import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getCaserecord,
  addDemographics,
  loadProblem,
  loadReliability,
  loadDiagnosis,
  loadProblemData,
  loadCaseRecord,
} from '../../../../store/caseRecord'
import AlertSuccess from '../../../common/alertSuccess'
import { toast } from 'react-toastify'
import download from '../../../../include/images/download-icon.svg'
import Joi from 'joi-browser'
import Form from '../../../common/form/form'
import _ from 'lodash'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
const briedGeneral = [
  'Sadness',
  'Crying',
  'Retardation',
  'Suicidal',
  'Hopelessness',
  'Worthlessness',
  'Grandiosity',
  'Acute Stress',
  'Elation',
  'Excitement',
  'Compulsions',
  'Obsessions',
  'Phobia',
  'Nervousness',
  'Headache',
  'Anxiety',
  'Adjustment Problems',
  'Forgetfulness',
  'Somatic Symptoms',
  'Sexual Problems',
  'Fainting',
  'Convulsions',
  'Eating Issues',
  'Suspiciousness',
  'Delusions',
  'Hallucinations',
  'Abusive',
  'Aggressiveness',
  'Socially Awkward Behaviour',
  'Drug Abuse',
  'Alcohol Abuse',
  'Sleep Issues',
  'Poor Self Care',
]
const past = [
  { Past: 'NIL' },
  { Past: 'Diabetes' },
  { Past: 'Hypothyroidism' },
  { Past: 'Hypertension' },
  { Past: 'Tuberculosis' },
  { Past: 'Seizures' },
  { Past: 'Cardiac Problem' },
  { Past: 'Renal Problem' },
  { Past: 'Respiratory Problem' },
  { Past: 'Birth Defects' },
  { Past: 'Cancer' },
  { Past: 'Gastric Disease' },
  { Past: 'Mental Retardation' },
  { Past: 'Gynaecological' },
  { Past: 'Cerebrovascular' },
  { Past: 'Head Injury' },
  { Past: 'Surgery' },
]

class Assessment extends Form {
  state = {
    data: {
      briefGeneralOther: '',
      briefDuration: '',
      briefFactors: '',
      type_of_work_up: '',
      problem_group: '60f066e1da1b1032d6cd2d0c',
      reliability: '',
      type_of_diagnosis: {},
      diagnosis: {},
      notes: '',
      patient: '',
      parents: '',
      relation1: '',
      relation2: '',
      relation3: '',
      status1: '',
      status2: '',
      status3: '',
      dynamics1: '',
      dynamics2: '',
      dynamics3: '',
      family_notes: '',
      member_treatment1: '',
      member_treatment2: '',
      member_treatment3: '',
      member_state1: 'NA',
      member_state2: 'NA',
      member_state3: 'NA',
      member_treatment_history1: '',
      member_treatment_history2: '',
      member_treatment_history3: '',
      member_state_history1: 'NA',
      member_state_history2: 'NA',
      member_state_history3: 'NA',
      member_psychiatric1: 'NIL',
      member_psychiatric2: 'NIL',
      member_psychiatric3: 'NIL',
      medical_history1: [{ label: 'NIL', value: 'NIL' }],
      medical_history2: [{ label: 'NIL', value: 'NIL' }],
      medical_history3: [{ label: 'NIL', value: 'NIL' }],
      other_problem: '',
      treatment_detail: '',
      treatment: '',
      psychotherapy_1therapy1: '',
      psychotherapy_1therapy2: '',
      psychotherapy_1therapy_count1: '',
      psychotherapy_1therapy_count2: '',
      psychotherapy_1therapy_duration1: '',
      psychotherapy_1therapy_duration2: '',
      psychotherapy_1compliance1: '',
      psychotherapy_1compliance2: '',
      psychotherapy_1therapy_response1: '',
      psychotherapy_1therapy_response2: '',
      psychotherapy_2therapy1: '',
      psychotherapy_2therapy2: '',
      psychotherapy_2therapy_count1: '',
      psychotherapy_2therapy_count2: '',
      psychotherapy_2therapy_duration1: '',
      psychotherapy_2therapy_duration2: '',
      psychotherapy_2compliance1: '',
      psychotherapy_2compliance2: '',
      psychotherapy_2therapy_response1: '',
      psychotherapy_2therapy_response2: '',
      medicine_name1: '',
      medicine_name2: '',
      medicine_name3: '',
      medicine_dose1: '',
      medicine_dose2: '',
      medicine_dose3: '',
      medicine_side_effect1: [],
      medicine_side_effect2: [],
      medicine_side_effect3: [],
      medicine_compliance1: '',
      medicine_compliance2: '',
      medicine_compliance3: '',
      medicine_response1: '',
      medicine_response2: '',
      medicine_response3: '',
      psychotherapy_2medicine_name1: '',
      psychotherapy_2medicine_name2: '',
      psychotherapy_2medicine_name3: '',
      psychotherapy_2medicine_dose1: '',
      psychotherapy_2medicine_dose2: '',
      psychotherapy_2medicine_dose3: '',
      psychotherapy_2medicine_side_effect1: [],
      psychotherapy_2medicine_side_effect2: [],
      psychotherapy_2medicine_side_effect3: [],
      psychotherapy_2medicine_compliance1: '',
      psychotherapy_2medicine_compliance2: '',
      psychotherapy_2medicine_compliance3: '',
      psychotherapy_2medicine_response1: '',
      psychotherapy_2medicine_response2: '',
      psychotherapy_2medicine_response3: '',
      previous_episodes: false,
      episodes_name1: '',
      episodes_name2: '',
      episodes_name3: '',
      episodes_count1: '',
      episodes_duration1: '',
      episodes_count2: '',
      episodes_duration2: '',
      episodes_count3: '',
      episodes_duration3: '',
      onset: '',
      progress: '',
      course: '',
      episod_number: '',
      illness_notes: '',
      general_other: '',
      mood_other: '',
      addiction_other: '',
      biological_functions_other: '',
      psychosis_other: '',
      predisposing_factors: '',
      prepetuating_factors: '',
      precipitating_factors: '',
      active_stressor: '',
    },
    errors: {},
    diagnosis: [],
    details: [],
    general: [],
    briefGeneral: [],
    mood: [],
    psychosis: [],
    biological_functions: [],
    addiction: [],
    personality_Socio_Occupational: [],
    problems: [],
    status_1: false,
    status2: false,
    status3: false,
    member1: true,
    member2: false,
    member3: false,
    psychotherapy1: true,
    psychotherapy2: false,
    status4: false,
    status5: false,
    status6: false,
    status7: false,
    status8: false,
    medicine1: true,
    medicine2: false,
    medicine3: false,
    fields: {},
    count: {},
    time: {},
    illness_by: {},
    work: [
      {
        id: 'Detailed Take Up',
        name: 'Detailed Take Up',
      },
      {
        id: 'Brief Take Up',
        name: 'Brief Take Up',
      },
    ],
    duration: [
      {
        id: 'Days',
        name: 'Days',
      },
      {
        id: 'Weeks',
        name: 'Weeks',
      },
      {
        id: 'Months',
        name: 'Months',
      },
      {
        id: 'Years',
        name: 'Years',
      },
      {
        id: 'Sessions',
        name: 'Sessions',
      },
    ],
    compliance: [
      {
        id: 'Good',
        name: 'Good',
      },
      {
        id: 'Partial',
        name: 'Partial',
      },
      {
        id: 'Poor',
        name: 'Poor',
      },
      {
        id: 'Single Session',
        name: 'Single Session',
      },
      {
        id: 'Irregular Session',
        name: 'Irregular Session',
      },
      {
        id: "Can't Say",
        name: "Can't Say",
      },
    ],
    response: [
      {
        id: 'Good Improvement',
        name: 'Good Improvement',
      },
      {
        id: 'Mild Improvement',
        name: 'Mild Improvement',
      },
      {
        id: 'No Improvement',
        name: 'No Improvement',
      },
      {
        id: "Can't Say",
        name: "Can't Say",
      },
    ],
    episodes: [
      {
        id: 'Substance',
        name: 'Substance',
      },
      {
        id: 'Suicide attempt',
        name: 'Suicide attempt',
      },
      {
        id: 'Bipolar',
        name: 'Bipolar',
      },
      {
        id: 'Anxiety',
        name: 'Anxiety',
      },
      {
        id: 'Panic',
        name: 'Panic',
      },
      {
        id: 'Phobia',
        name: 'Phobia',
      },
      {
        id: 'Psychosis',
        name: 'Psychosis',
      },
      {
        id: 'Manic',
        name: 'Manic',
      },
      {
        id: 'Depressive',
        name: 'Depressive',
      },
    ],
    illnessBy: [
      {
        id: 'By Informant',
        name: 'By Informant',
      },
      {
        id: 'By Patient',
        name: 'By Patient',
      },
    ],
    sideEffects: [
      {
        id: 'NIL',
        name: 'NIL',
      },
      {
        id: 'Tremors',
        name: 'Tremors',
      },
      {
        id: 'Hypersalivation',
        name: 'Hypersalivation',
      },
      {
        id: 'Weight Gain',
        name: 'Weight Gain',
      },
      {
        id: 'Hair Loss',
        name: 'Hair Loss',
      },
      {
        id: 'Sedation',
        name: 'Sedation',
      },
      {
        id: 'Rashes',
        name: 'Rashes',
      },
      {
        id: 'Gastric',
        name: 'Gastric',
      },
      {
        id: 'Hematological',
        name: 'Hematological',
      },
      {
        id: 'Mood Switch',
        name: 'Mood Switch',
      },
      {
        id: 'Hormonal',
        name: 'Hormonal',
      },
      {
        id: 'Others',
        name: 'Others',
      },
    ],
    status: [
      {
        id: 'Single',
        name: 'Single',
      },
      {
        id: 'Living In',
        name: 'Living In',
      },
      {
        id: 'Married',
        name: 'Married',
      },
      {
        id: 'Separated',
        name: 'Separated',
      },
      {
        id: 'Divorced',
        name: 'Divorced',
      },
      {
        id: 'Widowed',
        name: 'Widowed',
      },
      {
        id: 'Re-Married',
        name: 'Re-Married',
      },
    ],
    type: [
      {
        id: 'ICD',
        name: 'ICD',
      },
      {
        id: 'DSM',
        name: 'DSM',
      },
      {
        id: 'Conceptual Diagnosis',
        name: 'Conceptual Diagnosis',
      },
    ],
    medical_history: [
      {
        id: 'NIL',
        name: 'NIL',
      },
      {
        id: 'Hypertension',
        name: 'Hypertension',
      },
      {
        id: 'Diabetes',
        name: 'Diabetes',
      },
      {
        id: 'Cardiac',
        name: 'Cardiac',
      },
      {
        id: 'Stroke',
        name: 'Stroke',
      },
      {
        id: 'Cancer',
        name: 'Cancer',
      },
      {
        id: 'Epilepsy',
        name: 'Epilepsy',
      },
      {
        id: 'Others',
        name: 'Others',
      },
    ],
    member_status: [
      {
        id: 'Present',
        name: 'Present',
      },
      {
        id: 'Lives Away',
        name: 'Lives Away',
      },
      {
        id: 'Separated',
        name: 'Separated',
      },
      {
        id: 'Passed Away',
        name: 'Passed Away',
      },
      {
        id: 'Divorced',
        name: 'Divorced',
      },
    ],
    member_dynamics: [
      {
        id: 'Good',
        name: 'Good',
      },
      {
        id: 'Complicated',
        name: 'Complicated',
      },
      {
        id: 'Bad',
        name: 'Bad',
      },
      {
        id: 'Dysfunctional',
        name: 'Dysfunctional',
      },
      {
        id: 'N/A',
        name: 'N/A',
      },
    ],
    member_psychiatric: [
      {
        id: 'NIL',
        name: 'NIL',
      },
      {
        id: 'Mood Disorder',
        name: 'Mood Disorder',
      },
      {
        id: 'Psychosis',
        name: 'Psychosis',
      },
      {
        id: 'Depression Spectrum',
        name: 'Depression Spectrum',
      },
      {
        id: 'Anxiety Spectrum',
        name: 'Anxiety Spectrum',
      },
      {
        id: 'Personality',
        name: 'Personality',
      },
      {
        id: 'Addiction',
        name: 'Addiction',
      },
    ],
    member_treatment: [
      {
        id: 'Ongoing',
        name: 'Ongoing',
      },
      {
        id: 'Previously Taken',
        name: 'Previously Taken',
      },
      {
        id: 'No Treatment',
        name: 'No Treatment',
      },
    ],
    member_state: [
      {
        id: 'NA',
        name: 'NA',
      },
      {
        id: 'Improved',
        name: 'Improved',
      },
      {
        id: 'Stable',
        name: 'Stable',
      },
      {
        id: 'Unstable',
        name: 'Unstable',
      },
    ],
    relations: [
      { id: 'Father', name: 'Father' },
      { id: 'Mother', name: 'Mother' },
      { id: 'Grand Father', name: 'Grand Father' },
      { id: 'Grand Mother', name: 'Grand Mother' },
      { id: 'Brother', name: 'Brother' },
      { id: 'Sister', name: 'Sister' },
      { id: 'Husband', name: 'Husband' },
      { id: 'Wife', name: 'Wife' },
      { id: 'Friend', name: 'Friend' },
      { id: 'Son', name: 'Son' },
      { id: 'Daughter', name: 'Daughter' },
      { id: 'Grandchild', name: 'Grandchild' },
      { id: 'Others', name: 'Others' },
    ],
  }
  schema = {
    briefGeneralOther: Joi.string().allow(''),
    briefDuration: Joi.string().allow(''),
    briefFactors: Joi.string().allow(''),
    type_of_work_up: Joi.string().allow(''),
    problem_group: Joi.string().allow(''),
    reliability: Joi.string().allow(''),
    type_of_diagnosis: Joi.object(),
    notes: Joi.string().allow(''),
    patient: Joi.string().allow(''),
    parents: Joi.string().allow(''),
    relation1: Joi.string().allow(''),
    relation2: Joi.string().allow(''),
    relation3: Joi.string().allow(''),
    status1: Joi.string().allow(''),
    status2: Joi.string().allow(''),
    status3: Joi.string().allow(''),
    dynamics1: Joi.string().allow(''),
    dynamics2: Joi.string().allow(''),
    dynamics3: Joi.string().allow(''),
    family_notes: Joi.string().allow(''),
    member_treatment1: Joi.string().allow(''),
    member_treatment2: Joi.string().allow(''),
    member_treatment3: Joi.string().allow(''),
    member_state1: Joi.string().allow(''),
    member_state2: Joi.string().allow(''),
    member_state3: Joi.string().allow(''),
    member_treatment_history1: Joi.string().allow(''),
    member_treatment_history2: Joi.string().allow(''),
    member_treatment_history3: Joi.string().allow(''),
    member_state_history1: Joi.string().allow(''),
    member_state_history2: Joi.string().allow(''),
    member_state_history3: Joi.string().allow(''),
    member_psychiatric1: Joi.string().allow(''),
    member_psychiatric2: Joi.string().allow(''),
    member_psychiatric3: Joi.string().allow(''),
    other_problem: Joi.string().allow(''),
    treatment_detail: Joi.string().allow(''),
    treatment: Joi.string().allow(''),
    medical_history1: Joi.array().items(Joi.object().allow('')).allow(''),
    medical_history2: Joi.array().items(Joi.object().allow('')).allow(''),
    medical_history3: Joi.array().items(Joi.object().allow('')).allow(''),
    psychotherapy_1therapy1: Joi.string().allow(''),
    psychotherapy_1therapy2: Joi.string().allow(''),
    psychotherapy_1therapy_count1: Joi.number().min(0).allow(''),
    psychotherapy_1therapy_count2: Joi.number().min(0).allow(''),
    psychotherapy_1therapy_duration1: Joi.string().allow(''),
    psychotherapy_1therapy_duration2: Joi.string().allow(''),
    psychotherapy_1compliance1: Joi.string().allow(''),
    psychotherapy_1compliance2: Joi.string().allow(''),
    psychotherapy_1therapy_response1: Joi.string().allow(''),
    psychotherapy_1therapy_response2: Joi.string().allow(''),
    psychotherapy_2therapy1: Joi.string().allow(''),
    psychotherapy_2therapy2: Joi.string().allow(''),
    psychotherapy_2therapy_count1: Joi.string().allow(''),
    psychotherapy_2therapy_count2: Joi.string().allow(''),
    psychotherapy_2therapy_duration1: Joi.string().allow(''),
    psychotherapy_2therapy_duration2: Joi.string().allow(''),
    psychotherapy_2compliance1: Joi.string().allow(''),
    psychotherapy_2compliance2: Joi.string().allow(''),
    psychotherapy_2therapy_response1: Joi.string().allow(''),
    psychotherapy_2therapy_response2: Joi.string().allow(''),
    medicine_name1: Joi.string().allow(''),
    medicine_name2: Joi.string().allow(''),
    medicine_name3: Joi.string().allow(''),
    medicine_dose1: Joi.string().allow(''),
    medicine_dose2: Joi.string().allow(''),
    medicine_dose3: Joi.string().allow(''),
    medicine_side_effect1: Joi.array().items(Joi.object().allow('')).allow(''),
    medicine_side_effect2: Joi.array().items(Joi.object().allow('')).allow(''),
    medicine_side_effect3: Joi.array().items(Joi.object().allow('')).allow(''),
    medicine_compliance1: Joi.string().allow(''),
    medicine_compliance2: Joi.string().allow(''),
    medicine_compliance3: Joi.string().allow(''),
    medicine_response1: Joi.string().allow(''),
    medicine_response2: Joi.string().allow(''),
    medicine_response3: Joi.string().allow(''),
    psychotherapy_2medicine_name1: Joi.string().allow(''),
    psychotherapy_2medicine_name2: Joi.string().allow(''),
    psychotherapy_2medicine_name3: Joi.string().allow(''),
    psychotherapy_2medicine_dose1: Joi.string().allow(''),
    psychotherapy_2medicine_dose2: Joi.string().allow(''),
    psychotherapy_2medicine_dose3: Joi.string().allow(''),
    psychotherapy_2medicine_side_effect1: Joi.array()
      .items(Joi.object().allow(''))
      .allow(''),
    psychotherapy_2medicine_side_effect2: Joi.array()
      .items(Joi.object().allow(''))
      .allow(''),
    psychotherapy_2medicine_side_effect3: Joi.array()
      .items(Joi.object().allow(''))
      .allow(''),
    psychotherapy_2medicine_compliance1: Joi.string().allow(''),
    psychotherapy_2medicine_compliance2: Joi.string().allow(''),
    psychotherapy_2medicine_compliance3: Joi.string().allow(''),
    psychotherapy_2medicine_response1: Joi.string().allow(''),
    psychotherapy_2medicine_response2: Joi.string().allow(''),
    psychotherapy_2medicine_response3: Joi.string().allow(''),
    previous_episodes: Joi.boolean(),
    episodes_name1: Joi.string().allow('', null),
    episodes_name2: Joi.string().allow('', null),
    episodes_name3: Joi.string().allow('', null),
    episodes_count1: Joi.string().allow('', null),
    episodes_duration1: Joi.string().allow('', null),
    episodes_count2: Joi.string().allow('', null),
    episodes_duration2: Joi.string().allow('', null),
    episodes_count3: Joi.string().allow('', null),
    episodes_duration3: Joi.string().allow('', null),
    onset: Joi.string().allow('', null),
    progress: Joi.string().allow('', null),
    course: Joi.string().allow('', null),
    episod_number: Joi.string().allow('', null),
    illness_notes: Joi.string().allow('', null),
    general_other: Joi.string().allow(''),
    mood_other: Joi.string().allow(''),
    addiction_other: Joi.string().allow(''),
    biological_functions_other: Joi.string().allow(''),
    psychosis_other: Joi.string().allow(''),
    predisposing_factors: Joi.string().allow('', null),
    prepetuating_factors: Joi.string().allow('', null),
    precipitating_factors: Joi.string().allow('', null),
    active_stressor: Joi.string().allow('', null),
    diagnosis: Joi.object(),
  }
  doSubmit = () => {
    const {
      type_of_work_up,
      problem_group,
      reliability,
      general_other,
      mood_other,
      addiction_other,
      biological_functions_other,
      psychosis_other,
      type_of_diagnosis,
      diagnosis,
      notes,
      patient,
      parents,
      relation1,
      relation2,
      dynamics1,
      dynamics2,
      dynamics3,
      family_notes,
      member_treatment1,
      predisposing_factors,
      prepetuating_factors,
      precipitating_factors,
      active_stressor,
      relation3,
      status1,
      status2,
      status3,
      psychotherapy_2therapy1,
      member_treatment2,
      member_treatment3,
      member_state1,
      member_state2,
      member_state3,
      member_treatment_history1,
      member_treatment_history2,
      member_treatment_history3,
      member_state_history1,
      member_state_history2,
      member_state_history3,
      member_psychiatric1,
      member_psychiatric2,
      member_psychiatric3,
      medical_history1,
      medical_history2,
      medical_history3,
      other_problem,
      treatment_detail,
      treatment,
      psychotherapy_1therapy1,
      psychotherapy_1therapy2,
      psychotherapy_1therapy_count1,
      psychotherapy_1therapy_duration2,
      psychotherapy_1therapy_count2,
      psychotherapy_1therapy_duration1,
      psychotherapy_1compliance1,
      psychotherapy_1compliance2,
      psychotherapy_1therapy_response1,
      psychotherapy_1therapy_response2,
      psychotherapy_2therapy2,
      psychotherapy_2therapy_count1,
      psychotherapy_2therapy_count2,
      psychotherapy_2therapy_duration1,
      psychotherapy_2therapy_duration2,
      psychotherapy_2compliance1,
      psychotherapy_2compliance2,
      psychotherapy_2therapy_response1,
      psychotherapy_2therapy_response2,
      medicine_name1,
      medicine_name2,
      medicine_name3,
      medicine_dose1,
      medicine_dose2,
      medicine_dose3,
      medicine_side_effect1,
      medicine_side_effect2,
      medicine_side_effect3,
      medicine_compliance1,
      medicine_compliance2,
      medicine_compliance3,
      medicine_response1,
      medicine_response2,
      medicine_response3,
      psychotherapy_2medicine_name1,
      psychotherapy_2medicine_name2,
      psychotherapy_2medicine_name3,
      psychotherapy_2medicine_dose1,
      psychotherapy_2medicine_dose2,
      psychotherapy_2medicine_dose3,
      psychotherapy_2medicine_side_effect1,
      psychotherapy_2medicine_side_effect2,
      psychotherapy_2medicine_side_effect3,
      psychotherapy_2medicine_compliance1,
      psychotherapy_2medicine_compliance2,
      psychotherapy_2medicine_compliance3,
      psychotherapy_2medicine_response1,
      psychotherapy_2medicine_response2,
      psychotherapy_2medicine_response3,
      previous_episodes,
      episodes_name1,
      episodes_name2,
      episodes_name3,
      episodes_count1,
      episodes_duration1,
      episodes_count2,
      episodes_duration2,
      episodes_count3,
      episodes_duration3,
      onset,
      progress,
      course,
      episod_number,
      illness_notes,
      briefGeneralOther,
      briefDuration,
      briefFactors,
    } = this.state.data

    const {
      general,
      mood,
      psychosis,
      biological_functions,
      addiction,
      personality_Socio_Occupational,
      problems,
      status_1,
      briefGeneral,
    } = this.state
    const id = this.props.data && this.props.data._id

    const formData = {
      assessment: {
        patient_history: _.pickBy(
          {
            type_of_work_up: type_of_work_up,
          },
          _.identity
        ),
        history_of_chief_complaints: _.pickBy(
          {
            problem_group: problem_group,
            reliability: reliability,
            problem_group_data: _.pickBy(
              {
                general: {
                  value: general,
                  other_value: general_other,
                },
                briefGeneral: {
                  value: briefGeneral,
                  other_value: briefGeneralOther,
                  briefDuration,
                  briefFactors,
                },
                mood: {
                  value: mood,
                  other_value: mood_other,
                },
                psychosis: {
                  value: psychosis,
                  other_value: psychosis_other,
                },
                addiction: {
                  value: addiction,
                  other_value: addiction_other,
                },
                biological_functions: {
                  value: biological_functions,
                  other_value: biological_functions_other,
                },
                personality_Socio_Occupational: {
                  value: personality_Socio_Occupational,
                },
              },
              _.identity
            ),
            notes: notes,
          },
          _.identity
        ),
        order_of_illness: _.pickBy(
          {
            details: this.state.details,
            episod_number: episod_number,
            onset: onset,
            progress: progress,
            course: course,
            predisposing_factors: predisposing_factors,
            prepetuating_factors: prepetuating_factors,
            precipitating_factors: precipitating_factors,
            active_stressor: active_stressor,
            notes: illness_notes,
          },
          _.identity
        ),
        psychiatric_psychological: _.pickBy(
          {
            previous_episodes: previous_episodes,
            type_of_episode: [
              _.pickBy(
                {
                  name: episodes_name1,
                  duration: episodes_count1,
                  duration_type: episodes_duration1,
                },
                _.identity
              ),
              _.pickBy(
                {
                  name: episodes_name2,
                  duration: episodes_count2,
                  duration_type: episodes_duration2,
                },
                _.identity
              ),
              _.pickBy(
                {
                  name: episodes_name3,
                  duration: episodes_count3,
                  duration_type: episodes_duration3,
                },
                _.identity
              ),
            ],
            previous_diagnosis: _.pickBy(
              {
                type_of_diagnosis: type_of_diagnosis && type_of_diagnosis.value,
                diagnosis: diagnosis && diagnosis.value,
              },
              _.identity
            ),
          },
          _.identity
        ),
        past_mental_health_treatment: _.pickBy(
          {
            psychotherapy_1: {
              details: [
                _.pickBy(
                  {
                    name: psychotherapy_1therapy1,
                    count: psychotherapy_1therapy_count1,
                    duration: psychotherapy_1therapy_duration1,
                    compliance: psychotherapy_1compliance1,
                    response: psychotherapy_1therapy_response1,
                  },
                  _.identity
                ),
                _.pickBy(
                  {
                    name: psychotherapy_1therapy2,
                    count: psychotherapy_1therapy_count2,
                    duration: psychotherapy_1therapy_duration2,
                    compliance: psychotherapy_1compliance2,
                    response: psychotherapy_1therapy_response2,
                  },
                  _.identity
                ),
              ],
              medicines: [
                _.pickBy(
                  {
                    name: medicine_name1,
                    dose: medicine_dose1,
                    side_effect: medicine_side_effect1,
                    compliance: medicine_compliance1,
                    response: medicine_response1,
                  },
                  _.identity
                ),
                _.pickBy(
                  {
                    name: medicine_name2,
                    dose: medicine_dose2,
                    side_effect: medicine_side_effect2,
                    compliance: medicine_compliance2,
                    response: medicine_response2,
                  },
                  _.identity
                ),
                _.pickBy(
                  {
                    name: medicine_name3,
                    dose: medicine_dose3,
                    side_effect: medicine_side_effect3,
                    compliance: medicine_compliance3,
                    response: medicine_response3,
                  },
                  _.identity
                ),
              ],
            },
            psychotherapy_2: {
              details: [
                _.pickBy(
                  {
                    name: psychotherapy_2therapy1,
                    count: psychotherapy_2therapy_count1,
                    duration: psychotherapy_2therapy_duration1,
                    compliance: psychotherapy_2compliance1,
                    response: psychotherapy_2therapy_response1,
                  },
                  _.identity
                ),
                _.pickBy(
                  {
                    name: psychotherapy_2therapy2,
                    count: psychotherapy_2therapy_count2,
                    duration: psychotherapy_2therapy_duration2,
                    compliance: psychotherapy_2compliance2,
                    response: psychotherapy_2therapy_response2,
                  },
                  _.identity
                ),
              ],
              medicines: [
                _.pickBy(
                  {
                    name: psychotherapy_2medicine_name1,
                    dose: psychotherapy_2medicine_dose1,
                    side_effect: psychotherapy_2medicine_side_effect1,
                    compliance: psychotherapy_2medicine_compliance1,
                    response: psychotherapy_2medicine_response1,
                  },
                  _.identity
                ),
                _.pickBy(
                  {
                    name: psychotherapy_2medicine_name2,
                    dose: psychotherapy_2medicine_dose2,
                    side_effect: psychotherapy_2medicine_side_effect2,
                    compliance: psychotherapy_2medicine_compliance2,
                    response: psychotherapy_2medicine_response2,
                  },
                  _.identity
                ),
                _.pickBy(
                  {
                    name: psychotherapy_2medicine_name3,
                    dose: psychotherapy_2medicine_dose3,
                    side_effect: psychotherapy_2medicine_side_effect3,
                    compliance: psychotherapy_2medicine_compliance3,
                    response: psychotherapy_2medicine_response3,
                  },
                  _.identity
                ),
              ],
            },
          },
          _.identity
        ),
        past_medical_history: _.pickBy(
          {
            problems: problems,
            other_problem: other_problem,
            treatment: treatment,
            treatment_detail: treatment_detail,
          },
          _.identity
        ),
        family_members: _.pickBy(
          {
            members: [
              _.pickBy(
                {
                  relation: relation1,
                  dynamics: dynamics1,
                  status: status1,
                  psychiatric_psychological: member_psychiatric1,
                  on_treatment: member_treatment1,
                  status_1: member_state1,
                  medical_history: medical_history1,
                  on_treatment_1: member_treatment_history1,
                  status_2: member_state_history1,
                },
                _.identity
              ),
              _.pickBy(
                {
                  relation: relation2,
                  dynamics: dynamics2,
                  status: status2,
                  psychiatric_psychological: member_psychiatric2,
                  on_treatment: member_treatment2,
                  status_1: member_state2,
                  medical_history: medical_history2,
                  on_treatment_1: member_treatment_history2,
                  status_2: member_state_history2,
                },
                _.identity
              ),
              _.pickBy(
                {
                  relation: relation3,
                  dynamics: dynamics3,
                  status: status3,
                  psychiatric_psychological: member_psychiatric3,
                  on_treatment: member_treatment3,
                  status_1: member_state3,
                  medical_history: medical_history3,
                  on_treatment_1: member_treatment_history3,
                  status_2: member_state_history3,
                },
                _.identity
              ),
            ],
            notes: family_notes,
          },
          _.identity
        ),
        marital_history: _.pickBy(
          {
            status: status_1,
            patient: patient,
            parents: parents,
          },
          _.identity
        ),
      },
    }
    const newData = _.pickBy(formData, _.identity)
    this.props.addDemographics(newData, id, this.callBack)
  }
  callBack = res => {
    // const _id = this.props.data && this.props.data._id;
    // if (res && res.status === 200) {
    //   this.props.loadCaseRecord(_id);
    this.props.updateStep(null, 3)
    toast(<AlertSuccess message={'Information Saved.'} />)
    //}
  }
  toggleMember1 = () => {
    if (this.state.member1 === false) {
      this.setState({
        member1: !this.state.member1,
        member2: false,
        member3: false,
      })
    }
  }
  toggleMember2 = () => {
    this.setState({
      member1: false,
      member2: !this.state.member2,
      member3: false,
    })
  }
  toggleMember3 = () => {
    this.setState({
      member1: false,
      member2: false,
      member3: !this.state.member3,
    })
  }
  toggleMedicine1 = () => {
    if (this.state.medicine1 === false) {
      this.setState({
        medicine1: !this.state.medicine1,
        medicine2: false,
        medicine3: false,
      })
    }
  }
  toggleMedicine2 = () => {
    this.setState({
      medicine1: false,
      medicine2: !this.state.medicine2,
      medicine3: false,
    })
  }
  toggleMedicine3 = () => {
    this.setState({
      medicine1: false,
      medicine2: false,
      medicine3: !this.state.medicine3,
    })
  }
  togglePsychotherapy1 = () => {
    if (this.state.psychotherapy1 === false) {
      this.setState({
        psychotherapy1: !this.state.psychotherapy1,
        psychotherapy2: false,
      })
    }
  }
  togglePsychotherapy2 = () => {
    this.setState({
      psychotherapy1: false,
      psychotherapy2: !this.state.psychotherapy2,
    })
  }

  changeLang = Lang => {
    var problems = this.state.problems ? [...this.state.problems] : []
    const find = problems.find(v => v === Lang)
    if (find) {
      const f = problems.filter(vf => vf !== Lang)
      problems = f
    } else {
      problems.push(Lang)
    }
    this.setState({ problems })
  }

  toggleBriefGeneral = Lang => {
    let briefGeneral = [...this.state.briefGeneral]
    const find = briefGeneral.find(v => v === Lang)
    if (find) {
      const f = briefGeneral.filter(vf => vf !== Lang)
      briefGeneral = f
    } else {
      briefGeneral.push(Lang)
      briefGeneral.push({ name: Lang })
    }
    this.setState({ briefGeneral })
  }

  toggleGeneral = Lang => {
    let details = [...this.state.details]
    let general = [...this.state.general]
    const find = general.find(v => v === Lang)
    if (find) {
      const f = general.filter(vf => vf !== Lang)
      const g = details.filter(vf => vf.name !== Lang)
      details = g
      general = f
    } else {
      general.push(Lang)
      details.push({ name: Lang })
    }
    this.setState({ general, details })
  }
  toggleMood = Lang => {
    let mood = [...this.state.mood]
    let details = [...this.state.details]
    const find = mood.find(v => v === Lang)
    if (find) {
      const f = mood.filter(vf => vf !== Lang)
      const g = details.filter(vf => vf.name !== Lang)
      mood = f
      details = g
    } else {
      mood.push(Lang)
      details.push({ name: Lang })
    }
    this.setState({ mood, details })
  }
  togglePsychosis = Lang => {
    let psychosis = [...this.state.psychosis]
    let details = [...this.state.details]
    const find = psychosis.find(v => v === Lang)
    if (find) {
      const f = psychosis.filter(vf => vf !== Lang)
      const g = details.filter(vf => vf.name !== Lang)
      psychosis = f
      details = g
    } else {
      psychosis.push(Lang)
      details.push({ name: Lang })
    }
    this.setState({ psychosis, details })
  }
  toggleBiological = Lang => {
    let biological_functions = [...this.state.biological_functions]
    let details = [...this.state.details]
    const find = biological_functions.find(v => v === Lang)
    if (find) {
      const f = biological_functions.filter(vf => vf !== Lang)
      const g = details.filter(vf => vf.name !== Lang)
      biological_functions = f
      details = g
    } else {
      biological_functions.push(Lang)
      details.push({ name: Lang })
    }
    this.setState({ biological_functions, details })
  }
  toggleAddiction = Lang => {
    let addiction = [...this.state.addiction]
    let details = [...this.state.details]
    const find = addiction.find(v => v === Lang)
    if (find) {
      const f = addiction.filter(vf => vf !== Lang)
      const g = details.filter(vf => vf.name !== Lang)
      addiction = f
      details = g
    } else {
      addiction.push(Lang)
      details.push({ name: Lang })
    }
    this.setState({ addiction, details })
  }
  togglePersonality = Lang => {
    let personality_Socio_Occupational = [
      ...this.state.personality_Socio_Occupational,
    ]
    let details = [...this.state.details]
    const find = personality_Socio_Occupational.find(v => v === Lang)
    if (find) {
      const f = personality_Socio_Occupational.filter(vf => vf !== Lang)
      const g = details.filter(vf => vf.name !== Lang)
      personality_Socio_Occupational = f
      details = g
    } else {
      personality_Socio_Occupational.push(Lang)
      details.push({ name: Lang })
    }
    this.setState({ personality_Socio_Occupational, details })
  }
  toggleTreatment = e => {
    const data = { ...this.state.data }
    data.treatment = e.target.value
    this.setState({ data })
  }
  toggleEpisodeTrue = e => {
    //alert(e.target.value);
    const data = { ...this.state.data }
    data.previous_episodes = e
    this.setState({ data })
  }

  toggleOnset = e => {
    const data = { ...this.state.data }
    data.onset = e.target.value
    this.setState({ data })
  }
  toggleProgress = e => {
    const data = { ...this.state.data }
    data.progress = e.target.value
    this.setState({ data })
  }
  toggleCourse = e => {
    const data = { ...this.state.data }
    data.course = e.target.value
    this.setState({ data })
  }
  toggleNumber = e => {
    const data = { ...this.state.data }
    data.episod_number = e.target.value
    this.setState({ data })
  }

  animatedComponents = makeAnimated()
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
      'height': '57px',
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }
  customStyles2 = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
      'height': '57px',
      'width': '100%',
    }),
  }
  componentDidMount() {
    document.body.className = 'dashboard fixed'
    this.props.loadProblem()
    this.props.loadReliability()
    this.props.loadProblemData(this.state.data.problem_group)
    this.props.loadDiagnosis(this.state.data.type_of_diagnosis.value)
    if (this.props.data) {
      const { _id } = this.props.data
      this.props.loadCaseRecord(_id)
    }

    const data = { ...this.state.data }
    data.type_of_work_up = 'Detailed Take Up'
    this.setState({ data })
  }
  assessmentHistory = first => {
    const { assessment } = this.props.case
    return assessment &&
      assessment.history_of_chief_complaints &&
      assessment.history_of_chief_complaints.problem_group_data &&
      assessment.history_of_chief_complaints.problem_group_data[first] &&
      assessment.history_of_chief_complaints.problem_group_data[first].value
      ? assessment.history_of_chief_complaints.problem_group_data[first].value
      : []
  }
  assessmentHistory1 = first => {
    const { assessment } = this.props.case
    return assessment &&
      assessment.history_of_chief_complaints &&
      assessment.history_of_chief_complaints.problem_group_data &&
      assessment.history_of_chief_complaints.problem_group_data[first] &&
      assessment.history_of_chief_complaints.problem_group_data[first]
        .other_value
      ? assessment.history_of_chief_complaints.problem_group_data[first]
          .other_value
      : ''
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.data.type_of_work_up === 'Detailed Take Up' &&
      this.state.data.type_of_work_up !== prevState.data.type_of_work_up &&
      this.props.problem.length > 0
    ) {
      const data = { ...this.state.data }
      data.problem_group = this.props.problem[0]._id
      this.setState({ data })
    }
    if (this.state.data.type_of_diagnosis != prevState.data.type_of_diagnosis) {
      this.props.loadDiagnosis(this.state.data.type_of_diagnosis.value)
    }
    if (this.state.data.type_of_work_up != prevState.data.type_of_work_up) {
      this.props.loadDiagnosis(this.state.data.type_of_diagnosis.value)
    }
    if (
      !this.props.getCaserecord.diagnosisLoading &&
      this.props.getCaserecord.diagnosisLoading !==
        prevProps.getCaserecord.diagnosisLoading
    ) {
      this.setState({ diagnosis: this.props.diagnosis })
    }

    if (
      this.state.data.problem_group &&
      this.state.data.problem_group != prevState.data.problem_group
    ) {
      this.props.loadProblemData(this.state.data.problem_group)
    }
    if (this.props.case && this.props.loading !== prevProps.loading) {
      const { assessment } = this.props.case
      const data = { ...this.state.data }

      if (
        assessment &&
        assessment.patient_history &&
        assessment.patient_history.type_of_work_up
      )
        data.type_of_work_up = assessment.patient_history.type_of_work_up

      const fields =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.details &&
        assessment.order_of_illness.details.map(ed => ({
          by: ed.by,
          duration: ed.duration,
          duration_type: ed.duration_type,
          name: ed.name,
          value: ed.value,
        }))
      data.relation1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].relation
      data.relation2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].relation
      data.relation3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].relation
      data.status1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].status
      data.status2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].status
      data.status3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].status
      data.dynamics1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].dynamics
      data.dynamics2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].dynamics
      data.dynamics3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].dynamics
      data.family_notes =
        assessment &&
        assessment.family_members &&
        assessment.family_members.notes
      data.member_treatment1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].on_treatment
      data.member_treatment2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].on_treatment
      data.member_treatment3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].on_treatment
      data.member_state1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].status_1
      data.member_state2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].status_1
      data.member_state3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].status_1
      data.member_treatment_history1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].on_treatment_1
      data.member_treatment_history2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].on_treatment_1
      data.member_treatment_history3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].on_treatment_1
      data.member_state_history1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].status_2
      data.member_state_history2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].status_2
      data.member_state_history3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].status_2
      data.member_psychiatric1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].psychiatric_psychological
      data.member_psychiatric2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].psychiatric_psychological
      data.member_psychiatric3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].psychiatric_psychological
      data.medical_history1 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[0] &&
        assessment.family_members.members[0].medical_history
      data.medical_history2 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[1] &&
        assessment.family_members.members[1].medical_history
      data.medical_history3 =
        assessment &&
        assessment.family_members &&
        assessment.family_members.members &&
        assessment.family_members.members[2] &&
        assessment.family_members.members[2].medical_history
      data.problem_group =
        assessment &&
        assessment.history_of_chief_complaints &&
        assessment.history_of_chief_complaints.problem_group
      data.reliability =
        assessment &&
        assessment.history_of_chief_complaints &&
        assessment.history_of_chief_complaints.reliability
      data.general_other = this.assessmentHistory1('general')
      data.mood_other = this.assessmentHistory1('mood')
      data.addiction_other = this.assessmentHistory1('addiction')
      data.biological_functions_other = this.assessmentHistory1(
        'biological_functions'
      )
      data.psychosis_other = this.assessmentHistory1('psychosis')
      data.notes =
        assessment &&
        assessment.history_of_chief_complaints &&
        assessment.history_of_chief_complaints.notes
      data.onset =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.onset &&
        assessment.order_of_illness.onset.toString()
      data.progress =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.progress &&
        assessment.order_of_illness.progress.toString()
      data.course =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.course &&
        assessment.order_of_illness.course.toString()
      data.episod_number =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.episod_number
      data.illness_notes =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.notes
      data.predisposing_factors =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.predisposing_factors
      data.prepetuating_factors =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.prepetuating_factors
      data.precipitating_factors =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.precipitating_factors
      data.active_stressor =
        assessment &&
        assessment.order_of_illness &&
        assessment.order_of_illness.active_stressor
      data.previous_episodes =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.previous_episodes
      data.type_of_diagnosis.value =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.previous_diagnosis &&
        assessment.psychiatric_psychological.previous_diagnosis
          .type_of_diagnosis
      data.type_of_diagnosis.label =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.previous_diagnosis &&
        assessment.psychiatric_psychological.previous_diagnosis
          .type_of_diagnosis
      data.diagnosis.value =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.previous_diagnosis &&
        assessment.psychiatric_psychological.previous_diagnosis.diagnosis &&
        assessment.psychiatric_psychological.previous_diagnosis.diagnosis._id
      data.diagnosis.label =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.previous_diagnosis &&
        assessment.psychiatric_psychological.previous_diagnosis.diagnosis &&
        assessment.psychiatric_psychological.previous_diagnosis.diagnosis
          .diagnosis
      data.episodes_name1 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode[0] &&
        assessment.psychiatric_psychological.type_of_episode[0].name
      data.episodes_name2 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 0 &&
        assessment.psychiatric_psychological.type_of_episode[1] &&
        assessment.psychiatric_psychological.type_of_episode[1].name
      data.episodes_name3 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 1 &&
        assessment.psychiatric_psychological.type_of_episode[2] &&
        assessment.psychiatric_psychological.type_of_episode[2].name
      data.episodes_count1 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 0 &&
        assessment.psychiatric_psychological.type_of_episode[0] &&
        assessment.psychiatric_psychological.type_of_episode[0].duration
      data.episodes_duration1 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 0 &&
        assessment.psychiatric_psychological.type_of_episode[0] &&
        assessment.psychiatric_psychological.type_of_episode[0].duration_type
      data.episodes_count2 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 1 &&
        assessment.psychiatric_psychological.type_of_episode[1] &&
        assessment.psychiatric_psychological.type_of_episode[1].duration
      data.episodes_duration2 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 1 &&
        assessment.psychiatric_psychological.type_of_episode[1] &&
        assessment.psychiatric_psychological.type_of_episode[1].duration_type
      data.episodes_count3 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode.length > 2 &&
        assessment.psychiatric_psychological.type_of_episode[2] &&
        assessment.psychiatric_psychological.type_of_episode[2].duration
      data.episodes_duration3 =
        assessment &&
        assessment.psychiatric_psychological &&
        assessment.psychiatric_psychological.type_of_episode &&
        assessment.psychiatric_psychological.type_of_episode[2] &&
        assessment.psychiatric_psychological.type_of_episode[2].duration_type
      data.psychotherapy_1therapy1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details.length >
          0 &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0].name
          ? assessment.past_mental_health_treatment.psychotherapy_1.details[0]
              .name
          : ''
      data.psychotherapy_1therapy2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1].name
      data.psychotherapy_1therapy_count1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0].count
      data.psychotherapy_1therapy_count2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1].count
      data.psychotherapy_1therapy_duration1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0]
          .duration
      data.psychotherapy_1therapy_duration2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1]
          .duration
      data.psychotherapy_1compliance1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0]
          .compliance
      data.psychotherapy_1compliance2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details
          .length &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1]
          .compliance
          ? assessment.past_mental_health_treatment.psychotherapy_1.details[1]
              .compliance
          : ''
      data.psychotherapy_1therapy_response1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[0]
          .response
      data.psychotherapy_1therapy_response2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.details &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.details[1]
          .response
      data.psychotherapy_2therapy1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0].name
      data.psychotherapy_2therapy2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1].name
      data.psychotherapy_2therapy_count1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0].count
      data.psychotherapy_2therapy_count2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1].count
      data.psychotherapy_2therapy_duration1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0]
          .duration
      data.psychotherapy_2therapy_duration2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1]
          .duration
      data.psychotherapy_2compliance1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0]
          .compliance
      data.psychotherapy_2compliance2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1]
          .compliance
      data.psychotherapy_2therapy_response1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[0]
          .response
      data.psychotherapy_2therapy_response2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.details[1]
          .response
      data.medicine_name1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0]
          .name
      data.medicine_name2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1]
          .name
      data.medicine_name3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2]
          .name
      data.medicine_dose1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0]
          .dose
      data.medicine_dose2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1]
          .dose
      data.medicine_dose3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2]
          .dose
      data.medicine_side_effect1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0]
          .side_effect
      data.medicine_side_effect2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1]
          .side_effect
      data.medicine_side_effect3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2]
          .side_effect
      data.medicine_compliance1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0]
          .compliance
      data.medicine_compliance2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1]
          .compliance
      data.medicine_compliance3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2]
          .compliance
      data.medicine_response1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[0]
          .response
      data.medicine_response2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[1]
          .response
      data.medicine_response3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_1.medicines[2]
          .response
      data.psychotherapy_2medicine_name1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0]
          .name
      data.psychotherapy_2medicine_name2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1]
          .name
      data.psychotherapy_2medicine_name3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2]
          .name
      data.psychotherapy_2medicine_dose1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0].dose.toString()
      data.psychotherapy_2medicine_dose2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1].dose.toString()
      data.psychotherapy_2medicine_dose3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2].dose.toString()
      data.psychotherapy_2medicine_side_effect1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0]
          .side_effect
      data.psychotherapy_2medicine_side_effect2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1]
          .side_effect
      data.psychotherapy_2medicine_side_effect3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2]
          .side_effect
      data.psychotherapy_2medicine_compliance1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0]
          .compliance
      data.psychotherapy_2medicine_compliance2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1]
          .compliance
      data.psychotherapy_2medicine_compliance3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2]
          .compliance
      data.psychotherapy_2medicine_response1 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[0]
          .response
      data.psychotherapy_2medicine_response2 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[1]
          .response
      data.psychotherapy_2medicine_response3 =
        assessment &&
        assessment.past_mental_health_treatment &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2] &&
        assessment.past_mental_health_treatment.psychotherapy_2.medicines[2]
          .response
      data.briefGeneralOther =
        assessment &&
        assessment.history_of_chief_complaints &&
        assessment.history_of_chief_complaints.problem_group_data &&
        assessment.history_of_chief_complaints.problem_group_data
          .briefGeneral &&
        assessment.history_of_chief_complaints.problem_group_data.briefGeneral
          .other_value
      data.briefDuration =
        assessment &&
        assessment.history_of_chief_complaints &&
        assessment.history_of_chief_complaints.problem_group_data &&
        assessment.history_of_chief_complaints.problem_group_data
          .briefGeneral &&
        assessment.history_of_chief_complaints.problem_group_data.briefGeneral
          .briefDuration &&
        assessment.history_of_chief_complaints.problem_group_data.briefGeneral
          .briefDuration
      data.briefFactors =
        assessment &&
        assessment.history_of_chief_complaints &&
        assessment.history_of_chief_complaints.problem_group_data &&
        assessment.history_of_chief_complaints.problem_group_data
          .briefGeneral &&
        assessment.history_of_chief_complaints.problem_group_data.briefGeneral
          .briefFactors &&
        assessment.history_of_chief_complaints.problem_group_data.briefGeneral
          .briefFactors
      data.other_problem =
        assessment &&
        assessment.past_medical_history &&
        assessment.past_medical_history.other_problem
      data.treatment_detail =
        assessment &&
        assessment.past_medical_history &&
        assessment.past_medical_history.treatment_detail
      data.treatment =
        assessment &&
        assessment.past_medical_history &&
        assessment.past_medical_history.treatment
      data.patient =
        assessment &&
        assessment.marital_history &&
        assessment.marital_history.patient
      data.parents =
        assessment &&
        assessment.marital_history &&
        assessment.marital_history.parents
      console.log('fields', fields)
      this.setState({
        details: fields,
        data,
        general: this.assessmentHistory('general'),
        mood: this.assessmentHistory('mood'),
        briefGeneral: this.assessmentHistory('briefGeneral'),

        psychosis: this.assessmentHistory('psychosis'),

        biological_functions: this.assessmentHistory('biological_functions'),

        addiction: this.assessmentHistory('addiction'),

        personality_Socio_Occupational: this.assessmentHistory(
          'personality_Socio_Occupational'
        ),

        status4:
          Object.keys(
            assessment && assessment.past_mental_health_treatment
              ? assessment.past_mental_health_treatment.psychotherapy_1
              : {}
          ).length > 0 ||
          Object.keys(
            assessment && assessment.past_mental_health_treatment
              ? assessment.past_mental_health_treatment.psychotherapy_2
              : {}
          ).length > 0
            ? true
            : false,
        status3:
          (assessment &&
            assessment.past_medical_history &&
            assessment.past_medical_history.problems) ||
          (assessment &&
            assessment.past_medical_history &&
            assessment.past_medical_history.treatment) ||
          (assessment &&
            assessment.past_medical_history &&
            assessment.past_medical_history.treatment_detail)
            ? true
            : false,
        status2:
          (assessment &&
            assessment.past_medical_history &&
            assessment.past_medical_history.problems) ||
          (assessment &&
            assessment.past_medical_history &&
            assessment.past_medical_history.treatment) ||
          (assessment &&
            assessment.past_medical_history &&
            assessment.past_medical_history.treatment_detail)
            ? true
            : false,
        problems:
          assessment &&
          assessment.past_medical_history &&
          assessment.past_medical_history.problems,
        status_1:
          assessment &&
          assessment.marital_history &&
          assessment.marital_history.status,
        status5:
          assessment &&
          assessment.order_of_illness &&
          assessment.order_of_illness.predisposing_factors
            ? true
            : false,
        status6:
          assessment &&
          assessment.order_of_illness &&
          assessment.order_of_illness.prepetuating_factors
            ? true
            : false,
        status7:
          assessment &&
          assessment.order_of_illness &&
          assessment.order_of_illness.precipitating_factors
            ? true
            : false,
        status8:
          assessment &&
          assessment.order_of_illness &&
          assessment.order_of_illness.active_stressor
            ? true
            : false,
      })
    }
  }

  render() {
    const { diagnosis, problemData } = this.props
    const problem_data = _.pickBy(problemData, _.identity)
    console.log('this.state', this.state.details)
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="assessment"
          role="tabpanel"
          aria-labelledby="assessment-tab"
        >
          <div class="card-header">Assessment</div>
          <div class="content-body add-scrollbar scrollbar-dynamic"> */}
        <form onSubmit={this.handleSubmit} id='my-form'>
          <div class='crf-content-box'>
            <h4>Patient’s History</h4>
            <div class='radio-group-box'>
              <div class='input-group-row d-flex flex-wrap'>
                <div class='ig-column'>
                  <h5>Type Of Work Up</h5>
                  {this.renderSelect(
                    'type_of_work_up',
                    'Type Of Work Up',
                    this.state.work
                  )}
                </div>
              </div>
            </div>
            {this.state.data.type_of_work_up === 'Brief Take Up' && (
              <>
                <div class='pane-seprator'></div>
                <h4>History Of Chief Complaints</h4>
                <div class='radio-group-box'>
                  <div class='input-group-row d-flex flex-wrap'>
                    <div class='ig-column'>
                      <h5>Reliability</h5>
                      {this.renderSelect(
                        'reliability',
                        'Reliability',
                        this.props.reliability.map(ele => ({
                          _id: ele._id,
                          name: ele.title,
                        }))
                      )}
                    </div>
                  </div>
                </div>
                <div class='checkbox-group-row'>
                  <h6>General</h6>
                  <ul class='checkbox-group-list d-flex flex-wrap'>
                    {briedGeneral.map((e, i) => (
                      <li class='checkbox-group-item'>
                        <div class='form-check form-check-inline'>
                          <input
                            type='checkbox'
                            checked={this.state.briefGeneral.find(
                              el => el === e
                            )}
                            id={e + '1' + i}
                            class='form-check-input'
                            name='general1'
                            onChange={() => this.toggleBriefGeneral(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor={e + '1' + i}>
                            {e}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div class='form-group custom-input mt-3'>
                    {this.renderTextarea('briefGeneralOther', 'Other')}
                  </div>
                  <ul class='fm-form-list d-flex flex-wrap'>
                    <li class='fm-form-item'>
                      <div class='fm-form-box'>
                        {this.renderInput('briefDuration', 'Duration')}
                      </div>
                    </li>
                    <li class='fm-form-item'>
                      <div class='fm-form-box'>
                        {this.renderInput('briefFactors', 'Factors')}
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {this.state.data.type_of_work_up === 'Detailed Take Up' && (
              <>
                <div class='pane-seprator'></div>
                <h4>History Of Chief Complaints</h4>
                <div class='radio-group-box'>
                  <div class='input-group-row d-flex flex-wrap'>
                    <div class='ig-column'>
                      <h5>Problem Group</h5>
                      {this.renderSelect(
                        'problem_group',
                        'Problem Group',
                        this.props.problem.map(ele => ({
                          _id: ele._id,
                          name: ele.title,
                        }))
                      )}
                    </div>
                    <div class='ig-column'>
                      <h5>Reliability</h5>
                      {this.renderSelect(
                        'reliability',
                        'Reliability',
                        this.props.reliability.map(ele => ({
                          _id: ele._id,
                          name: ele.title,
                        }))
                      )}
                    </div>
                  </div>
                </div>
                {this.state.data.problem_group &&
                  problem_data &&
                  Object.entries(problem_data).map(([key, value]) => (
                    <>
                      {key === 'general' && (
                        <div class='checkbox-group-row'>
                          <h6>General</h6>
                          <ul class='checkbox-group-list d-flex flex-wrap'>
                            {value.map((e, i) => (
                              <li class='checkbox-group-item'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    type='checkbox'
                                    checked={this.state.general.find(
                                      el => el === e
                                    )}
                                    id={e + '1' + i}
                                    class='form-check-input'
                                    name='general1'
                                    onChange={() => this.toggleGeneral(e)}
                                  />
                                  <label
                                    class='form-check-label ml-4'
                                    htmlFor={e + '1' + i}>
                                    {e}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div class='form-group custom-input mt-3'>
                            {this.renderInput('general_other', 'Other')}
                          </div>
                        </div>
                      )}
                      {key === 'mood' && (
                        <div class='checkbox-group-row'>
                          <h6>Mood</h6>
                          <ul class='checkbox-group-list d-flex flex-wrap'>
                            {value.map((e, i) => (
                              <li class='checkbox-group-item'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    class='form-check-input'
                                    type='checkbox'
                                    value={e}
                                    id={e + '2' + i}
                                    checked={this.state.mood.find(
                                      el => el === e
                                    )}
                                    name='mood1'
                                    onChange={() => this.toggleMood(e)}
                                  />
                                  <label
                                    class='form-check-label ml-4'
                                    htmlFor={e + '2' + i}>
                                    {e}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div className='form-group custom-input mt-3'>
                            {this.renderInput('mood_other', 'Other')}
                          </div>
                        </div>
                      )}
                      {key === 'psychosis' && (
                        <div class='checkbox-group-row'>
                          <h6>Psychosis</h6>
                          <ul class='checkbox-group-list d-flex flex-wrap'>
                            {value.map((e, i) => (
                              <li class='checkbox-group-item'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    class='form-check-input'
                                    type='checkbox'
                                    value={e}
                                    id={e + '3'}
                                    checked={this.state.psychosis.find(
                                      el => el === e
                                    )}
                                    onChange={() => this.togglePsychosis(e)}
                                  />
                                  <label
                                    class='form-check-label ml-4'
                                    htmlFor={e + '3'}>
                                    {e}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div class='form-group custom-input mt-3'>
                            {this.renderInput('psychosis_other', 'Other')}
                          </div>
                        </div>
                      )}
                      {key === 'piological_functions' && (
                        <div class='checkbox-group-row'>
                          <h6>Biological Functions</h6>
                          <ul class='checkbox-group-list d-flex flex-wrap'>
                            {value.map((e, i) => (
                              <li class='checkbox-group-item'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    class='form-check-input'
                                    type='checkbox'
                                    value={e}
                                    id={e + '4'}
                                    checked={this.state.biological_functions.find(
                                      el => el === e
                                    )}
                                    onChange={() => this.toggleBiological(e)}
                                  />
                                  <label
                                    class='form-check-label ml-4'
                                    htmlFor={e + '4'}>
                                    {e}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div class='form-group custom-input mt-3'>
                            {this.renderInput(
                              'biological_functions_other',
                              'Other'
                            )}
                          </div>
                        </div>
                      )}
                      {key === 'addiction' && (
                        <div class='checkbox-group-row'>
                          <h6>Addiction</h6>
                          <ul class='checkbox-group-list d-flex flex-wrap'>
                            {value.map((e, i) => (
                              <li class='checkbox-group-item'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    class='form-check-input'
                                    type='checkbox'
                                    value={e}
                                    id={e + '5'}
                                    checked={this.state.addiction.find(
                                      el => el === e
                                    )}
                                    onChange={() => this.toggleAddiction(e)}
                                  />
                                  <label
                                    class='form-check-label ml-4'
                                    htmlFor={e + '5'}>
                                    {e}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <div class='form-group custom-input mt-3'>
                            {this.renderInput('addiction_other', 'Other')}
                          </div>
                        </div>
                      )}
                      {key === 'personality-Socio_Occupational' && (
                        <div class='checkbox-group-row'>
                          <h6>Personality/ Socio Occupational</h6>
                          <ul class='checkbox-group-list d-flex flex-wrap'>
                            {value.map((e, i) => (
                              <li class='checkbox-group-item'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    class='form-check-input'
                                    type='checkbox'
                                    value={e}
                                    id={e + '6'}
                                    checked={this.state.personality_Socio_Occupational.find(
                                      el => el === e
                                    )}
                                    onChange={() => this.togglePersonality(e)}
                                  />
                                  <label
                                    class='form-check-label ml-4'
                                    htmlFor={e + '6'}>
                                    {e}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  ))}
                <div class='textarea-row'>
                  <h5>Notes</h5>
                  {this.renderTextarea('notes', 'Notes')}
                </div>
                <div class='pane-seprator'></div>
                <div class='radio-group-box rg-row-outer'>
                  <h5>
                    Total duration, intensity and chronogical order of illness
                  </h5>
                  {this.state.details &&
                    this.state.details.map((e1, i) => (
                      <div class='rg-row add-disable active d-lg-flex flex-wrap align-items-lg-center'>
                        <div class='rg-row-content-left d-flex align-items-center'>
                          <div class='rg-row-right'>
                            <div class='form-check form-check-inline'>
                              <label class='form-check-label' htmlFor={e1.name}>
                                {e1.name}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class='rg-row-content-right d-lg-flex flex-wrap align-items-lg-center'>
                          <div class='custom-radio-group d-flex'>
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id={i + '1x'}
                                name=''
                                value='1x'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].value = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                checked={this.state.details[i].value === '1x'}
                              />
                              <label
                                class='custom-control-label'
                                htmlFor={i + '1x'}>
                                1x
                              </label>
                            </div>
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id={i + '2x'}
                                name=''
                                value='2x'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].value = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                checked={this.state.details[i].value === '2x'}
                              />
                              <label
                                class='custom-control-label'
                                htmlFor={i + '2x'}>
                                2x
                              </label>
                            </div>
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id={i + '3x'}
                                name=''
                                value='3x'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].value = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                checked={this.state.details[i].value === '3x'}
                              />
                              <label
                                class='custom-control-label'
                                htmlFor={i + '3x'}>
                                3x
                              </label>
                            </div>
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id={i + '4x'}
                                name=''
                                value='4x'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].value = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                checked={this.state.details[i].value === '4x'}
                              />
                              <label
                                class='custom-control-label'
                                htmlFor={i + '4x'}>
                                4x
                              </label>
                            </div>
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id={i + '5x'}
                                name=''
                                value='5x'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].value = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                checked={this.state.details[i].value === '5x'}
                              />
                              <label
                                class='custom-control-label'
                                htmlFor={i + '5x'}>
                                5x
                              </label>
                            </div>
                          </div>
                          <div class='number-input-field'>
                            <input
                              type='number'
                              name=''
                              min='0'
                              onChange={e => {
                                const details = [...this.state.details]
                                if (e) {
                                  details[i].duration = e.target.value
                                  this.setState({ details })
                                }
                              }}
                              value={this.state.details[i].duration}
                              className='form-control'
                              placeholder='Count'
                            />
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              <select
                                class='form-control'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].duration_type = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                value={this.state.details[i].duration_type}>
                                <option value='' disabled selected>
                                  Duration
                                </option>

                                {this.state.duration.map((e2, i2) => (
                                  <option key={i2}>{e2.name} </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              <select
                                class='form-control'
                                onChange={e => {
                                  const details = [...this.state.details]
                                  if (e) {
                                    details[i].by = e.target.value
                                    this.setState({ details })
                                  }
                                }}
                                value={this.state.details[i].by}>
                                <option value='' disabled selected>
                                  By
                                </option>

                                {this.state.illnessBy.map((e2, i2) => (
                                  <option key={i2}>{e2.name} </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div class='radio-group-box'>
                  <h5>Onset Of Illness</h5>
                  <div class='radio-group-row d-flex flex-wrap align-items-center'>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='acute'
                        name='illness'
                        value='acute'
                        onChange={e => this.toggleOnset(e)}
                        checked={this.state.data.onset === 'acute'}
                      />
                      <label class='form-check-label' htmlFor='acute'>
                        Acute (1wk)
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='subAcute'
                        name='illness'
                        value='subAcute'
                        onChange={e => this.toggleOnset(e)}
                        checked={this.state.data.onset === 'subAcute'}
                      />
                      <label class='form-check-label' htmlFor='subAcute'>
                        Sub-acute (1-4wks)
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='chronic'
                        name='illness'
                        value='chronic'
                        onChange={e => this.toggleOnset(e)}
                        checked={this.state.data.onset === 'chronic'}
                      />
                      <label class='form-check-label' htmlFor='chronic'>
                        Chronic (more than 4 wks)
                      </label>
                    </div>
                  </div>
                </div>

                <div class='radio-group-box'>
                  <h5>Progress Of Illness</h5>
                  <div class='radio-group-row d-flex flex-wrap align-items-center'>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='deteriorating'
                        name='progressOfIllness'
                        value='deteriorating'
                        onChange={e => this.toggleProgress(e)}
                        checked={this.state.data.progress === 'deteriorating'}
                      />
                      <label class='form-check-label' htmlFor='deteriorating'>
                        Deteriorating
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='static'
                        name='progressOfIllness'
                        value='static'
                        onChange={e => this.toggleProgress(e)}
                        checked={this.state.data.progress === 'static'}
                      />
                      <label class='form-check-label' htmlFor='static'>
                        Static
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='improving'
                        name='progressOfIllness'
                        value='improving'
                        onChange={e => this.toggleProgress(e)}
                        checked={this.state.data.progress === 'improving'}
                      />
                      <label class='form-check-label' htmlFor='improving'>
                        Improving
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='remitted'
                        name='progressOfIllness'
                        value='remitted'
                        onChange={e => this.toggleProgress(e)}
                        checked={this.state.data.progress === 'remitted'}
                      />
                      <label class='form-check-label' htmlFor='remitted'>
                        Remitted
                      </label>
                    </div>
                  </div>
                </div>
                <div class='radio-group-box'>
                  <h5>Course Of Illness</h5>
                  <div class='radio-group-row d-flex flex-wrap align-items-center'>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='continuio'
                        name='courseOfIllness'
                        value='Continuios'
                        onChange={e => this.toggleCourse(e)}
                        checked={this.state.data.course === 'Continuios'}
                      />
                      <label class='form-check-label' htmlFor='continuio'>
                        Continuios
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='episodic'
                        name='courseOfIllness'
                        value='Episodic'
                        onChange={e => this.toggleCourse(e)}
                        checked={this.state.data.course === 'Episodic'}
                      />
                      <label class='form-check-label' htmlFor='episodic'>
                        Episodic
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='fluctuating'
                        name='courseOfIllness'
                        value='Fluctuating'
                        onChange={e => this.toggleCourse(e)}
                        checked={this.state.data.course === 'Fluctuating'}
                      />
                      <label class='form-check-label' htmlFor='fluctuating'>
                        Fluctuating
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='relapse'
                        name='courseOfIllness'
                        value='Relapse'
                        onChange={e => this.toggleCourse(e)}
                        checked={this.state.data.course === 'Relapse'}
                      />
                      <label class='form-check-label' htmlFor='relapse'>
                        Relapse
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='others'
                        name='courseOfIllness'
                        value='Other'
                        onChange={e => this.toggleCourse(e)}
                        checked={this.state.data.course === 'Other'}
                      />
                      <label class='form-check-label' htmlFor='others'>
                        Other
                      </label>
                    </div>
                  </div>
                </div>
                <div class='radio-group-box'>
                  <h5>Number Of Episodes</h5>
                  <div class='radio-group-row d-flex flex-wrap align-items-center'>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='first'
                        name='episodeNum'
                        value='First'
                        onChange={e => this.toggleNumber(e)}
                        checked={this.state.data.episod_number === 'First'}
                      />
                      <label class='form-check-label' htmlFor='first'>
                        First
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='second'
                        name='episodeNum'
                        value='Second'
                        onChange={e => this.toggleNumber(e)}
                        checked={this.state.data.episod_number === 'Second'}
                      />
                      <label class='form-check-label' htmlFor='second'>
                        Second
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='third'
                        name='episodeNum'
                        value='Third'
                        onChange={e => this.toggleNumber(e)}
                        checked={this.state.data.episod_number === 'Third'}
                      />
                      <label class='form-check-label' htmlFor='third'>
                        Third
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='fourth'
                        name='episodeNum'
                        value='More than 3'
                        onChange={e => this.toggleNumber(e)}
                        checked={
                          this.state.data.episod_number === 'More than 3'
                        }
                      />
                      <label class='form-check-label' htmlFor='fourth'>
                        More than 3
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        type='radio'
                        class='form-check-input'
                        id='notKnown'
                        name='episodeNum'
                        value='Not Known'
                        onChange={e => this.toggleNumber(e)}
                        checked={this.state.data.episod_number === 'Not Known'}
                      />
                      <label class='form-check-label' htmlFor='notKnown'>
                        Not Known
                      </label>
                    </div>
                  </div>
                </div>
                <div class='input-group-row ig-multiple-column d-flex flex-wrap'>
                  <div class='ig-column'>
                    <div class='custom-toggle-button d-flex align-items-center flex-wrap'>
                      <p>Predisposing Factors</p>
                      <div class='custom-switch-outer d-flex align-items-center'>
                        <div class='custom-switch'>
                          <input
                            type='checkbox'
                            class='custom-switch'
                            onClick={() =>
                              this.setState({
                                status5: !this.state.status5,
                              })
                            }
                            id='custom-switch-13'
                            checked={this.state.status5 === false}
                          />
                          <label htmlFor='custom-switch-13'></label>
                        </div>
                      </div>
                    </div>
                    {this.state.status5 === false
                      ? this.renderInput(
                          'predisposing_factors',
                          'Start Typing...',
                          'text',
                          true
                        )
                      : this.renderInput(
                          'predisposing_factors',
                          'Start Typing...'
                        )}
                  </div>
                  <div class='ig-column'>
                    <div class='custom-toggle-button d-flex align-items-center flex-wrap'>
                      <p>Prepetuating Factors</p>
                      <div class='custom-switch-outer d-flex align-items-center'>
                        <div class='custom-switch'>
                          <input
                            type='checkbox'
                            class='custom-switch'
                            onClick={() =>
                              this.setState({
                                status6: !this.state.status6,
                              })
                            }
                            id='custom-switch-14'
                            checked={this.state.status6 === false}
                          />
                          <label htmlFor='custom-switch-14'></label>
                        </div>
                      </div>
                    </div>
                    {this.state.status6 === false
                      ? this.renderInput(
                          'prepetuating_factors',
                          'Start Typing...',
                          'text',
                          true
                        )
                      : this.renderInput(
                          'prepetuating_factors',
                          'Start Typing...'
                        )}
                  </div>
                  <div class='ig-column'>
                    <div class='custom-toggle-button d-flex align-items-center flex-wrap'>
                      <p>Precipitating Factors</p>
                      <div class='custom-switch-outer d-flex align-items-center'>
                        <div class='custom-switch'>
                          <input
                            type='checkbox'
                            class='custom-switch'
                            onClick={() =>
                              this.setState({
                                status7: !this.state.status7,
                              })
                            }
                            id='custom-switch-15'
                            checked={this.state.status7 === false}
                          />
                          <label htmlFor='custom-switch-15'></label>
                        </div>
                      </div>
                    </div>
                    {this.state.status7 === false
                      ? this.renderInput(
                          'precipitating_factors',
                          'Start Typing...',
                          'text',
                          true
                        )
                      : this.renderInput(
                          'precipitating_factors',
                          'Start Typing...'
                        )}
                  </div>
                  <div class='ig-column'>
                    <div class='custom-toggle-button d-flex align-items-center flex-wrap'>
                      <p>Active Stressor</p>
                      <div class='custom-switch-outer d-flex align-items-center'>
                        <div class='custom-switch'>
                          <input
                            type='checkbox'
                            class='custom-switch'
                            onClick={() =>
                              this.setState({
                                status8: !this.state.status8,
                              })
                            }
                            id='custom-switch-16'
                            checked={this.state.status8 === false}
                          />
                          <label htmlFor='custom-switch-16'></label>
                        </div>
                      </div>
                    </div>
                    {this.state.status8 === false
                      ? this.renderInput(
                          'active_stressor',
                          'Start Typing...',
                          'text',
                          true
                        )
                      : this.renderInput('active_stressor', 'Start Typing...')}
                  </div>
                </div>
                <div class='radio-group-box'>
                  <div class='textarea-row'>
                    <h5>Notes</h5>
                    {this.renderTextarea('illness_notes', 'Notes')}
                  </div>
                </div>
              </>
            )}
            <div class='pane-seprator'></div>
            <div class='radio-group-box'>
              <h5>Psychiatric/Psychological</h5>
              <div class='radio-group-row d-flex flex-wrap align-items-center'>
                <div class='form-check'>
                  <input
                    type='radio'
                    class='form-check-input'
                    id='pEpisodes1'
                    name='pepisode'
                    value={true}
                    onChange={e => this.toggleEpisodeTrue(true)}
                    checked={this.state.data.previous_episodes}
                  />
                  <label class='form-check-label' htmlFor='pEpisodes1'>
                    Previous Episodes
                  </label>
                </div>

                <div class='form-check'>
                  <input
                    type='radio'
                    class='form-check-input'
                    id='pEpisodes2'
                    name='pepisode'
                    value={false}
                    onChange={e => this.toggleEpisodeTrue(false)}
                    checked={!this.state.data.previous_episodes}
                  />
                  <label class='form-check-label' htmlFor='pEpisodes2'>
                    No Episodes
                  </label>
                </div>
              </div>
            </div>
            {this.state.data.previous_episodes === true && (
              <>
                <div class='grid-row-outer'>
                  <h5>Type of Episode</h5>
                  {this.state.data.type_of_work_up === 'Detailed Take Up' ? (
                    <>
                      <div class='tc-row'>
                        <div class='tc-form d-flex flex-wrap'>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_name1',
                                'Name of episodes',
                                this.state.episodes
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='number-input-field'>
                              {this.renderInput(
                                'episodes_count1',
                                'Count',
                                'number'
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_duration1',
                                'Duration',
                                this.state.duration
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='tc-row'>
                        <div class='tc-form d-flex flex-wrap'>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_name2',
                                'Name of episodes',
                                this.state.episodes
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='number-input-field'>
                              {this.renderInput(
                                'episodes_count2',
                                'Count',
                                'number'
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_duration2',
                                'Duration',
                                this.state.duration
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='tc-row'>
                        <div class='tc-form d-flex flex-wrap'>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_name3',
                                'Name of episodes',
                                this.state.episodes
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='number-input-field'>
                              {this.renderInput(
                                'episodes_count3',
                                'Count',
                                'number'
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_duration3',
                                'Duration',
                                this.state.duration
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4>Probable Diagnosis</h4>
                      <div class='diagnosis-box'>
                        <div class='row'>
                          <div class='col-lg-3 col-sm-12'>
                            <div class='dtb-content'>
                              <div class='radio-group-box'>
                                <div class='input-group-row'>
                                  <h5>Type Of diagnosis</h5>
                                  <Select
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      if (e) {
                                        data.type_of_diagnosis = e
                                        this.setState({ data })
                                      }
                                    }}
                                    placeholder=''
                                    styles={this.customStyles}
                                    classNameName='form-groupxx'
                                    closeMenuOnSelect={true}
                                    components={this.animatedComponents}
                                    options={this.state.type.map(e => ({
                                      label: e.name,
                                      value: e.id,
                                    }))}
                                    defaultValue={
                                      this.state.data.type_of_diagnosis
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='col-lg-9 col-sm-12'>
                            <div class='dtb-content'>
                              <div class='radio-group-box'>
                                <div class='input-group-row'>
                                  <h5>Diagnosis</h5>
                                  <Select
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      if (e) {
                                        data.diagnosis = e
                                        this.setState({ data })
                                      }
                                    }}
                                    placeholder=''
                                    styles={this.customStyles2}
                                    classNameName='form-groupxx'
                                    closeMenuOnSelect={true}
                                    components={this.animatedComponents}
                                    options={diagnosis.map(e => ({
                                      label: e.diagnosis + ' - ' + e.value,
                                      value: e._id,
                                    }))}
                                    defaultValue={this.state.data.diagnosis}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class='tc-row'>
                        <div class='tc-form d-flex flex-wrap'>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_name1',
                                'Name of episodes',
                                this.state.episodes
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='number-input-field'>
                              {this.renderInput(
                                'episodes_count1',
                                'Count',
                                'number'
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_duration1',
                                'Duration',
                                this.state.duration
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='tc-row'>
                        <div class='tc-form d-flex flex-wrap'>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_name2',
                                'Name of episodes',
                                this.state.episodes
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='number-input-field'>
                              {this.renderInput(
                                'episodes_count2',
                                'Count',
                                'number'
                              )}
                            </div>
                          </div>
                          <div class='tc-column'>
                            <div class='form-group show'>
                              {this.renderSelect(
                                'episodes_duration2',
                                'Duration',
                                this.state.duration
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {this.state.data.type_of_work_up === 'Detailed Take Up' && (
              <>
                <div class='pane-seprator'></div>
                <div class='custom-switch-heading d-flex align-items-center'>
                  <h3>Past Mental Health Treatment</h3>
                  <div class='custom-switch-outer d-flex align-items-center'>
                    <div class='custom-switch'>
                      <input
                        type='checkbox'
                        class='custom-switch'
                        onClick={() =>
                          this.setState({ status4: !this.state.status4 })
                        }
                        id='custom-switch-11'
                        checked={this.state.status4 === false}
                      />
                      <label htmlFor='custom-switch-11'></label>
                    </div>
                  </div>
                </div>
                {this.state.status4 === true && (
                  <>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='form-check form-check-inline'>
                          <label
                            class='form-check-label ml-3'
                            for='Psychotherapys2'>
                            Psychotherapy
                          </label>
                        </div>
                      </div>
                      <div class='tc-row-body'>
                        <div class='tc-row'>
                          <div class=' btn-group-toggle' data-toggle='buttons'>
                            <label
                              className={
                                this.state.psychotherapy1 === true
                                  ? 'btn  active tc-btn'
                                  : 'btn  tc-btn'
                              }>
                              <input
                                type='radio'
                                name='options'
                                id='Psychotherpy1'
                                onClick={this.togglePsychotherapy1}
                                checked={this.state.psychotherapy1 === true}
                              />{' '}
                              Psychotherpy 1
                            </label>

                            <label
                              className={
                                this.state.psychotherapy2 === true
                                  ? 'btn  active tc-btn'
                                  : 'btn  tc-btn'
                              }>
                              <input
                                type='radio'
                                name='options'
                                id='Psychotherpy2'
                                onClick={this.togglePsychotherapy2}
                                checked={this.state.psychotherapy2 === true}
                              />{' '}
                              Psychotherpy 2
                            </label>
                          </div>
                        </div>
                        {this.state.psychotherapy1 === true && (
                          <>
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput(
                                    'psychotherapy_1therapy1',
                                    'Name of therapy'
                                  )}
                                </div>
                                <div class='tc-column'>
                                  <div class='number-input-field'>
                                    {this.renderInput(
                                      'psychotherapy_1therapy_count1',
                                      'Count',
                                      'number'
                                    )}
                                  </div>
                                </div>

                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_1therapy_duration1',
                                      'Duration',
                                      this.state.duration
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_1compliance1',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_1therapy_response1',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div class="tc-row">
                                  <div class="tc-form d-flex flex-wrap">
                                    <div class="tc-column">
                                      {this.renderInput(
                                        "psychotherapy_1therapy2",
                                        "Name of therapy"
                                      )}
                                    </div>
                                    <div class="tc-column">
                                      <div class="number-input-field">
                                        {this.renderInput(
                                          "psychotherapy_1therapy_count2",
                                          "Count",
                                          "number"
                                        )}
                                      </div>
                                    </div>
                                    <div class="tc-column">
                                      <div class="form-group show">
                                        {this.renderSelect(
                                          "psychotherapy_1therapy_duration2",
                                          "Duration",
                                          this.state.duration
                                        )}
                                      </div>
                                    </div>
                                    <div class="tc-column">
                                      <div class="form-group show">
                                        {this.renderSelect(
                                          "psychotherapy_1compliance2",
                                          "Compliance",
                                          this.state.compliance
                                        )}
                                      </div>
                                    </div>
                                    <div class="tc-column">
                                      <div class="form-group show">
                                        {this.renderSelect(
                                          "psychotherapy_1therapy_response2",
                                          "Response",
                                          this.state.response
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                          </>
                        )}
                        {this.state.psychotherapy2 === true && (
                          <>
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput(
                                    'psychotherapy_2therapy1',
                                    'Name of therapy'
                                  )}
                                </div>
                                <div class='tc-column'>
                                  <div class='number-input-field'>
                                    {this.renderInput(
                                      'psychotherapy_2therapy_count1',
                                      'Count',
                                      'number'
                                    )}
                                  </div>
                                </div>

                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2therapy_duration1',
                                      'Duration',
                                      this.state.duration
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2compliance1',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2therapy_response1',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div class="tc-row">
                                  <div class="tc-form d-flex flex-wrap">
                                    <div class="tc-column">
                                      {this.renderInput(
                                        "psychotherapy_2therapy2",
                                        "Name of therapy"
                                      )}
                                    </div>
                                    <div class="tc-column">
                                      <div class="number-input-field">
                                        {this.renderInput(
                                          "psychotherapy_2therapy_count2",
                                          "Count",
                                          "number"
                                        )}
                                      </div>
                                    </div>
                                    <div class="tc-column">
                                      <div class="form-group show">
                                        {this.renderSelect(
                                          "psychotherapy_2therapy_duration2",
                                          "Duration",
                                          this.state.duration
                                        )}
                                      </div>
                                    </div>
                                    <div class="tc-column">
                                      <div class="form-group show">
                                        {this.renderSelect(
                                          "psychotherapy_2compliance2",
                                          "Compliance",
                                          this.state.compliance
                                        )}
                                      </div>
                                    </div>
                                    <div class="tc-column">
                                      <div class="form-group show">
                                        {this.renderSelect(
                                          "psychotherapy_2therapy_response2",
                                          "Response",
                                          this.state.response
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                          </>
                        )}
                      </div>
                    </div>
                    {this.state.psychotherapy1 === true && (
                      <div class='three-column-row'>
                        <div class='tc-row-head'>
                          <div class='form-check form-check-inline'>
                            <label
                              class='form-check-label ml-3'
                              for='Psychotherapys'>
                              Medicines
                            </label>
                          </div>
                        </div>
                        <div class='tc-row-body'>
                          <div class='tc-row'>
                            <div
                              class=' btn-group-toggle'
                              data-toggle='buttons'>
                              <label
                                className={
                                  this.state.medicine1 === true
                                    ? 'btn  active tc-btn'
                                    : 'btn  tc-btn'
                                }>
                                <input
                                  type='radio'
                                  name='options'
                                  id='Medicine1'
                                  onClick={this.toggleMedicine1}
                                  checked={this.state.medicine1 === true}
                                />{' '}
                                Medicine 1
                              </label>
                              <label
                                className={
                                  this.state.medicine2 === true
                                    ? 'btn  active tc-btn'
                                    : 'btn  tc-btn'
                                }>
                                <input
                                  type='radio'
                                  name='options'
                                  id='Medicine1'
                                  onClick={this.toggleMedicine2}
                                  checked={this.state.medicine2 === true}
                                />{' '}
                                Medicine 2
                              </label>
                              <label
                                className={
                                  this.state.medicine3 === true
                                    ? 'btn  active tc-btn'
                                    : 'btn  tc-btn'
                                }>
                                <input
                                  type='radio'
                                  name='options'
                                  id='Medicine1'
                                  onClick={this.toggleMedicine3}
                                  checked={this.state.medicine2 === true}
                                />{' '}
                                Medicine 3
                              </label>
                            </div>
                          </div>
                          {this.state.medicine1 === true && (
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput('medicine_name1', 'Name')}
                                </div>
                                <div
                                  class='tc-column'
                                  style={{ width: '50px' }}>
                                  {this.renderInput('medicine_dose1', 'Dose')}
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    <Select
                                      key={
                                        this.state.data.medicine_side_effect1 &&
                                        this.state.data.medicine_side_effect1
                                          .length
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data.medicine_side_effect1 = e
                                          this.setState({ data })
                                        }
                                      }}
                                      isMulti
                                      placeholder='Side effects'
                                      styles={this.customStyles}
                                      classNameName='form-groupxx'
                                      closeMenuOnSelect={true}
                                      components={this.animatedComponents}
                                      options={this.state.sideEffects.map(
                                        e => ({
                                          label: e.name,
                                          value: e.id,
                                        })
                                      )}
                                      defaultValue={
                                        this.state.data.medicine_side_effect1
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'medicine_compliance1',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'medicine_response1',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.medicine2 === true && (
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput('medicine_name2', 'Name')}
                                </div>
                                <div
                                  class='tc-column'
                                  style={{ width: '50px' }}>
                                  {this.renderInput('medicine_dose2', 'Dose')}
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    <Select
                                      key={
                                        this.state.data.medicine_side_effect2 &&
                                        this.state.data.medicine_side_effect2
                                          .length
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data.medicine_side_effect2 = e
                                          this.setState({ data })
                                        }
                                      }}
                                      isMulti
                                      placeholder='Side effects'
                                      styles={this.customStyles}
                                      classNameName='form-groupxx'
                                      closeMenuOnSelect={false}
                                      components={this.animatedComponents}
                                      options={this.state.sideEffects.map(
                                        e => ({
                                          label: e.name,
                                          value: e.id,
                                        })
                                      )}
                                      defaultValue={
                                        this.state.data.medicine_side_effect2
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'medicine_compliance2',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'medicine_response2',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.medicine3 === true && (
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput('medicine_name3', 'Name')}
                                </div>
                                <div
                                  class='tc-column'
                                  style={{ width: '50px' }}>
                                  {this.renderInput('medicine_dose3', 'Dose')}
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    <Select
                                      key={
                                        this.state.data.medicine_side_effect3 &&
                                        this.state.data.medicine_side_effect3
                                          .length
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data.medicine_side_effect3 = e
                                          this.setState({ data })
                                        }
                                      }}
                                      isMulti
                                      placeholder='Side effects'
                                      styles={this.customStyles}
                                      classNameName='form-groupxx'
                                      closeMenuOnSelect={false}
                                      components={this.animatedComponents}
                                      options={this.state.sideEffects.map(
                                        e => ({
                                          label: e.name,
                                          value: e.id,
                                        })
                                      )}
                                      defaultValue={
                                        this.state.data.medicine_side_effect3
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'medicine_compliance3',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'medicine_response3',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.psychotherapy2 === true && (
                      <div class='three-column-row'>
                        <div class='tc-row-head'>
                          <div class='form-check form-check-inline'>
                            <label
                              class='form-check-label ml-3'
                              for='Psychotherapys'>
                              Medicines
                            </label>
                          </div>
                        </div>
                        <div class='tc-row-body'>
                          <div class='tc-row'>
                            <div
                              class=' btn-group-toggle'
                              data-toggle='buttons'>
                              <label
                                className={
                                  this.state.medicine1 === true
                                    ? 'btn  active tc-btn'
                                    : 'btn  tc-btn'
                                }>
                                <input
                                  type='radio'
                                  name='options'
                                  id='Medicine1'
                                  onClick={this.toggleMedicine1}
                                  checked={this.state.medicine1 === true}
                                />{' '}
                                Medicine 1
                              </label>
                              <label
                                className={
                                  this.state.medicine2 === true
                                    ? 'btn  active tc-btn'
                                    : 'btn  tc-btn'
                                }>
                                <input
                                  type='radio'
                                  name='options'
                                  id='Medicine1'
                                  onClick={this.toggleMedicine2}
                                  checked={this.state.medicine2 === true}
                                />{' '}
                                Medicine 2
                              </label>
                              <label
                                className={
                                  this.state.medicine3 === true
                                    ? 'btn  active tc-btn'
                                    : 'btn  tc-btn'
                                }>
                                <input
                                  type='radio'
                                  name='options'
                                  id='Medicine1'
                                  onClick={this.toggleMedicine3}
                                  checked={this.state.medicine2 === true}
                                />{' '}
                                Medicine 3
                              </label>
                            </div>
                          </div>
                          {this.state.medicine1 === true && (
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput(
                                    'psychotherapy_2medicine_name1',
                                    'Name'
                                  )}
                                </div>
                                <div
                                  class='tc-column'
                                  style={{ width: '50px' }}>
                                  {this.renderInput(
                                    'psychotherapy_2medicine_dose1',
                                    'Dose'
                                  )}
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    <Select
                                      key={
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect1 &&
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect1
                                          .length
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data.psychotherapy_2medicine_side_effect1 =
                                            e
                                          this.setState({ data })
                                        }
                                      }}
                                      isMulti
                                      placeholder='Side effects'
                                      styles={this.customStyles}
                                      classNameName='form-groupxx'
                                      closeMenuOnSelect={false}
                                      components={this.animatedComponents}
                                      options={this.state.sideEffects.map(
                                        e => ({
                                          label: e.name,
                                          value: e.id,
                                        })
                                      )}
                                      defaultValue={
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect1
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2medicine_compliance1',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2medicine_response1',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.medicine2 === true && (
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput(
                                    'psychotherapy_2medicine_name2',
                                    'Name'
                                  )}
                                </div>
                                <div
                                  class='tc-column'
                                  style={{ width: '50px' }}>
                                  {this.renderInput(
                                    'psychotherapy_2medicine_dose2',
                                    'Dose'
                                  )}
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    <Select
                                      key={
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect2 &&
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect2
                                          .length
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data.psychotherapy_2medicine_side_effect2 =
                                            e
                                          this.setState({ data })
                                        }
                                      }}
                                      isMulti
                                      placeholder='Side effects'
                                      styles={this.customStyles}
                                      classNameName='form-groupxx'
                                      closeMenuOnSelect={false}
                                      components={this.animatedComponents}
                                      options={this.state.sideEffects.map(
                                        e => ({
                                          label: e.name,
                                          value: e.id,
                                        })
                                      )}
                                      defaultValue={
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect2
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2medicine_compliance2',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2medicine_response2',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.medicine3 === true && (
                            <div class='tc-row'>
                              <div class='tc-form d-flex flex-wrap'>
                                <div class='tc-column'>
                                  {this.renderInput(
                                    'psychotherapy_2medicine_name3',
                                    'Name'
                                  )}
                                </div>
                                <div
                                  class='tc-column'
                                  style={{ width: '50px' }}>
                                  {this.renderInput(
                                    'psychotherapy_2medicine_dose3',
                                    'Dose'
                                  )}
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    <Select
                                      key={
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect3 &&
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect3
                                          .length
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data.psychotherapy_2medicine_side_effect3 =
                                            e
                                          this.setState({ data })
                                        }
                                      }}
                                      isMulti
                                      placeholder='Side effects'
                                      styles={this.customStyles}
                                      classNameName='form-groupxx'
                                      closeMenuOnSelect={false}
                                      components={this.animatedComponents}
                                      options={this.state.sideEffects.map(
                                        e => ({
                                          label: e.name,
                                          value: e.id,
                                        })
                                      )}
                                      defaultValue={
                                        this.state.data
                                          .psychotherapy_2medicine_side_effect3
                                      }
                                    />
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2medicine_compliance3',
                                      'Compliance',
                                      this.state.compliance
                                    )}
                                  </div>
                                </div>
                                <div class='tc-column'>
                                  <div class='form-group show'>
                                    {this.renderSelect(
                                      'psychotherapy_2medicine_response3',
                                      'Response',
                                      this.state.response
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            <div class='pane-seprator'></div>
            <div class='custom-switch-heading d-flex align-items-center'>
              <h3>Past Medical History</h3>
              <div class='custom-switch-outer d-flex align-items-center'>
                <div class='custom-switch'>
                  <input
                    type='checkbox'
                    class='custom-switch'
                    onClick={() =>
                      this.setState({ status3: !this.state.status3 })
                    }
                    id='custom-switch-8'
                    checked={this.state.status3 === false}
                  />
                  <label htmlFor='custom-switch-8'></label>
                </div>
              </div>
            </div>
            {this.state.status3 === true && (
              <div class='three-column-row'>
                <div class='new-checkbox-group-box'>
                  <ul class='checkbox-group-list d-flex flex-wrap new-checkbox-group-list'>
                    {past.map(e => (
                      <li class='checkbox-group-item'>
                        <div class='form-check form-check-inline'>
                          <input
                            type='checkbox'
                            checked={
                              this.state.problems &&
                              this.state.problems.find(el => el === e.Past)
                            }
                            id={e.Past}
                            class='form-check-input'
                            name='selectOption_15'
                            onChange={() => this.changeLang(e.Past)}
                          />
                          <label class='form-check-label ml-4' htmlFor={e.Past}>
                            {e.Past}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div class='ig-column'>
                    {this.state.problems &&
                    this.state.problems.find(el => el === 'Others')
                      ? this.renderInput('other_problem', 'Start Typing')
                      : ''}
                  </div>

                  <div class='radio-group-box'>
                    <div class='radio-group-row d-flex flex-wrap align-items-center'>
                      <div class='form-check'>
                        <input
                          type='radio'
                          class='form-check-input'
                          id='TreatmentTaken'
                          name='treatment'
                          value='Treatment Taken'
                          onChange={e => this.toggleTreatment(e)}
                          checked={
                            this.state.data.treatment === 'Treatment Taken'
                          }
                        />
                        <label
                          class='form-check-label'
                          htmlFor='TreatmentTaken'>
                          Treatment Taken
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          type='radio'
                          class='form-check-input'
                          id='OnTreatment'
                          name='treatment'
                          value='Currently On Treatment'
                          onChange={e => this.toggleTreatment(e)}
                          checked={
                            this.state.data.treatment ===
                            'Currently On Treatment'
                          }
                        />
                        <label class='form-check-label' htmlFor='OnTreatment'>
                          Currently On Treatment
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='ig-column'>
                    {this.renderInput(
                      'treatment_detail',
                      'Details if available...'
                    )}
                  </div>
                </div>
              </div>
            )}
            <div class='pane-seprator'></div>
            <div class='custom-switch-heading d-flex align-items-center'>
              <h3>Family Members</h3>
              <div class='custom-switch-outer d-flex align-items-center'>
                <div class='custom-switch'>
                  <input
                    type='checkbox'
                    class='custom-switch'
                    onClick={() =>
                      this.setState({ status2: !this.state.status2 })
                    }
                    id='custom-switch-9'
                    checked={this.state.status2 === false}
                  />
                  <label htmlFor='custom-switch-9'></label>
                </div>
              </div>
            </div>
            {this.state.status2 === true && (
              <>
                <div className='three-column-row'>
                  <div className='tc-row-body p-0'>
                    <div className='tc-row'>
                      <div className=' btn-group-toggle' data-toggle='buttons'>
                        <label
                          className={
                            this.state.member1 === true
                              ? 'btn  active tc-btn'
                              : 'btn  tc-btn'
                          }>
                          <input
                            type='radio'
                            name='options'
                            id='Member1'
                            checked={this.state.member1 === true}
                            onClick={this.toggleMember1}
                          />{' '}
                          Member 1
                        </label>
                        <label
                          className={
                            this.state.member2 === true
                              ? 'btn  active tc-btn'
                              : 'btn  tc-btn'
                          }>
                          <input
                            type='radio'
                            name='options'
                            id='Member2'
                            onClick={this.toggleMember2}
                            checked={this.state.member2 === true}
                          />{' '}
                          Member 2
                        </label>
                        <label
                          className={
                            this.state.member3 === true
                              ? 'btn  active tc-btn'
                              : 'btn  tc-btn'
                          }>
                          <input
                            type='radio'
                            name='options'
                            id='Member3'
                            onClick={this.toggleMember3}
                            checked={this.state.member3 === true}
                          />{' '}
                          Member 3
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.member1 === true && (
                  <>
                    <div class='three-column-row'>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'relation1',
                                    'Relationship',
                                    this.state.relations
                                  )}
                                </div>
                              </div>
                            </li>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'status1',
                                    'Status',
                                    this.state.member_status
                                  )}
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'dynamics1',
                                        'Dynamics',
                                        this.state.member_dynamics
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='textarea-row'>
                          <h5>Any Psychiatric/Psychological history</h5>
                        </div>
                      </div>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'member_psychiatric1',
                                    'Members Psychiatric/Psychological',
                                    this.state.member_psychiatric
                                  )}
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_treatment1',
                                        'On Treatment',
                                        this.state.member_treatment
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_state1',
                                        'Current State',
                                        this.state.member_state
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='textarea-row'>
                          <h5>Medical History</h5>
                        </div>
                      </div>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  <Select
                                    key={
                                      this.state.data.medical_history1 &&
                                      this.state.data.medical_history1.length
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      if (e) {
                                        data.medical_history1 = e
                                        this.setState({ data })
                                      }
                                    }}
                                    isMulti
                                    styles={this.customStyles}
                                    classNameName='form-groupxx'
                                    closeMenuOnSelect={false}
                                    components={this.animatedComponents}
                                    options={this.state.medical_history.map(
                                      e => ({
                                        label: e.name,
                                        value: e.id,
                                      })
                                    )}
                                    defaultValue={
                                      this.state.data.medical_history1
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_treatment_history1',
                                        'On Treatment',
                                        this.state.member_treatment
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_state_history1',
                                        'Current State',
                                        this.state.member_state
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.member2 === true && (
                  <>
                    <div class='three-column-row'>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'relation2',
                                    'Relationship',
                                    this.state.relations
                                  )}
                                </div>
                              </div>
                            </li>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'status2',
                                    'Status',
                                    this.state.member_status
                                  )}
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'dynamics2',
                                        'Dynamics',
                                        this.state.member_dynamics
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='textarea-row'>
                          <h5>Members Psychiatric/Psychological</h5>
                        </div>
                      </div>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'member_psychiatric2',
                                    'Members Psychiatric/Psychological',
                                    this.state.member_psychiatric
                                  )}
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_treatment2',
                                        'On Treatment',
                                        this.state.member_treatment
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_state2',
                                        'Current State',
                                        this.state.member_state
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='textarea-row'>
                          <h5>Medical History</h5>
                        </div>
                      </div>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  <Select
                                    key={
                                      this.state.data.medical_history2 &&
                                      this.state.data.medical_history2.length
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      if (e) {
                                        data.medical_history2 = e
                                        this.setState({ data })
                                      }
                                    }}
                                    isMulti
                                    styles={this.customStyles}
                                    classNameName='form-groupxx'
                                    closeMenuOnSelect={false}
                                    components={this.animatedComponents}
                                    options={this.state.medical_history.map(
                                      e => ({
                                        label: e.name,
                                        value: e.id,
                                      })
                                    )}
                                    defaultValue={
                                      this.state.data.medical_history2
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_treatment_history2',
                                        'On Treatment',
                                        this.state.member_treatment
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_state_history2',
                                        'Current State',
                                        this.state.member_state
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.member3 === true && (
                  <>
                    <div class='three-column-row'>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'relation3',
                                    'Relationship',
                                    this.state.relations
                                  )}
                                </div>
                              </div>
                            </li>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'status3',
                                    'Status',
                                    this.state.member_status
                                  )}
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'dynamics3',
                                        'Dynamics',
                                        this.state.member_dynamics
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='textarea-row'>
                          <h5>Members Psychiatric/Psychological</h5>
                        </div>
                      </div>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  {this.renderSelect(
                                    'member_psychiatric3',
                                    'Members Psychiatric/Psychological',
                                    this.state.member_psychiatric
                                  )}
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_treatment3',
                                        'On Treatment',
                                        this.state.member_treatment
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_state3',
                                        'Current State',
                                        this.state.member_state
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class='three-column-row'>
                      <div class='tc-row-head'>
                        <div class='textarea-row'>
                          <h5>Medical History</h5>
                        </div>
                      </div>
                      <div class='tc-row-body p-0'>
                        <div class='fm-box'>
                          <ul class='fm-form-list d-flex flex-wrap'>
                            <li class='fm-form-item'>
                              <div class='fm-form-box'>
                                <div class='form-group show'>
                                  <Select
                                    key={
                                      this.state.data.medical_history3 &&
                                      this.state.data.medical_history3.length
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      if (e) {
                                        data.medical_history3 = e
                                        this.setState({ data })
                                      }
                                    }}
                                    isMulti
                                    styles={this.customStyles}
                                    classNameName='form-groupxx'
                                    closeMenuOnSelect={false}
                                    components={this.animatedComponents}
                                    options={this.state.medical_history.map(
                                      e => ({
                                        label: e.name,
                                        value: e.id,
                                      })
                                    )}
                                    defaultValue={
                                      this.state.data.medical_history3
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                            {this.state.data.type_of_work_up ===
                              'Detailed Take Up' && (
                              <>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_treatment_history3',
                                        'On Treatment',
                                        this.state.member_treatment
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li class='fm-form-item'>
                                  <div class='fm-form-box'>
                                    <div class='form-group show'>
                                      {this.renderSelect(
                                        'member_state_history3',
                                        'Current State',
                                        this.state.member_state
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div class='radio-group-box'>
                  <div class='textarea-row'>
                    <h5>Notes</h5>
                    {this.renderTextarea('family_notes')}
                  </div>
                </div>
              </>
            )}

            {this.state.data.type_of_work_up === 'Detailed Take Up' && (
              <>
                <div class='pane-seprator'></div>
                <div class='three-column-row'>
                  <div class='tc-row-head'>
                    <div class='custom-switch-heading d-flex align-items-center'>
                      <h3>Marital History</h3>
                      <div class='custom-switch-outer d-flex align-items-center'>
                        <div class='custom-switch'>
                          <input
                            type='checkbox'
                            class='custom-switch'
                            onClick={() =>
                              this.setState({
                                status_1: !this.state.status_1,
                              })
                            }
                            id='custom-switch-10'
                            checked={!this.state.status_1}
                          />
                          <label htmlFor='custom-switch-10'></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.status_1 === true && (
                    <div class='tc-row-body p-0'>
                      <div class='fm-box'>
                        <ul class='fm-form-list d-flex flex-wrap'>
                          <li class='fm-form-item'>
                            <div class='fm-form-box'>
                              <div class='textarea-row'>
                                <h5>
                                  <strong>Patient</strong>
                                </h5>
                              </div>
                              <div class='form-group show'>
                                {this.renderSelect(
                                  'patient',
                                  'Status',
                                  this.state.status
                                )}
                              </div>
                            </div>
                          </li>
                          <li class='fm-form-item'>
                            <div class='fm-form-box'>
                              <div class='textarea-row'>
                                <h5>
                                  <strong>Parents</strong>
                                </h5>
                              </div>
                              <div class='form-group show'>
                                {this.renderSelect(
                                  'parents',
                                  'Status',
                                  this.state.status
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </form>
        {/* </div>
        </div> */}
        <div class='patients-details-footer d-md-flex align-items-md-center justify-content-md-between'>
          <Link to='/doctor/dashboard'>
            <button class='btn btn-grey'>EXIT</button>
          </Link>
          {/* <div class="pdf-btn d-flex align-items-center">
            <a href="javascript:void(0)">
              <img src={download} />
            </a>
          </div> */}
          <button type='submit' class='btn btn-primary btn-lg' form='my-form'>
            SAVE
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  demographicsAdd: getCaserecord(state).demographicsAdd,
  problem: getCaserecord(state).problemgroups,
  reliability: getCaserecord(state).reliability,
  diagnosis: getCaserecord(state).diagnosis,
  getCaserecord: getCaserecord(state),
  problemData: getCaserecord(state).problemData,
  case: getCaserecord(state).caserecord,
  loading: getCaserecord(state).caseRecordLoading,
})
const mapDispatchToProps = dispatch => ({
  addDemographics: (params, id, callBack) =>
    dispatch(addDemographics(params, id, callBack)),
  loadProblem: () => dispatch(loadProblem()),
  loadReliability: () => dispatch(loadReliability()),
  loadDiagnosis: params => dispatch(loadDiagnosis(params)),
  loadProblemData: id => dispatch(loadProblemData(id)),
  loadCaseRecord: id => dispatch(loadCaseRecord(id)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Assessment)
)
