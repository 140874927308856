import React, { Component, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getAppointment,
  loadAllAppointmentForDoctor,
} from '../../../store/appointment'
import JourneyList from './journeyList'
import { getDoctor } from '../../../store/doctor'
import { loadChatForDoctor } from '../../../store/chats'

const Journey = props => {
  const [page, setPage] = useState(1)
  const [initialData, setInitialData] = useState([])
  const [paginate, setPaginate] = useState([])

  useEffect(() => {
    document.body.className = 'dashboard fixed'
    props.loadAllAppointmentForDoctor(
      {
        page: page,
        patient_id: props.id,
      },
      callbackLoad
    )
  }, [])

  const loadFunc = () => {
    if (
      Math.ceil(
        props.appointment.pagination.filtered_record /
          props.appointment.pagination.record_per_page
      ) > page
    ) {
      props.loadAllAppointmentForDoctor(
        {
          page: page + 1,
        },
        callbackLoad
      )
      setPage(page + 1)
    }
  }

  const callbackLoad = res => {
    console.log('call', res)
    if (res && res.status === 200) {
      const { data, pagination } = res.data
      setPaginate(pagination)
      setInitialData([...initialData, ...data])
    }
  }

  const { appointment, loading } = props

  console.log('ini', initialData)

  return (
    <>
      {/* // <div
    //   class="tab-pane card fade show active"
    //   id="Journey"
    //   role="tabpanel"
    //   aria-labelledby="Journey-tab"
    // >
    //   <div class="card-header">Journey</div>
    // <div class="content-body show add-scrollbar  scrollbar-dynamic"> */}
      {initialData && initialData.length > 0 ? (
        <ul class='new-profile-list'>
          <JourneyList
            loadChatForDoctor={props.loadChatForDoctor}
            //states={this.state}
            appointment={initialData}
            pagination={paginate}
            loadFunc={loadFunc}
            loading={loading}
            doctorProfile={props.doctorProfile}
          />
        </ul>
      ) : (
        <ul class='new-profile-list'>
          <li class='np-item'>No Records Found.</li>
        </ul>
      )}
      {/* </div>
    // </div> */}
    </>
  )
}
// class Journey extends Component {
//   state = { page: 1 }
//   componentDidMount() {
//     document.body.className = 'dashboard fixed'
//     this.props.loadAllAppointmentForDoctor({
//       page: 1,
//       patient_id: this.props.id,
//     })
//   }
//   loadFunc = () => {
//     if (
//       Math.ceil(
//         this.props.appointment.pagination.filtered_record /
//           this.props.appointment.pagination.record_per_page
//       ) > this.state.page
//     ) {
//       this.setState({ page: this.state.page + 1 }, () =>
//         this.props.loadAllAppointmentForDoctor({
//           page: this.state.page + 1,
//           patient_id: this.props.location.state,
//         })
//       )
//     }
//   }

//   render() {
//     const { appointment, loading } = this.props
//     const { data, pagination } = appointment
//     return (
//       // <div
//       //   class="tab-pane card fade show active"
//       //   id="Journey"
//       //   role="tabpanel"
//       //   aria-labelledby="Journey-tab"
//       // >
//       //   <div class="card-header">Journey</div>
//       // <div class="content-body show add-scrollbar  scrollbar-dynamic">
//       data.length > 0 ? (
//         <ul class='new-profile-list'>
//           <JourneyList
//             loadChatForDoctor={this.props.loadChatForDoctor}
//             states={this.state}
//             appointment={data}
//             pagination={pagination}
//             loadFunc={this.loadFunc}
//             loading={loading}
//             doctorProfile={this.props.doctorProfile}
//           />
//         </ul>
//       ) : (
//         <ul class='new-profile-list'>
//           <li class='np-item'>No Records Found.</li>
//         </ul>
//       )
//       //   {/* </div>
//       // </div> */}
//     )
//   }
// }

const mapStateToProps = state => ({
  appointment: getAppointment(state).doctorAppointment,
  loading: getAppointment(state).loadingDoctorAppointment,
  doctorProfile: getDoctor(state).profile,
})
const mapDispatchToProps = dispatch => ({
  loadChatForDoctor: id => dispatch(loadChatForDoctor(id)),
  loadAllAppointmentForDoctor: (params, callback) =>
    dispatch(loadAllAppointmentForDoctor(params, callback)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Journey))
