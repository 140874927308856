import React, { Component } from 'react'
import Joi from 'joi-browser'
import moment from 'moment'
import SubmitBtn from '../../../common/form/submitBtn'
import {
  dobInvalid,
  dobRequired,
  emailInvalid,
  emailRequired,
  firstNameRequired,
  fnameMax,
  lastNameRequired,
  lnameMax,
  mobileInvalid,
  mobileRequired,
  typeRequired,
  sexRequired,
  mobileCodeRequired,
} from '../../../common/misc'
import Form from '../../../common/form/form'
import SelectSearch from '../../../common/form/selectSearch'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  emailAvailability,
  mobileAvailability,
  sendOtp,
  addExistingPatientByDoctor,
} from '../../../../store/patient'
import { toast } from 'react-toastify'
import AlertError from '../../../common/alertError'
import AlertSuccess from '../../../common/alertSuccess'
import { countries } from '../../../../constants/country'
import Select from 'react-select'
import { State, City, Country } from 'country-state-city'

class PersonalDetails extends Form {
  state = {
    loader: false,
    btnClass: 'btn btn-lg btn-primary btn-block',
    data: {
      first_name: '',
      last_name: '',
      DOB: '',
      phone: '',
      email: '',
      type: {},
      sex: '',
      guardian_first_name: '',
      guardian_last_name: '',
      guardian_relation: '',
      mobileCode: {
        label: '+91 - India',
        value: 'IN',
        currLabel: '+91',
      },
      country: 'India',
    },
    errors: '',
  }

  schema = {
    first_name: Joi.string()
      .required()
      .max(50)
      .label('First Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = firstNameRequired
              break

            case 'string.max':
              err.message = fnameMax
              break
          }
        })
        return errors
      }),
    type: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = typeRequired
              break

            case 'string.max':
              err.message = typeRequired
              break
          }
        })
        return errors
      }),
    phone: Joi.number()
      .required()
      .label('Mobile number')
      .min(1000000000)
      .max(999999999999999)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'number.base':
              err.message = mobileRequired
              break

            case 'number.max':
              err.message = mobileInvalid
              break

            case 'number.min':
              err.message = mobileInvalid
              break
          }
        })
        return errors
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label('Email')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = emailRequired
              break

            case 'string.email':
              err.message = emailInvalid
              break
            case 'string.max':
              err.message = emailInvalid
              break
          }
        })
        return errors
      }),
    last_name: Joi.string()
      .required()
      .max(50)
      .label('Last Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = lastNameRequired
              break

            case 'string.max':
              err.message = lnameMax
              break
          }
        })
        return errors
      }),
    DOB: Joi.date()
      .max(new Date().setFullYear(new Date().getFullYear() - 18))
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'date.base':
              err.message = dobRequired
              break

            case 'date.max':
              err.message = dobInvalid
              break
          }
        })
        return errors
      }),
    mobileCode: Joi.object()
      .required()
      .error(() => {
        return { message: mobileCodeRequired }
      }),
    country: Joi.string(),
    sex: Joi.string()
      .required()
      .max(50)
      .label('Sex')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = sexRequired
              break
          }
        })
        return errors
      }),
    guardian_first_name: Joi.string()
      .allow('')
      .max(50)
      .label('Last Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = lastNameRequired
              break

            case 'string.max':
              err.message = lnameMax
              break
          }
        })
        return errors
      }),

    guardian_last_name: Joi.string()
      .allow('')
      .max(50)
      .label('Last Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = lastNameRequired
              break

            case 'string.max':
              err.message = lnameMax
              break
          }
        })
        return errors
      }),
    guardian_relation: Joi.string()
      .allow('')
      .max(50)
      .label('Last Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = lastNameRequired
              break

            case 'string.max':
              err.message = lnameMax
              break
          }
        })
        return errors
      }),
  }

  doSubmit = () => {
    //this.props.doSubmit(this.state.data);
    this.setState({
      loader: true,
    })

    const payLoad = {
      mobile: this.state.data.phone,
      type: this.state.data.type === 'Self' ? '1' : '2',
    }
    this.props.mobileAvailability(payLoad, this.callBaclMobile)
  }

  callBaclMobile = res => {
    this.setState({
      loader: false,
    })
    if (res.status === 200) {
      const payLoad = {
        email: this.state.data.email,
        type: this.state.data.type === 'Self' ? '1' : '2',
      }
      this.props.emailAvailability(payLoad, this.callBaclEmail)
    } else {
      this.props.addExistingPatientByDoctor(
        {
          mobile: this.state.data.phone,
          email: this.state.data.email,
        },
        this.callBackPatient
      )
    }
  }

  callBackPatient = res => {
    this.setState({
      loader: false,
    })
    if (res.status === 200) {
      toast(<AlertSuccess message='Date saved successfully' />)
      this.props.history.goBack()
      // const params = { mobile: this.state.data.phone }
      // this.props.sendOtp(params, this.otpCallBack)
    } else {
      const { errors } = this.state
      errors.phone = 'A user with this mobile number already exists.'
      this.setState({ errors })
      toast(<AlertError message={res.data.error} />)
    }
  }

  callBaclEmail = res => {
    this.setState({
      loader: false,
    })
    if (res.status === 200) {
      const params = {
        mobile: this.state.data.phone,
        email: this.state.data.email,
      }

      this.props.sendOtp(params, this.otpCallBack)
    } else {
      toast(<AlertError message={res.data.error} />)
      const { errors } = this.state
      errors.email = 'A user with this email number already exists.'
      this.setState({ errors })
    }
  }

  otpCallBack = res => {
    if (res.status === 200) {
      toast(<AlertSuccess message={res.data.otp} />)
      this.props.updateStep(2)
      this.props.updateData({
        ...this.state.data,
        mobileCode: this.state.data.mobileCode.currLabel,
      })
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      minHeight: 60,
      borderColor: '#e0e0e0',
      borderRadius: 8,
      boxShadow: '0 !important',
    }),
  }

  onChange = e => {
    const { data, errors } = this.state
    data[e.name] = e.currentTarget.value
    if (errors[e.currentTarget.name]) errors[e.currentTarget.name] = ''
    this.setState({ data, errors })

    if (e.currentTarget.value === 'Guardian') {
      this.schema.guardian_last_name = Joi.string()
        .required()
        .max(50)
        .label('Last Name')
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'any.empty':
                err.message = lastNameRequired
                break

              case 'string.max':
                err.message = lnameMax
                break
            }
          })
          return errors
        })
      this.schema.guardian_first_name = Joi.string()
        .required()
        .max(50)
        .label('Last Name')
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'any.empty':
                err.message = lastNameRequired
                break

              case 'string.max':
                err.message = lnameMax
                break
            }
          })
          return errors
        })
      this.schema.DOB = Joi.date()
        .required()
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'date.base':
                err.message = dobRequired
                break

              case 'date.max':
                err.message = dobInvalid
                break
            }
          })
          return errors
        })
      this.schema.sex = Joi.string()
        .max(50)
        .label('Sex')
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'any.empty':
                err.message = sexRequired
                break
            }
          })
          return errors
        })
    }

    if (e.currentTarget.value === 'Self') {
      this.schema.guardian_last_name = Joi.string()
        .allow('')
        .max(50)
        .label('Last Name')
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'any.empty':
                err.message = lastNameRequired
                break

              case 'string.max':
                err.message = lnameMax
                break
            }
          })
          return errors
        })
      this.schema.guardian_first_name = Joi.string()
        .allow('')
        .max(50)
        .label('Last Name')
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'any.empty':
                err.message = lastNameRequired
                break

              case 'string.max':
                err.message = lnameMax
                break
            }
          })
          return errors
        })
      this.schema.DOB = Joi.date()
        .max(new Date().setFullYear(new Date().getFullYear() - 18))
        .required()
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'date.base':
                err.message = dobRequired
                break

              case 'date.max':
                err.message = dobInvalid
                break
            }
          })
          return errors
        })
    }
  }

  render() {
    return (
      <div className='dashboard-content-box'>
        <h4>Add New Patient</h4>
        <form onSubmit={this.handleSubmit}>
          <div className='dc-body d-flex flex-wrap align-items-lg-center'>
            <div className='dc-left'>
              <h4 className='d-block d-xl-none'>Personal Details</h4>
              <div className='dc-form-box'>
                <div className='row'>
                  <div className='col-md-6'>
                    <SelectSearch
                      name='type'
                      options={[
                        { value: 'Self', name: 'Self' },
                        { value: 'Guardian', name: 'Guardian' },
                      ]}
                      label='Patient Type'
                      error={this.state.errors.type}
                      value={this.state.data.type}
                      onChange={this.onChange}
                    />
                  </div>

                  {this.state.data.type === 'Guardian' && (
                    <div className='col-md-6'>
                      <SelectSearch
                        name='guardian_relation'
                        options={[
                          { value: 'Father', name: 'Father' },
                          { value: 'Mother', name: 'Mother' },
                          { value: 'Grand Father', name: 'Grand Father' },
                          { value: 'Grand Mother', name: 'Grand Mother' },
                          { value: 'Brother', name: 'Brother' },
                          { value: 'Sister', name: 'Sister' },
                          { value: 'Husband', name: 'Husband' },
                          { value: 'Wife', name: 'Wife' },
                          { value: 'Friend', name: 'Friend' },
                          { value: 'Son', name: 'Son' },
                          { value: 'Daughter', name: 'Daughter' },
                          { value: 'Grandchild', name: 'Grandchild' },
                          { value: 'Others', name: 'Others' },
                        ]}
                        label='Relation'
                        error={this.state.errors.guardian_relation}
                        value={this.state.data.guardian_relation}
                        onChange={this.onChange}
                      />
                    </div>
                  )}
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {this.renderInput('first_name', 'First Name')}
                  </div>
                  <div className='col-md-6'>
                    {this.renderInput('last_name', 'Last Name')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {this.renderDateInput('DOB', 'Date Of Birth')}
                  </div>
                  <div className='col-md-2'>
                    {this.state.data.DOB && (
                      <div className='form-group'>
                        <label htmlFor='age'>Age</label>
                        <div>
                          {moment().diff(this.state.data.DOB, 'years')} Y
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-4'>
                    {this.renderSelect('sex', 'Sex', [
                      { id: 'M', name: 'Male' },
                      { id: 'F', name: 'Female' },
                    ])}
                  </div>
                </div>
                {this.state.data.type === 'Guardian' && (
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'guardian_first_name',
                        'Guardian First Name'
                      )}
                    </div>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'guardian_last_name',
                        'Guardian Last Name'
                      )}
                    </div>
                  </div>
                )}

                <div className='row'>
                  <div className='col-md-6'>
                    <Select
                      styles={this.customStyles}
                      onChange={e => {
                        console.log('e se', e)
                        const data = { ...this.state.data }
                        data.mobileCode = {
                          label: e.label,
                          value: e.value,
                          currLabel: e.currLabel,
                        }
                        data.country = e.name

                        this.setState({ data })
                      }}
                      placeholder='Select Country Code'
                      value={this.state.data.mobileCode}
                      defaultValue={this.state.data.mobileCode}
                      getOptionLabel={x => x.label}
                      getOptionValue={x => x.value}
                      options={Country.getAllCountries().map(ele => ({
                        label:
                          (ele.phonecode.startsWith('+')
                            ? ele.phonecode
                            : '+' + ele.phonecode) +
                          ' - ' +
                          ele.name,
                        value: ele.isoCode,
                        name: ele.name,
                        currLabel: ele.phonecode.startsWith('+')
                          ? ele.phonecode
                          : '+' + ele.phonecode,
                      }))}
                    />
                  </div>
                  <div className='col-md-6'>
                    {this.renderInput('phone', 'Mobile Number', 'text')}
                  </div>
                  <div className='col-md-6'>
                    {this.renderInput('email', 'Email')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='prescription-btn d-flex justify-content-between align-items-center'>
            <a
              class='btn btn-outline no-shadow'
              onClick={() => this.props.history.goBack()}>
              Back
            </a>
            <SubmitBtn
              label='Register'
              loading={this.state.loading}
              btnClass='btn btn-default no-shadow'
            />
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  addExistingPatientByDoctor: (param, callback) =>
    dispatch(addExistingPatientByDoctor(param, callback)),
})
export default withRouter(connect(null, mapDispatchToProps)(PersonalDetails))
