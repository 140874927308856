import Joi from 'joi-browser'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import logo from '../../../include/images/yes-mindy-main-logo-white-color.png'
import AlertSuccess from '../../common/alertSuccess'
import {
  patientSignIn,
  patientAvailability,
  sendOtp,
  patientSignInOtp,
  setSignupData,
} from '../../../store/patient'
import AlertError from '../../common/alertError'
import Form from '../../common/form/form'
import loginImg from './../../../include/images/signup/signup login-1.png'

import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  usernameRequired,
} from '../../common/misc'
import SubmitBtn from '../../common/form/submitBtn'
import { clearAllToken, setToken } from '../../services/localStorageServices'
import { loadSeo, loadTermTemplate } from '../../../store/seo'
import TermTemplatePopup from '../../common/termTemplatePopup'

class SignIn extends Form {
  state = {
    popShow: false,
    btnClass: 'btn btn-lg btn-primary btn-block',
    loading: false,
    multi: false,
    data: {
      username: '',
      password: '',
      type: 1,
    },
    errors: {
      username: '',
      password: '',
      type: 1,
    },
    appointment: false,
    showPassword: false,
    showOtp: false,
    type: 1,
    mobile: null,
    countDown: 59,
    reset: false,
    countDownId: 1,
    otp: {
      a: '',
      b: '',
      c: '',
      d: '',
    },
  }

  constructor(props) {
    super(props)
    this.aRef = React.createRef()
    this.bRef = React.createRef()
    this.cRef = React.createRef()
    this.dRef = React.createRef()
  }

  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow })
  }

  openPopup = (e, key) => {
    e.preventDefault()
    this.props.loadTermTemplate(key)
    this.togglePopup()
  }

  componentDidMount() {
    if (this.props.match.params.app) this.setState({ appointment: true })
    clearAllToken()
    this.props.loadSeo('patient-login')
  }

  componentWillUnmount() {
    clearInterval(this.state.countDownId)
  }

  onChange = e => {
    const { name, value } = e.target
    const otp = this.state.otp
    otp[name] = value
    this.setState({ otp })
    if (name === 'a') this.bRef.current.focus()
    if (name === 'b') this.cRef.current.focus()
    if (name === 'c') this.dRef.current.focus()
  }

  schema = {
    password: Joi.any().when('type', {
      is: this.state.showPassword,
      then: Joi.string()
        .min(8)
        .max(25)
        .required()
        .label('Password')
        .error(errors => {
          errors.forEach(err => {
            switch (err.type) {
              case 'any.empty':
                err.message = passwordRequired
                break

              case 'string.min':
                err.message = passwordInvalid
                break

              case 'string.max':
                err.message = passwordInvalidMax
                break
            }
          })
          return errors
        }),
    }),
    username: Joi.string()
      .required()
      .label('Username')
      .error(() => {
        return { message: usernameRequired }
      }),
    type: Joi.any(),
  }

  doSubmit = () => {
    const { showPassword, showOtp, username } = this.state
    if (showPassword) {
      if (!this.state.loading) {
        this.setState({
          btnClass: 'btn btn-lg btn-primary btn-block disabled',
          loading: true,
        })
        const {
          data: { username, password, type },
        } = this.state
        const payload = {
          username,
          password,
        }
        if (this.state.multi) {
          payload['type'] = type
        }
        this.props.patientSignIn(payload, this.callback)
      }
    } else if (showOtp) {
      const { a, b, c, d } = this.state.otp
      const data = {
        mobile: this.state.email ? this.state.email : this.state.mobile,
        otp: a + b + c + d,
      }
      if (this.state.multi) {
        data['type'] = this.state.data.type
      }
      this.props.patientSignInOtp(data, this.callback)
    } else {
      if (!this.state.loader) {
        if (
          !isNaN(parseInt(this.state.data.username)) &&
          this.state.data.username.length !== 10
        ) {
          this.setState({
            errors: {
              username: 'Invalid mobile number. Do not enter country code',
            },
          })
          return
        }
        this.setState({
          loader: true,
          btnClass: 'btn btn-lg btn-primary btn-block disabled',
        })
        const params = {
          username: this.state.data.username,
        }
        this.props.mobileAvailability(params, this.checkPatientCallback)
      }
    }
  }

  checkPatientCallback = res => {
    if (!res) return
    this.setState({
      loader: false,
      btnClass: 'btn btn-lg btn-primary btn-block',
    })
    if (res.status === 200) {
      if (!res.data.data.length) {
        return toast(<AlertError message={res.data.error} />)
      }
      if (res.data.data.length === 2) {
        this.setState({ type: 3 })
      } else {
        this.setState({ type: res.data.data[0].type })
      }
      const params = {
        mobile: res.data.data[0].mobile,
        email: res.data.data[0].email,
        type: 'resetPassword',
      }
      this.setState({
        mobile: res.data.data[0].mobile,
        email: res.data.data[0].email,
      })
      this.props.sendOtp(params, this.otpCallBack)
    } else {
      let formatDatas = { name: '', value: this.state.data.username }
      if (isNaN(parseInt(this.state.data.username))) {
        formatDatas.name = 'email_id'
      } else {
        formatDatas.name = 'mobile'
      }
      this.props.setSignupData(formatDatas)
      this.props.history.push('/patient/sign-up')
      //return
      //toast(<AlertError message={res.data.error} />)
    }
  }

  otpCallBack = res => {
    if (res.status === 200) {
      toast(<AlertSuccess message={res.data.otp} />)
      this.setState({
        showPassword: false,
        showOtp: true,
      })
      const countDown = setInterval(() => {
        if (this.state.countDown === 1 || this.state.reset)
          clearInterval(countDown)
        this.setState({ countDown: this.state.countDown - 1 })
      }, 1000)
      this.setState({ countDownId: countDown })
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  reSend = e => {
    clearInterval(this.state.countDownId)
    const param = {
      mobile: this.state.mobile,
      email: this.state.email,
      type: 'resetPassword',
    }
    this.props.sendOtp(param, this.resendCallback)
    e.preventDefault()
  }

  resendCallback = res => {
    if (res.status === 200) {
      this.setState({ countDown: 59, reset: true }, () => {
        const countDown = setInterval(() => {
          if (this.state.countDown === 1) clearInterval(countDown)
          this.setState({ countDown: this.state.countDown - 1 })
        }, 1000)

        this.setState({ countDownId: countDown })
      })

      toast(<AlertSuccess message={res.data.otp} />)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  callback = res => {
    this.setState({
      btnClass: 'btn btn-lg btn-primary btn-block',
      loading: false,
    })
    if (res.status === 403 || res.status === 400)
      return toast(<AlertError message={res.data.error} />)
    if (res.status === 409) {
      this.schema.type = Joi.any().required()
      this.setState({
        multi: true,
        data: {
          username: this.state.data.username,
          password: this.state.data.password,
          type: 1,
        },
        errors: { username: '', password: '', type: '' },
      })
      return
    }
    if (res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data
      setToken(xRefreshToken, xAuthToken)
      localStorage.setItem('patient', JSON.stringify(res.data.data))
      if (sessionStorage.getItem('initialurl')) {
        const url = sessionStorage.getItem('initialurl')
        sessionStorage.removeItem('initialurl')
        this.props.history.push(JSON.parse(url))
      } else if (this.state.appointment) {
        if (this.props.match.params.profile) {
          return this.props.history.push({
            pathname: '/dr/' + this.props.match.params.profile + '/appointment',
            appointId: 'yes',
          })
        }
        this.props.history.push('/patient/doctors')
      } else {
        this.props.history.push('/patient/dashboard')
      }
    }
  }

  renderOtpInput = () => {
    return (
      <div className='enter-otp-box'>
        <p>Please enter OTP sent to phone number</p>
        <div className='otp-box'>
          <input
            className='form-control'
            id='codeBox1'
            type='text'
            name='a'
            onChange={this.onChange}
            maxLength='1'
            ref={this.aRef}
          />
          <input
            className='form-control'
            id='codeBox2'
            type='text'
            name='b'
            onChange={this.onChange}
            maxLength='1'
            ref={this.bRef}
          />
          <input
            className='form-control'
            id='codeBox3'
            type='text'
            name='c'
            onChange={this.onChange}
            maxLength='1'
            ref={this.cRef}
          />
          <input
            className='form-control'
            id='codeBox4'
            type='text'
            name='d'
            onChange={this.onChange}
            maxLength='1'
            ref={this.dRef}
          />
        </div>
        <div className='time-count'>
          Code expires in:{' '}
          <span>
            00:
            {parseInt(
              this.state.countDown > 9
                ? this.state.countDown
                : '0' + this.state.countDown
            )}
          </span>
        </div>
      </div>
    )
  }

  handlePassword = () => {
    if (this.state.showPassword) {
      this.setState(
        {
          showPassword: false,
        },
        () => {
          this.doSubmit()
        }
      )
    } else {
      this.setState({
        showPassword: true,
        showOtp: false,
      })
    }
  }

  render() {
    const { showPassword, showOtp } = this.state

    return (
      <div className='member-container'>
        <div className='top-logo'>
          <a href='/'>
            {' '}
            <img src={logo} alt='' />
          </a>
        </div>
        <div className='member-bg hero-background1'></div>
        <div className='member-box d-flex login-form'>
          <div className='member-left'>
            <div className='ml-box'>
              <h1>YesMindy.</h1>
              <p className='mb-0'>Please enter your password to continue</p>
            </div>
          </div>

          <div className='member-right'>
            <div className='member-right-content d-md-flex align-items-center'>
              <div className='mr-box'>
                <div className='mr-head'>
                  <h2>Login</h2>
                  {!this.state.appointment && (
                    <p className='login-text'>
                      New user? <Link to='sign-up'>Sign Up</Link>
                    </p>
                  )}
                </div>
                <form action='' onSubmit={this.handleSubmit}>
                  <div className='form-box'>
                    {this.renderInput('username', 'Mobile / Email')}
                    <div className='mr-head'>
                      <p
                        className='login-text'
                        style={{ marginTop: 0, marginBottom: 10 }}>
                        Outside indian clients please enter email
                      </p>
                    </div>
                    {showPassword &&
                      this.renderPasswordInput('password', 'Password')}
                    {showOtp && this.renderOtpInput()}

                    {this.state.multi &&
                      this.renderSelect(
                        'type',
                        'Account Type',
                        [
                          { id: 1, name: 'Self' },
                          { id: 2, name: 'Caretaker/Gardian' },
                        ],
                        1
                      )}

                    {showOtp && (
                      <div className='resend-box pb-2'>
                        <p>
                          Didn't get the code?{' '}
                          <a href='#' onClick={this.reSend}>
                            Resend
                          </a>
                        </p>
                      </div>
                    )}

                    <div className='form-group' style={{ marginBottom: 0 }}>
                      <SubmitBtn
                        label={
                          showPassword || showOtp ? 'Login' : 'Login With Otp'
                        }
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>

                    <div className='resend-box'>
                      <p>or</p>
                    </div>

                    <div className='form-group'>
                      <button
                        type='button'
                        onClick={this.handlePassword}
                        className={this.state.btnClass}>
                        Login With {showPassword ? 'OTP' : 'Password'}
                      </button>
                    </div>

                    {/* {!this.state.appointment && ( */}
                    <p className='forgot-password text-center'>
                      <Link to='/patient/forget-password'>
                        Forgot/Set Password
                      </Link>
                    </p>
                    {/* )} */}
                    <div class='seprator'>or</div>
                    <div class='login-as-text-box text-center'>
                      <h5>
                        <Link to='/doctor/sign-in'>
                          Login as a Health Professional
                        </Link>
                      </h5>
                    </div>
                  </div>
                </form>
              </div>

              <div class='mr-bottom'>
                <p>
                  By clicking login, I agree to{' '}
                  <a href='' onClick={e => this.openPopup(e, 'PatientTerms')}>
                    terms of service
                  </a>{' '}
                  and{' '}
                  <a
                    onClick={e => this.openPopup(e, 'user-privacy-policy')}
                    href=''>
                    privacy policy.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <TermTemplatePopup
          show={this.state.popShow}
          toggle={this.togglePopup}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  patientSignIn: (param, callback) => dispatch(patientSignIn(param, callback)),
  patientSignInOtp: (param, callback) =>
    dispatch(patientSignInOtp(param, callback)),
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadTermTemplate: key => dispatch(loadTermTemplate(key)),
  mobileAvailability: (param, callback) =>
    dispatch(patientAvailability(param, callback)),
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  setSignupData: param => dispatch(setSignupData(param)),
})

export default withRouter(connect(null, mapDispatchToProps)(SignIn))
