import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'

const slice = createSlice({
  name: 'prescription',
  initialState: {
    list: [],
    forPatient: [],
    formula: [],
    radiology: [],
    medicinalPlan: [],
    techniques: [],
    conduct: [],
    labinvestigation: [],
    formulaAdd: {},
    radiologyAdd: {},
    labinvestigationAdd: {},
    prescriptionAdd: {},
    compositionAdd: {},
    frequencyAdd: {},
    brandAdd: {},
    doseAdd: {},
    composition: [],
    frequency: [],
    brand: [],
    dose: [],
    day: [],
    forAppointment: {},
    loading: false,
    lastFetch: null,
    doctorChatListLoading: false,
    doctorChatList: [],
  },
  reducers: {
    formulaRequested: (prescription, action) => {
      prescription.loading = true
    },
    formulaRecieved: (prescription, action) => {
      prescription.formula = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    formulaRequestFailed: (prescription, action) => {
      prescription.formula = []
      prescription.loading = false
    },
    radiologyRequested: (prescription, action) => {
      prescription.loading = true
    },
    radiologyRecieved: (prescription, action) => {
      prescription.radiology = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    radiologyRequestFailed: (prescription, action) => {
      prescription.radiology = []
      prescription.loading = false
    },
    medicinalPlanRequested: (prescription, action) => {
      prescription.loading = true
    },
    medicinalPlanRecieved: (prescription, action) => {
      prescription.medicinalPlan = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    medicinalPlanRequestFailed: (prescription, action) => {
      prescription.medicinalPlan = []
      prescription.loading = false
    },
    techniquesRequested: (prescription, action) => {
      prescription.loading = true
    },
    techniquesRecieved: (prescription, action) => {
      prescription.techniques = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    techniquesRequestFailed: (prescription, action) => {
      prescription.techniques = []
      prescription.loading = false
    },
    conductRequested: (prescription, action) => {
      prescription.loading = true
    },
    conductRecieved: (prescription, action) => {
      prescription.conduct = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    conductRequestFailed: (prescription, action) => {
      prescription.conduct = []
      prescription.loading = false
    },
    labinvestigationRequested: (prescription, action) => {
      prescription.loading = true
    },
    labinvestigationRecieved: (prescription, action) => {
      prescription.labinvestigation = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    labinvestigationRequestFailed: (prescription, action) => {
      prescription.labinvestigation = []
      prescription.loading = false
    },
    formulaAdded: (prescription, action) => {
      prescription.formulaAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    formulaAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },
    radiologyAdded: (prescription, action) => {
      prescription.radiologyAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    radiologyAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },
    labinvestigationAdded: (prescription, action) => {
      prescription.labinvestigationAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    labinvestigationAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },

    doseAdded: (prescription, action) => {
      prescription.doseAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    doseAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },
    compositionAdded: (prescription, action) => {
      prescription.compositionAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    compositionAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },

    frequencyAdded: (prescription, action) => {
      prescription.frequencyAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    frequencyAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },

    brandAdded: (prescription, action) => {
      prescription.brandAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    brandAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },
    prescriptionAdded: (prescription, action) => {
      prescription.prescriptionAdd = action.payload
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },

    prescriptionAddRequestFailed: (prescription, action) => {
      prescription.loading = false
    },
    listRequested: (prescription, action) => {
      prescription.loading = true
    },
    listRecieved: (prescription, action) => {
      prescription.list = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    listRequestFailed: (prescription, action) => {
      prescription.list = []
      prescription.loading = false
    },
    listChatRequestedDoctor: (prescription, action) => {
      prescription.doctorChatListLoading = true
    },

    listChatReceivedDoctor: (prescription, action) => {
      prescription.doctorChatList = action.payload.data
      prescription.doctorChatListLoading = false
    },

    listChatRequestFailedDoctor: (prescription, action) => {
      prescription.doctorChatListLoading = false
    },
    forPatientRequested: (prescription, action) => {
      prescription.loading = true
    },
    forPatientRecieved: (prescription, action) => {
      prescription.forPatient = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    forPatientRequestFailed: (prescription, action) => {
      prescription.forPatient = []
      prescription.loading = false
    },
    forAppointmentRequested: (prescription, action) => {
      prescription.loading = true
    },
    forAppointmentRecieved: (prescription, action) => {
      prescription.forAppointment = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    forAppointmentRequestFailed: (prescription, action) => {
      prescription.forAppointment = {}
      prescription.loading = false
    },
    dayRequested: (prescription, action) => {
      prescription.loading = true
    },
    dayRecieved: (prescription, action) => {
      prescription.day = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    dayRequestFailed: (prescription, action) => {
      prescription.day = []
      prescription.loading = false
    },
    doseRequested: (prescription, action) => {
      prescription.loading = true
    },
    doseRecieved: (prescription, action) => {
      prescription.dose = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    doseRequestFailed: (prescription, action) => {
      prescription.dose = []
      prescription.loading = false
    },
    brandRequested: (prescription, action) => {
      prescription.loading = true
    },
    brandRecieved: (prescription, action) => {
      prescription.brand = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    brandRequestFailed: (prescription, action) => {
      prescription.brand = []
      prescription.loading = false
    },

    compositionRequested: (prescription, action) => {
      prescription.loading = true
    },
    compositionRecieved: (prescription, action) => {
      prescription.composition = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    compositionRequestFailed: (prescription, action) => {
      prescription.composition = []
      prescription.loading = false
    },

    frequencyRequested: (prescription, action) => {
      prescription.loading = true
    },
    frequencyRecieved: (prescription, action) => {
      prescription.frequency = action.payload.data
      prescription.loading = false
      prescription.lastFetch = Date.now()
    },
    frequencyRequestFailed: (prescription, action) => {
      prescription.frequency = []
      prescription.loading = false
    },
  },
})

export const {
  listRecieved,
  listRequestFailed,
  listRequested,
  forPatientRecieved,
  forPatientRequestFailed,
  forPatientRequested,
  forAppointmentRecieved,
  forAppointmentRequestFailed,
  forAppointmentRequested,
  formulaRequested,
  formulaRecieved,
  formulaRequestFailed,
  radiologyRecieved,
  radiologyRequestFailed,
  radiologyRequested,
  dayRecieved,
  dayRequested,
  dayRequestFailed,
  compositionRecieved,
  compositionRequestFailed,
  compositionRequested,
  compositionAdded,
  compositionAddRequestFailed,
  frequencyRecieved,
  frequencyRequestFailed,
  frequencyRequested,
  frequencyAdded,
  frequencyAddRequestFailed,
  doseAdded,
  doseAddRequestFailed,
  brandRecieved,
  brandRequestFailed,
  brandRequested,
  brandAdded,
  brandAddRequestFailed,
  doseRequested,
  doseRequestFailed,
  doseRecieved,
  formulaAdded,
  formulaAddRequestFailed,
  radiologyAdded,
  radiologyAddRequestFailed,
  labinvestigationAddRequestFailed,
  labinvestigationAdded,
  labinvestigationRecieved,
  labinvestigationRequestFailed,
  labinvestigationRequested,
  prescriptionAdded,
  prescriptionAddRequestFailed,
  conductRecieved,
  conductRequestFailed,
  conductRequested,
  techniquesRecieved,
  techniquesRequestFailed,
  techniquesRequested,
  medicinalPlanRecieved,
  medicinalPlanRequestFailed,
  medicinalPlanRequested,
  listChatRequestedDoctor,
  listChatReceivedDoctor,
  listChatRequestFailedDoctor,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'prescription/list/forDoctor'
const urlChat = 'prescription/list/forChatDoctor'
const url1 = 'composition'
const url2 = 'brand'
const url3 = 'dose'
const url4 = 'day'
const url5 = 'formula'
const url6 = 'prescription/bypatient_id/'
const url7 = 'radiology'
const url8 = 'labinvestigation'
const url9 = 'prescription/add/'
const url10 = 'prescription/appointment_id/'
const url11 = 'prescription/medicinalPlan'
const url12 = 'prescription/techniques'
const url13 = 'prescription/conduct'
const url14 = 'frequency'
export const loadList = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: listRequested.type,
      onSuccess: listRecieved.type,
      onError: listRequestFailed.type,
    })
  )
}
export const loadChatList = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlChat,
      params,
      onStart: listChatRequestedDoctor.type,
      onSuccess: listChatReceivedDoctor.type,
      onError: listChatRequestFailedDoctor.type,
    })
  )
}
export const loadTechniques = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url12,
      onStart: techniquesRequested.type,
      onSuccess: techniquesRecieved.type,
      onError: techniquesRequestFailed.type,
    })
  )
}
export const loadConducts = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url13,
      onStart: conductRequested.type,
      onSuccess: conductRecieved.type,
      onError: conductRequestFailed.type,
    })
  )
}
export const loadmedicinalPlan = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url11,
      onStart: medicinalPlanRequested.type,
      onSuccess: medicinalPlanRecieved.type,
      onError: medicinalPlanRequestFailed.type,
    })
  )
}
export const loadForPatient = id => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url6 + id,
      onStart: forPatientRequested.type,
      onSuccess: forPatientRecieved.type,
      onError: forPatientRequestFailed.type,
    })
  )
}
export const loadForAppointment =
  (id, callback = null) =>
  (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        callback,
        url: url10 + id,
        onStart: forAppointmentRequested.type,
        onSuccess: forAppointmentRecieved.type,
        onError: forAppointmentRequestFailed.type,
      })
    )
  }

export const loadFormula = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url5,
      onStart: formulaRequested.type,
      onSuccess: formulaRecieved.type,
      onError: formulaRequestFailed.type,
    })
  )
}
export const loadRadiology = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url7,
      onStart: radiologyRequested.type,
      onSuccess: radiologyRecieved.type,
      onError: radiologyRequestFailed.type,
    })
  )
}
export const addRadiology = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url7,
      method: 'post',
      data: param,
      onSuccess: radiologyAdded.type,
      onError: radiologyAddRequestFailed.type,
    })
  )
}
export const loadLabinvestigation = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url8,
      onStart: labinvestigationRequested.type,
      onSuccess: labinvestigationRecieved.type,
      onError: labinvestigationRequestFailed.type,
    })
  )
}
export const addLabinvestigation = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url8,
      method: 'post',
      data: param,
      onSuccess: labinvestigationAdded.type,
      onError: labinvestigationAddRequestFailed.type,
    })
  )
}
export const addPrescription = (param, id, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url9 + id,
      method: 'post',
      data: param,
      onSuccess: prescriptionAdded.type,
      onError: prescriptionAddRequestFailed.type,
    })
  )
}
export const addFormula = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url5,
      method: 'post',
      data: param,
      onSuccess: formulaAdded.type,
      onError: formulaAddRequestFailed.type,
    })
  )
}
export const addComposition = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url1,
      method: 'post',
      data: param,
      onSuccess: compositionAdded.type,
      onError: compositionAddRequestFailed.type,
    })
  )
}
export const addFrequency = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url14,
      method: 'post',
      data: param,
      onSuccess: frequencyAdded.type,
      onError: frequencyAddRequestFailed.type,
    })
  )
}
export const addBrand = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url2,
      method: 'post',
      data: param,
      onSuccess: brandAdded.type,
      onError: brandAddRequestFailed.type,
    })
  )
}
export const addDose = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url3,
      method: 'post',
      data: param,
      onSuccess: doseAdded.type,
      onError: doseAddRequestFailed.type,
    })
  )
}
export const loadDose =
  (params, callback = null) =>
  (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        callback,
        url: url3,
        params,
        onStart: doseRequested.type,
        onSuccess: doseRecieved.type,
        onError: doseRequestFailed.type,
      })
    )
  }
export const loadDay = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url4,
      onStart: dayRequested.type,
      onSuccess: dayRecieved.type,
      onError: dayRequestFailed.type,
    })
  )
}

export const loadComposition = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1,
      onStart: compositionRequested.type,
      onSuccess: compositionRecieved.type,
      onError: compositionRequestFailed.type,
    })
  )
}
export const loadFrequency = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url14,
      onStart: frequencyRequested.type,
      onSuccess: frequencyRecieved.type,
      onError: frequencyRequestFailed.type,
    })
  )
}
export const loadBrand =
  (params, callback = null) =>
  (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        callback,
        params,
        url: url2,
        onStart: brandRequested.type,
        onSuccess: brandRecieved.type,
        onError: brandRequestFailed.type,
      })
    )
  }
export const getPrescription = createSelector(
  state => state.entities.prescription,
  prescription => prescription
)
