import React, { Component } from 'react'
import {
  getAssessment,
  loadDetailAssessment,
  loadAnswerAssessment,
  addAnswerAssessment,
  loadAllAssessment,
} from '../../../../../store/assessment'
import close from '../../../../../include/images/icon-close.svg'
import { connect } from 'react-redux'
import { getPatient } from '../../../../../store/patient'
import AlertSuccess from '../../../../common/alertSuccess'
import AlertError from '../../../../common/alertError'
import { toast } from 'react-toastify'
import moment from 'moment'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import notesIcon from './../../../../../include/images/notes-icon.svg'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  loadAppointmentDetailPatient,
  loadAllAppointment,
  getAppointment,
} from '../../../../../store/appointment'
import PatientHistory from '../../../profile/patientHistory'

class Notes extends Component {
  state = {
    id: '',
    isShow1: false,
    title: '',
    data: {},
    is_submitted: false,
    appointId: '',
    followup: true,
    historyForm: false,
  }
  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },
      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      'height': 55,
      'minHeight': 55,
      'borderColor': state.isFocused ? '#6119c0' : '#e0e0e0',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }
  toggle1 = (id, title, appointId, historyFormVal = false) => {
    this.setState({
      isShow1: !this.state.isShow1,
      title: title,
      id: id,
      appointId,
      historyForm: historyFormVal,
    })
  }

  toggleClose = () => {
    this.setState({
      isShow1: false,
      is_submitted: false,
      data: '',
      id: '',
      title: '',
      historyForm: false,
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.id != '' && this.state.id !== prevState.id) {
      this.props.loadDetailAssessment(this.state.id)
      this.props.loadAnswerAssessment({
        doctor_id:
          (this.props.psychiatrist && this.props.psychiatrist._id) ||
          (this.props.psychologist && this.props.psychologist._id),
        appointment_id: this.state.appointId,
        assesment_id: this.state.id,
      })
    }
    if (
      this.props.answer.data !== null &&
      this.props.answer.data !== prevProps.answer.data
    ) {
      this.setState({
        is_submitted:
          this.props.answer.data && this.props.answer.data.is_submitted,
        data: this.props.answer.data && this.props.answer.data.formData,
      })
    }
    if (
      this.props.aapointment_id &&
      this.props.aapointment_id != prevProps.aapointment_id
    ) {
      this.props.loadAppointmentDetailPatient(this.props.aapointment_id, res =>
        this.setState({ followup: res.data.data.followup }, () => {
          this.props.loadAllAssessment({
            patient_id: this.props.getPatientProfile._id,
            doctor_id: this.props.doctor_id,
            appointment_id: this.props.aapointment_id,
            category: this.state.followup
              ? [
                  this.props.getPatientProfile.type === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Progress Notes - Guardian'
                    : 'Progress Notes - Self',
                ]
              : [
                  this.props.getPatientProfile.type === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                ],
          })
        })
      )
    }
    if (this.props.tab == 'notes' && this.props.tab != prevProps.tab) {
      this.props.loadAllAssessment({
        patient_id: this.props.getPatientProfile._id,
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.aapointment_id,
        category: this.state.followup
          ? [
              this.props.getPatientProfile.type === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Progress Notes - Guardian'
                : 'Progress Notes - Self',
            ]
          : [
              this.props.getPatientProfile.type === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
            ],
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const formData = { ...this.state.data }
    var payLoad
    if (this.state.is_submitted === true) {
      payLoad = {
        doctor_id: this.props.doctor_id,
        appointment_id: this.state.appointId,
        assesment_id: this.state.id,
        is_submitted: this.state.is_submitted,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    } else {
      payLoad = {
        doctor_id: this.props.doctor_id,
        appointment_id: this.state.appointId,
        assesment_id: this.state.id,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    }
  }
  callBack = res => {
    if (res && res.status === 200) {
      this.props.loadAnswerAssessment({
        doctor_id: this.props.doctor_id,
        appointment_id: this.state.appointId,
        assesment_id: this.state.id,
      })
      this.props.loadAllAssessment({
        patient_id: this.props.getPatientProfile._id,
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.aapointment_id,
        category: this.state.followup
          ? [
              this.props.getPatientProfile.type === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Progress Notes - Guardian'
                : 'Progress Notes - Self',
            ]
          : [
              this.props.getPatientProfile.type === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
            ],
      })
      toast(<AlertSuccess message='Information Saved.' />)
      this.setState({
        isShow1: '',
        data: {},
      })
    } else {
      toast(<AlertError message='Something Went Wrong.' />)
      this.setState({
        isShow1: '',
        data: {},
      })
    }
  }
  componentDidMount = () => {
    this.props.loadAllAppointment({
      page: 1,
    })
    if (this.props.aapointment_id) {
      this.props.loadAppointmentDetailPatient(this.props.aapointment_id, res =>
        this.setState({ followup: res.data.data.followup }, () => {
          this.props.loadAllAssessment({
            patient_id: this.props.getPatientProfile._id,
            doctor_id: this.props.doctor_id,
            appointment_id: this.props.aapointment_id,
            category: this.state.followup
              ? [
                  this.props.getPatientProfile.type === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Progress Notes - Guardian'
                    : 'Progress Notes - Self',
                ]
              : [
                  this.props.getPatientProfile.type === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                ],
          })
        })
      )
    }
  }

  render() {
    const { detail, assessment, appointment, getPatientHistory } = this.props
    const { data: appointmentData = [] } = appointment
    const filterAppointmentData = appointmentData.filter(
      ele => ele?.doctor_id?._id == this.props.doctor_id
    )

    return (
      <>
        <div
          onClick={e => this.props.setTab('notes', e)}
          className={
            this.props.tab === 'notes' ? 'card-header active' : 'card-header'
          }>
          <img src={notesIcon} alt='' />
          <b>Notes & Thoughts</b>
        </div>
        <div
          className={
            this.props.tab === 'notes' ? 'content-body show' : 'content-body'
          }>
          <div className='notes-pane-content'>
            {assessment.length
              ? [
                  assessment.find(
                    eds =>
                      eds.title.trim() === 'Pre-session Thoughts' ||
                      eds.title.trim() === 'Pre-session Thoughts For Guardian'
                  ) || [],
                  assessment.find(
                    eds =>
                      (eds.title && eds.title.trim() === 'Progress Notes') ||
                      (eds.title &&
                        eds.title.trim() === 'Progress Notes For Guardian')
                  ) || [],
                  assessment.find(
                    eds =>
                      eds.title.trim() === 'Post session Thoughts' ||
                      eds.title.trim() === 'Post session Thoughts For Guardian'
                  ) || [],
                ].map((e, i) => (
                  <>
                    {i === 0 && (
                      <div class='d-flex align-items-center' key={i}>
                        <ReactTooltip className='testPurple' html={true} />
                        <p className='mb-0 mt-4 mb-4'>Pre-Appointment Tasks</p>
                        <div>
                          {' '}
                          <a
                            data-tip='To be filled before the appointment starts for better consultation experience.'
                            style={{ cursor: 'pointer' }}>
                            <i
                              class='fas fa-info-circle ml-2'
                              aria-hidden='true'></i>
                          </a>
                        </div>
                      </div>
                    )}
                    {i === 2 && (
                      <div class='d-flex align-items-center' key={i}>
                        <ReactTooltip className='testPurple' html={true} />
                        <p className='mb-0 mt-4 mb-4'>Post-Appointment Tasks</p>
                        <div>
                          <a
                            data-tip='To be filled after the appointment. Help experts know about your Experience.'
                            style={{ cursor: 'pointer' }}>
                            <i
                              class='fas fa-info-circle ml-2'
                              aria-hidden='true'></i>
                          </a>
                        </div>
                      </div>
                    )}
                    {(e.title &&
                      e.title.trim() ===
                        'Post session Thoughts For Guardian') ||
                    (e.title && e.title.trim() === 'Post session Thoughts') ? (
                      <div className='notes-box d-flex align-items-center'>
                        <p>{e.title && e.title.trim()}</p>
                        {e.assesmentAnswer &&
                        e.assesmentAnswer.is_submitted === true ? (
                          <div
                            className={
                              moment().diff(
                                moment(e.appointment_time).subtract({
                                  hours: 5,
                                  minutes: 30,
                                }),
                                'minutes'
                              ) > 25
                                ? 'fillup-btn'
                                : 'fillup-btn fillup-btn-didable'
                            }
                            onClick={() => {
                              if (
                                moment().diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) > 25
                              )
                                this.toggle1(e._id, e.title, e.appointment_id)
                            }}>
                            View
                          </div>
                        ) : (
                          <div
                            className={
                              moment().diff(
                                moment(e.appointment_time).subtract({
                                  hours: 5,
                                  minutes: 30,
                                }),
                                'minutes'
                              ) > 25
                                ? 'fillup-btn'
                                : 'fillup-btn fillup-btn-didable'
                            }
                            onClick={() => {
                              if (
                                moment().diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) > 25
                              )
                                this.toggle1(e._id, e.title, e.appointment_id)
                            }}>
                            Fill Up
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {e.title && e.title.trim() && (
                          <div className='notes-box d-flex align-items-center'>
                            <p>{e.title}</p>
                            {e.assesmentAnswer &&
                            e.assesmentAnswer.is_submitted === true ? (
                              <div
                                className='fillup-btn'
                                onClick={() =>
                                  this.toggle1(e._id, e.title, e.appointment_id)
                                }>
                                View
                              </div>
                            ) : (
                              <div
                                className={'fillup-btn'}
                                onClick={() => {
                                  this.toggle1(e._id, e.title, e.appointment_id)
                                }}>
                                Fill Up
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {i === 0 &&
                      filterAppointmentData &&
                      filterAppointmentData.length &&
                      filterAppointmentData.length === 1 && (
                        <>
                          <div className='notes-box d-flex align-items-center'>
                            <p>Fill history form</p>
                            <div
                              className={'fillup-btn'}
                              onClick={() => {
                                this.toggle1('', 'History', '', true)
                              }}>
                              {getPatientHistory ? 'View' : 'Fill Up'}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                ))
              : ''}
          </div>
        </div>
        <div
          className={
            this.state.isShow1 === true
              ? 'sidebar-box preSession opennote'
              : 'sidebar-box preSession closenote'
          }
          id='pre'>
          <div className='sidebar-head d-flex align-items-center'>
            {this.state.isShow1 === true && (
              <h5>
                Fill {this.state.title}
                {!this.state.historyForm &&
                  detail &&
                  detail.data &&
                  detail.data.header_text &&
                  ' - ' + detail.data.header_text}
              </h5>
            )}
            {this.state.isShow1 === true ? (
              <div className='close-img ml-auto' onClick={this.toggleClose}>
                <img src={close} alt='' />
              </div>
            ) : (
              ''
            )}
          </div>
          <div div className='sidebar-body'>
            {this.state.historyForm ? (
              <PatientHistory
                drawer={true}
                toggleDrawer={this.toggleClose}
                readable={getPatientHistory ? true : false}
              />
            ) : (
              <form action='' onSubmit={this.handleSubmit}>
                <>
                  {this.props.answer.data &&
                  this.props.answer.data.is_submitted === true
                    ? detail.questions &&
                      detail.questions.map((e, i) => (
                        <>
                          {e.questions.map((e1, i1) => {
                            const showValue =
                              Array.isArray(this.state.data[e1.question]) &&
                              this.state.data[e1.question].length > 0 &&
                              typeof this.state.data[e1.question][0] ===
                                'object'
                                ? this.state.data[e1.question]
                                    .map(u => u.label)
                                    .join(',')
                                : Array.isArray(this.state.data[e1.question])
                                ? this.state.data[e1.question].join(',')
                                : typeof this.state.data[e1.question] ===
                                  'object'
                                ? this.state.data[e1.question].label
                                : this.state.data[e1.question]
                            return (
                              <div className='check-box-group' key={i1}>
                                <div class='scb-right pb-3'>
                                  <p>{e1.question}</p>
                                  <h6>{this.state.data && showValue}</h6>
                                </div>
                                {/* <label>{e1.question}</label> */}

                                {/* {e1.response_type === 'free_text_pill_box' && (
                                  <CreatableSelect
                                    noOptionsMessage={() =>
                                      'Type and press enter to add options'
                                    }
                                    isMulti
                                    classNamePrefix='select'
                                    isDisabled={false}
                                    isLoading={false}
                                    name='color'
                                    styles={this.customStyles}
                                    value={
                                      this.state.data &&
                                      this.state.data[e1.question]
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      data[e1.question] = e
                                      this.setState({ data })
                                    }}
                                  />
                                )}
                                {e1.response_type ===
                                  'multiselect_dropdown' && (
                                  <Select
                                    isMulti
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    name='color'
                                    styles={this.customStyles}
                                    options={e1.options.map(ex => ({
                                      label: ex.title,
                                      value: ex.title,
                                    }))}
                                    value={
                                      this.state.data &&
                                      this.state.data[e1.question]
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      data[e1.question] = e
                                      this.setState({ data })
                                    }}
                                  />
                                )}
                                {e1.response_type === 'dropdown' && (
                                  <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    name='color'
                                    styles={this.customStyles}
                                    options={e1.options.map(ex => ({
                                      label: ex.title,
                                      value: ex.title,
                                    }))}
                                    value={
                                      this.state.data &&
                                      this.state.data[e1.question]
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      data[e1.question] = e
                                      this.setState({ data })
                                    }}
                                  />
                                )}

                                {e1.response_type === 'text' && (
                                  <input
                                    type='text'
                                    name=''
                                    className='form-control'
                                    value={
                                      this.state.data &&
                                      this.state.data[e1.question]
                                    }
                                    placeholder='Write here…'
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      data[e1.question] = e.target.value
                                      this.setState({ data })
                                    }}
                                  />
                                )}
                                {e1.response_type === 'text_area' && (
                                  <textarea
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    value={
                                      this.state.data &&
                                      this.state.data[e1.question]
                                    }
                                    onChange={e => {
                                      const data = { ...this.state.data }
                                      data[e1.question] = e.target.value
                                      this.setState({ data })
                                    }}></textarea>
                                )}

                                <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                  {e1.response_type === 'checkbox' &&
                                    e1.options.map((e2, i2) => (
                                      <div className='form-check' key={i2}>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          value={e2.title}
                                          checked={
                                            this.state.data &&
                                            this.state.data[e1.question] &&
                                            this.state.data[e1.question].find(
                                              echeck => echeck === e2.title
                                            )
                                          }
                                          onChange={event => {
                                            const data = { ...this.state.data }
                                            if (event) {
                                              const findData =
                                                data[e1.question] &&
                                                data[e1.question].find(
                                                  echeck =>
                                                    echeck ===
                                                    event.target.value
                                                )

                                              if (findData) {
                                                data[e1.question] = data[
                                                  e1.question
                                                ].filter(
                                                  eqs =>
                                                    eqs !== event.target.value
                                                )
                                              } else {
                                                if (data[e1.question]) {
                                                  data[e1.question].push(
                                                    event.target.value
                                                  )
                                                } else {
                                                  data[e1.question] = [
                                                    event.target.value,
                                                  ]
                                                }
                                              }

                                              this.setState({ data })
                                            }
                                          }}
                                          name={e2.title + e1._id}
                                          id={e2.title}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor={e2.title}>
                                          <span></span>
                                          {e2.title}
                                        </label>
                                      </div>
                                    ))}
                                </div>
                                <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                  {e1.response_type === 'radio' &&
                                    e1.options.map((e2, i2) => (
                                      <div className='form-check' key={i2}>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          onChange={e => {
                                            const data = { ...this.state.data }
                                            data[e1.question] = e.target.value
                                            this.setState({ data })
                                          }}
                                          checked={
                                            this.state.data &&
                                            this.state.data[e1.question] ===
                                              e2.title
                                          }
                                          value={e2.title}
                                          id={e2.title + e1._id}
                                          name={e2.title + e1._id}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor={e2.title + e1._id}>
                                          <span
                                            style={{
                                              marginLeft: '2.75rem',
                                            }}></span>
                                          {e2.title}
                                        </label>
                                      </div>
                                    ))}
                                </div> */}
                              </div>
                            )
                          })}
                        </>
                      ))
                    : detail.questions &&
                      detail.questions.map((e, i) => (
                        <>
                          {e.questions.map((e1, i1) => (
                            <div className='check-box-group' key={i1}>
                              <label>{e1.question}</label>
                              {e1.response_type === 'free_text_pill_box' && (
                                <CreatableSelect
                                  noOptionsMessage={() =>
                                    'Type and press enter to add options'
                                  }
                                  isMulti
                                  classNamePrefix='select'
                                  isDisabled={false}
                                  isLoading={false}
                                  name='color'
                                  styles={this.customStyles}
                                  value={
                                    this.state.data &&
                                    this.state.data[e1.question]
                                  }
                                  onChange={e => {
                                    const data = { ...this.state.data }
                                    data[e1.question] = e
                                    this.setState({ data })
                                  }}
                                />
                              )}
                              {e1.response_type === 'multiselect_dropdown' && (
                                <Select
                                  isMulti
                                  className='basic-single'
                                  classNamePrefix='select'
                                  isDisabled={false}
                                  isLoading={false}
                                  isClearable={false}
                                  isRtl={false}
                                  isSearchable={false}
                                  name='color'
                                  styles={this.customStyles}
                                  options={e1.options.map(ex => ({
                                    label: ex.title,
                                    value: ex.title,
                                  }))}
                                  value={
                                    this.state.data &&
                                    this.state.data[e1.question]
                                  }
                                  onChange={e => {
                                    const data = { ...this.state.data }
                                    data[e1.question] = e
                                    this.setState({ data })
                                    console.log(e)
                                  }}
                                />
                              )}
                              {e1.response_type === 'dropdown' && (
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  isDisabled={false}
                                  isLoading={false}
                                  isClearable={false}
                                  isRtl={false}
                                  isSearchable={false}
                                  name='color'
                                  styles={this.customStyles}
                                  options={e1.options.map(ex => ({
                                    label: ex.title,
                                    value: ex.title,
                                  }))}
                                  value={
                                    this.state.data &&
                                    this.state.data[e1.question]
                                  }
                                  onChange={e => {
                                    const data = { ...this.state.data }
                                    data[e1.question] = e
                                    this.setState({ data })
                                    console.log(e)
                                  }}
                                />
                              )}
                              {e1.response_type === 'text' && (
                                <input
                                  type='text'
                                  name=''
                                  onChange={e => {
                                    const data = { ...this.state.data }
                                    if (e) {
                                      data[e1.question] = e.target.value
                                      this.setState({ data })
                                    }
                                  }}
                                  className='form-control'
                                  value={
                                    this.state.data &&
                                    this.state.data[e1.question]
                                  }
                                  placeholder='Write here…'
                                />
                              )}
                              {e1.response_type === 'text_area' && (
                                <textarea
                                  type='text'
                                  className='form-control'
                                  placeholder=''
                                  value={
                                    this.state.data &&
                                    this.state.data[e1.question]
                                  }
                                  onChange={e => {
                                    const data = { ...this.state.data }
                                    data[e1.question] = e.target.value
                                    this.setState({ data })
                                  }}></textarea>
                              )}

                              <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                {e1.response_type === 'checkbox' &&
                                  e1.options.map((e2, i2) => (
                                    <div
                                      className='form-check'
                                      key={i2}
                                      onChange={event => {
                                        const data = { ...this.state.data }
                                        if (event) {
                                          const findData =
                                            data[e1.question] &&
                                            data[e1.question].find(
                                              echeck =>
                                                echeck === event.target.value
                                            )

                                          if (findData) {
                                            data[e1.question] = data[
                                              e1.question
                                            ].filter(
                                              eqs => eqs !== event.target.value
                                            )
                                          } else {
                                            if (data[e1.question]) {
                                              data[e1.question].push(
                                                event.target.value
                                              )
                                            } else {
                                              data[e1.question] = [
                                                event.target.value,
                                              ]
                                            }
                                          }

                                          this.setState({ data })
                                        }
                                      }}>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value={e2.title}
                                        checked={
                                          this.state.data &&
                                          this.state.data[e1.question] &&
                                          Array.isArray(
                                            this.state.data[e1.question]
                                          ) &&
                                          this.state.data[e1.question].find(
                                            echeck => echeck === e2.title
                                          )
                                        }
                                        name={e2.title + e1._id}
                                        id={e2.title + e1.question}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor={e2.title + e1.question}>
                                        <span></span>
                                        {e2.title}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                              <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                {e1.response_type === 'radio' &&
                                  e1.options.map((e2, i2) => (
                                    <div
                                      className='form-check'
                                      key={i2}
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        if (e) {
                                          data[e1.question] = e.target.value
                                          this.setState({ data })
                                        }
                                      }}>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        checked={
                                          this.state.data &&
                                          this.state.data[e1.question] ===
                                            e2.title
                                        }
                                        value={e2.title}
                                        id={e2.title + e1._id}
                                        name={e2.title + e1._id}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor={e2.title + e1._id}>
                                        <span
                                          style={{
                                            marginLeft: '2.75rem',
                                          }}></span>
                                        {e2.title}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          ))}
                        </>
                      ))}
                  <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                    <div class='pi-body d-flex flex-wrap'>
                      <p style={{ fontSize: '11px' }}>
                        {detail && detail.data && detail.data.footer_text}
                      </p>
                    </div>
                  </div>
                  <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                    {(this.props.answer.data &&
                      this.props.answer.data.is_submitted === false) ||
                    this.props.answer.data === null ? (
                      <>
                        <button
                          type='submit'
                          className='btn btn-outline no-shadow'>
                          Save
                        </button>
                        <button
                          type='submit'
                          onClick={() => this.setState({ is_submitted: true })}
                          className='btn btn-primary'>
                          Submit
                        </button>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              </form>
            )}
          </div>
        </div>
        <div
          className={
            this.state.isShow1 === true ||
            this.state.isShow2 === true ||
            this.state.isShow3 === true
              ? 'overlay4 show'
              : 'overlay4'
          }></div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  appointment: getAppointment(state).appointment,
  detail: getAssessment(state).detail,
  answer: getAssessment(state).answer,
  psychiatrist: getPatient(state).psychiatrist,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  loading: getAssessment(state).answerLoading,
  assessment: getAssessment(state).assessment,
  getPatientProfile: getPatient(state).profile,
  getPatientHistory: getPatient(state).history,
})
const mapDispatchToProps = dispatch => ({
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadAnswerAssessment: params => dispatch(loadAnswerAssessment(params)),
  loadAppointmentDetailPatient: (data, callBack) =>
    dispatch(loadAppointmentDetailPatient(data, callBack)),
  addAnswerAssessment: (data, callBack) =>
    dispatch(addAnswerAssessment(data, callBack)),
  loadAllAssessment: params => dispatch(loadAllAssessment(params)),
  loadAllAppointment: params => dispatch(loadAllAppointment(params)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Notes)
