import React, { Component } from 'react'
import { connect } from 'react-redux'
import DashboardHeader from './common/dashboardHeader'
import DashboardLeftBar from './common/dashboardLeftBar'
import moment from 'moment'
import PatientDetails from './patientDetail'
import NomineeDetails from './nomineeDetail'
import PatientId from './patientId'
import PatientHistory from './patientHistory'
import PatientWelcome from './patientWelcome'
import { updatePatientProfile } from '../../../store/patient'
import { withRouter } from 'react-router-dom'

class PersonalDetails extends Component {
  state = {
    step: 1,
  }

  componentDidMount = () => {
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
  }
  componentWillUnmount = () => {
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }

  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
  }

  leftSideStep = () => {
    const { isDoctor = false, patientId = '' } = this.props
    
    switch (this.state.step) {
      case 1:
        return (
          <PatientDetails
            updateStep={this.updateStep}
            step={this.state.step}
            isDoctor={isDoctor}
            patientId={patientId}
          />
        )
      case 2:
        return (
          <NomineeDetails
            updateStep={this.updateStep}
            step={this.state.step}
            isDoctor={isDoctor}
            patientId={patientId}
            {...this.props}
          />
        )
      case 3:
        return (
          <PatientId
            updateStep={this.updateStep}
            step={this.state.step}
            initial={this.props?.initial}
            setInitial={() => {
              this.props.setInitial()
            }}
          />
        )

      case 5:
        return (
          <PatientWelcome
            updateStep={this.updateStep}
            step={this.state.step}
            welcome="welcome"
            setInitial={() => {
              this.props.setInitial()
            }}
          />
        )
      default:
        break
    }
  }

  render() {
    const { isDoctor = false } = this.props
    return (
      <>
        {!isDoctor && (
          <>
            <DashboardLeftBar />
            <DashboardHeader />
          </>
        )}
        <div className="dashboard-main-container">{this.leftSideStep()}</div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
})
export default withRouter(connect(null, mapDispatchToProps)(PersonalDetails))
