import React, { Component } from 'react'
import Joi from 'joi-browser'
import loaderIcon from '../../../include/images/loading-icon.svg'
import { toast } from 'react-toastify'
import Form from './../../common/form/form'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { withRouter } from 'react-router-dom'
import {
  mobileAvailability,
  sendOtp,
  emailAvailability,
} from '../../../store/patient'
import {
  mobileInvalid,
  mobileRequired,
  dobRequired,
  dobInvalid,
  emailInvalid,
  emailRequired,
  mobileCodeRequired,
} from '../../common/misc'
import { countries } from '../../../constants/country'

import { connect } from 'react-redux'
import SubmitBtn from '../../common/form/submitBtn'
import LoginHere from './loginHere'
import { Button, Modal } from 'react-bootstrap'
import TermTemplatePopup from '../../common/termTemplatePopup'
import { loadTermTemplate } from '../../../store/seo'
import Select from 'react-select'
import { State, City, Country } from 'country-state-city'

class VerifyMobile extends Form {
  state = {
    btnClass: 'btn btn-lg btn-primary btn-block',
    loader: false,
    data: {
      mobile: '',
      DOB: '',
      email_id: '',
      mobileCode: {
        label: '+91 - India',
        value: 'IN',
        currLabel: '+91',
      },
      country: 'India',
    },
    errors: {},
    show: false,
    popShow: false,
  }

  toggle = e => {
    if (e) e.preventDefault()
    this.setState({ show: !this.state.show })
  }

  componentDidMount = () => {
    if (this.props.accountFor === 'guardian') {
      delete this.schema.DOB
      this.setState({
        data: {
          mobile: '',
          mobileCode: {
            label: '+91 - India',
            value: 'IN',
            currLabel: '+91',
          },
          country: 'India',
        },
        errors: {},
      })
    }

    if (
      this.props.accountFor !== 'guardian' &&
      (this.props.verifyMobile.mobile || this.props.verifyMobile.email_id)
    ) {
      this.setState({
        data: {
          mobile: this.props.verifyMobile.mobile,
          DOB: this.props.verifyMobile.DOB,
          email_id: this.props.verifyMobile.email_id,
          mobileCode: {
            label: '+91 - India',
            value: 'IN',
            currLabel: '+91',
          },
          country: 'India',
        },
      })
    } else if (
      this.props.accountFor === 'guardian' &&
      (this.props.verifyMobile.mobile || this.props.verifyMobile.email_id)
    ) {
      this.setState({
        data: {
          mobile: this.props.verifyMobile.mobile,
          email_id: this.props.verifyMobile.email_id,
          mobileCode: {
            label: '+91 - India',
            value: 'IN',
            currLabel: '+91',
          },
          country: 'India',
        },
      })
    }
  }

  schema = {
    mobile: Joi.number()
      .required()
      .label('Mobile number')
      .min(1000000000)
      .max(999999999999999)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'number.base':
              err.message = mobileRequired
              break

            case 'number.max':
              err.message = mobileInvalid
              break

            case 'number.min':
              err.message = mobileInvalid
              break
          }
        })
        return errors
      }),

    DOB: Joi.date()
      .required()
      //.format("DD/MM/YYYY")
      .max(new Date().setFullYear(new Date().getFullYear() - 18))
      .label('Date of birth')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'date.base':
              err.message = dobRequired
              break

            case 'date.max':
              err.message = dobInvalid
              break
          }
        })
        return errors
      }),
    email_id: Joi.string()
      .email()
      .max(50)
      .required()
      .label('Email')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = emailRequired
              break

            case 'string.email':
              err.message = emailInvalid
              break
            case 'string.max':
              err.message = emailInvalid
              break
          }
        })
        return errors
      }),
    mobileCode: Joi.object()
      .required()
      .error(() => {
        return { message: mobileCodeRequired }
      }),
    country: Joi.string(),
  }

  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      minHeight: 60,
      borderColor: '#e0e0e0',
      borderRadius: 8,
      boxShadow: '0 !important',
    }),
  }

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        loader: true,
        btnClass: 'btn btn-lg btn-primary btn-block disabled',
      })

      const params2 = {
        mobile: this.state.data.mobile,
        type: this.props.accountFor === 'guardian' ? 1 : 2,
      }
      this.props.mobileAvailability(params2, this.callback2)
    }
    // toast(<AlertSuccess message="This is a Succes" />);
    // toast(<AlertError message="This is an error" />);
  }
  callback2 = res => {
    if (res.status === 200) {
      if (this.props.register) {
        const params = {
          email: this.state.data.email_id,
        }
        this.props.emailAvailability(params, this.callback)
      } else {
        this.callback(res)
      }
      // const params = {
      //   mobile: this.state.data.mobile,
      //   type: this.props.accountFor === 'guardian' ? 2 : 1,
      // }
      // this.props.mobileAvailability(params, this.callback)
    } else {
      this.toggle(null)
    }
  }

  comfirmCancel = e => {
    e.preventDefault()
    this.toggle()
    this.setState({
      loader: false,
      btnClass: 'btn btn-lg btn-primary btn-block',
    })
  }

  confirmAccount = e => {
    e.preventDefault()
    const params = {
      mobile: this.state.data.mobile,
      type: this.props.accountFor === 'guardian' ? 2 : 1,
    }
    this.props.mobileAvailability(params, this.callback)
    this.toggle(null)
  }

  callback = res => {
    if (res.status === 200) {
      this.setState({
        loader: false,
        btnClass: 'btn btn-lg btn-primary btn-block',
      })
      const params = {
        mobile: this.state.data.mobile,
        email: this.state.data?.email_id,
        mobileCode: this.state.data.mobileCode?.currLabel,
      }
      this.props.sendOtp(params, this.otpCallBack)
    } else {
      this.setState({
        loader: false,
        btnClass: 'btn btn-lg btn-primary btn-block',
      })
      toast(<AlertError message={<LoginHere msg={res.data.error} />} />)
    }
  }

  otpCallBack = res => {
    if (res.status === 200) {
      toast(<AlertSuccess message={res.data.otp} />)
      this.props.updateStep(null, 3)
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.state.data.mobile,
        DOB: this.state.data.DOB,
        email: this.state.data.email_id,
        mobileCode: this.state.data.mobileCode?.currLabel,
        country: this.state.data.country,
        type: 1,
      })
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }
  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow })
  }

  openPopup = (e, key) => {
    e.preventDefault()
    this.props.loadTermTemplate(key)
    this.togglePopup()
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (
  //     this.props.step !== prevProps.step &&
  //     this.props.accountFor === "guardian"
  //   ) {
  //     alert(1);
  //     delete this.schema.DOB;
  //     this.setState({ data: { mobile: "" }, errors: { mobile: "" } });
  //   }
  // };

  render() {
    return (
      <div className='member-right'>
        <div className='member-right-content d-md-flex align-items-center'>
          <div className='mr-box'>
            <div className='mr-head mb-4'>
              <a href='' onClick={e => this.props.updateStep(e, 1)}>
                <svg
                  version='1.1'
                  id='Capa_1'
                  xmlns='http://www.w3.org/2000/svg'
                  xlink='http://www.w3.org/1999/xlink'
                  x='0px'
                  y='0px'
                  viewBox='0 0 31.494 31.494'
                  space='preserve'>
                  <path
                    d='M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z'
                  />
                </svg>
              </a>
              <div className='mr-step'>Step 2/5</div>
              <h2>Let's Get You Started</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className='form-box'>
                <div className='form-group'>
                  <div className='form-group'>
                    <Select
                      styles={this.customStyles}
                      onChange={e => {
                        const data = { ...this.state.data }
                        data.mobileCode = {
                          label: e.label,
                          value: e.value,
                          currLabel: e.currLabel,
                        }
                        data.country = e.name
                        this.setState({ data })
                      }}
                      placeholder='Select Country Code'
                      value={this.state.data.mobileCode}
                      defaultValue={this.state.data.mobileCode}
                      getOptionLabel={x => x.label}
                      getOptionValue={x => x.value}
                      options={Country.getAllCountries().map(ele => ({
                        label:
                          (ele.phonecode.startsWith('+')
                            ? ele.phonecode
                            : '+' + ele.phonecode) +
                          ' - ' +
                          ele.name,
                        value: ele.isoCode,
                        name: ele.name,
                        currLabel: ele.phonecode.startsWith('+')
                          ? ele.phonecode
                          : '+' + ele.phonecode,
                      }))}
                    />
                  </div>
                  {this.renderInput('mobile', 'Mobile Number')}
                  {this.renderInput('email_id', 'Email ID')}
                  {this.props.accountFor !== 'guardian' &&
                    this.renderDateInput('DOB', 'Date Of Birth')}
                </div>

                <div className='form-group'>
                  <SubmitBtn
                    label='Continue'
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
          <div class='mr-bottom'>
            <p>
              By clicking continue, I agree to{' '}
              <a href='' onClick={e => this.openPopup(e, 'PatientTerms')}>
                terms of service
              </a>{' '}
              and{' '}
              <a
                onClick={e => this.openPopup(e, 'user-privacy-policy')}
                href=''>
                privacy policy.
              </a>
            </p>
          </div>
        </div>
        <>
          <Modal show={this.state.show} onHide={this.toggle}>
            <div class='modal-body'>
              <div class='varification-popup'>
                <h4>Alert!</h4>
                <p>
                  {this.props.accountFor !== 'guardian'
                    ? 'You already exist as a caretaker.'
                    : 'You already exist as a patient.'}
                </p>
                <p>
                  {this.props.accountFor !== 'guardian'
                    ? 'Do you wish to proceed to register as a patient to consult for yourself?'
                    : 'Do you wish to proceed to register as a caretaker to consult for any minor?'}
                </p>
                <a
                  onClick={this.confirmAccount}
                  href='#'
                  class='btn btn-primary'
                  style={{ minWidth: '185px', float: 'right' }}>
                  Okay
                </a>
                <a
                  onClick={this.comfirmCancel}
                  href='#'
                  class='btn btn-cancel'
                  style={{ minWidth: '185px', float: 'right' }}>
                  Cancel
                </a>
              </div>
            </div>
          </Modal>
          <TermTemplatePopup
            show={this.state.popShow}
            toggle={this.togglePopup}
          />
        </>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  loadTermTemplate: key => dispatch(loadTermTemplate(key)),
})
export default withRouter(connect(null, mapDispatchToProps)(VerifyMobile))
