import React from 'react'
import Moment from 'moment'
import { connect } from 'react-redux'
import Form from '../../../common/form/form'
import { withRouter } from 'react-router-dom'
import star from '../../../../include/images/start-0.svg'
import close from '../../../../include/images/icon-close.svg'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import dark from '../../../../include/images/doument-icon-dark.svg'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  getAssessment,
  loadDetailAssessment,
} from '../../../../store/assessment'
import {
  loadAnswerFollowup,
  addAnswerFollowup,
  getFollowup,
  loadTherapy,
} from '../../../../store/followUp'
import moment from 'moment'
import _ from 'lodash'
import AlertSuccess from '../../../common/alertSuccess'
import AlertError from '../../../common/alertError'
import { toast } from 'react-toastify'
import closeIcon from '../../../../include/images/close.svg'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { getDoctor } from '../../../../store/doctor'
import AvtarImage from '../../../common/avtarImage'
import NoRecord from '../../../common/noRecord'

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

class FollowUpView extends Form {
  state = {
    data: {},
    is_submitted: false,
    therephy_method: '',
    techniques: '',
    technique: [],
    popup2: false,
  }
  componentDidMount() {
    if (this.props.doctorProfile.field_type.name !== 'Psychologist') {
      this.props.loadDetailAssessment('60e008b712b30d4b9468b9e4')
    } else {
      this.props.loadDetailAssessment('60eff53584694c08054f57c4')
    }
    if (this.props.id) {
      this.props.loadAnswerFollowup({
        patient_id: this.props.id,
        appointment_id: this.props.appointment_id,
      })
    }
  }

  toggleClose = () => {
    this.props.toggle2()
  }

  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  render() {
    const ques = []
    this.props &&
      this.props.detail &&
      this.props.detail.questions &&
      this.props.detail.questions.map(e => {
        e.questions.map(e1 =>
          Object.keys(
            this.props &&
              this.props.answer &&
              this.props.answer.data !== null &&
              this.props.answer.data &&
              this.props.answer.data.formData &&
              this.props.answer.data.formData
          ).map(e2 => {
            if (e2 == e1.question) {
              ques.push(e1)
            }
          })
        )
      })

    const question = _.chain(ques)
      .groupBy('section.name')
      .map((value, key) => ({
        section: key,
        question: value,
      }))
      .value()
    return (
      <>
        <div
          class='sidebar-box list-box'
          id='prescriptions_side_panel_1'
          style={{ right: this.props.isShow2 === true ? '0px' : '-100%' }}>
          <div class='sidebar-head d-flex align-items-center'>
            <h5>Follow Up Notes</h5>
            <div class='sidebar-right ml-auto d-flex align-items-centers'>
              <div class='close-img' onClick={this.toggleClose}>
                <img src={close} alt='' />
              </div>
            </div>
          </div>
          <div class='sidebar-body height-auto p-0'>
            <div className='sidebar-body'>
              {!this.props.answer.data ? (
                <NoRecord
                  noLogo={true}
                  header='You Have Not Filled The Follow Up Notes'
                  text='You may fill out follow up notes from your home section for the follow up appointments only.'
                />
              ) : (
                <div class='sidebar-inner-content'>
                  <div class='sic-head d-flex flex-wrap justify-content-between'>
                    <div class='sic-head-left flex-grow-1 d-flex '>
                      <div class='sic-head-img'>
                        {this.props.image ? (
                          <img
                            src={process.env.REACT_APP_S3URL + this.props.image}
                            alt=''
                          />
                        ) : (
                          <AvtarImage />
                        )}
                      </div>
                      <div class='sic-head-text'>
                        <h4>
                          {this.props.first_name + ' ' + this.props.last_name}{' '}
                          <i>
                            {' '}
                            {this.props.star === true ? (
                              <img src={bluestar} alt='star-icon' />
                            ) : (
                              <img src={star} alt='star-icon' />
                            )}
                          </i>
                        </h4>
                        <p>{this.props.yesmindy_identification_number}</p>
                      </div>
                    </div>
                  </div>
                  <div class='fun-box'>
                    <div class='files-box d-flex flex-wrap align-items-center'>
                      <img src={dark} alt='' />
                      <div class='files-text-box'>
                        <h3>Follow Up Note</h3>
                      </div>
                    </div>
                    <div class='block-seprator'></div>
                    <div class='fun-body-box'>
                      <ul class='fun-list d-flex flex-wrap'>
                        <li class='fun-item'>
                          <strong>
                            {this.props.first_name + ' ' + this.props.last_name}{' '}
                          </strong>
                          <p>
                            Age: {moment().diff(this.props.age, 'years')} yrs
                          </p>
                          <p>Gender: {this.props.sex}</p>
                        </li>
                        <li class='fun-item'>
                          <strong>
                            UHID:{this.props.yesmindy_identification_number}
                          </strong>
                          <p>Session Date:</p>
                          <p>
                            {this.props.time
                              ? Moment(this.props.time).utc().format('lll')
                              : ''}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class='block-seprator'></div>
                    <div class='sidebar-content-box d-flex flex-wrap'>
                      {/* {Object.keys(this.props.answer.data.formData).map(
                        (ek, i) => (
                          <>
                            {i % 2 === 0 ? (
                              <div
                                class="scb-left"
                                style={{ marginBottom: "5px" }}
                              >
                                <p>{ek}</p>
                                {typeof this.props.answer.data.formData[ek] ===
                                  "string" &&
                                  this.props.answer.data.formData[ek]}
                                {typeof this.props.answer.data.formData[ek] ===
                                  "object" &&
                                this.props.answer.data.formData[ek] &&
                                this.props.answer.data.formData[ek].length >= 1
                                  ? this.props.answer.data.formData[ek]
                                      .map((ec) => (ec.label ? ec.label : ec))
                                      .join(", ")
                                  : this.props.answer.data.formData[ek].label}
                              </div>
                            ) : (
                              <div
                                class="scb-right"
                                style={{ marginBottom: "5px" }}
                              >
                                <p>{ek}</p>
                                {typeof this.props.answer.data.formData[ek] ===
                                  "string" &&
                                  this.props.answer.data.formData[ek]}
                                {typeof this.props.answer.data.formData[ek] ===
                                  "object" &&
                                this.props.answer.data.formData[ek] &&
                                this.props.answer.data.formData[ek].length >= 1
                                  ? this.props.answer.data.formData[ek]
                                      .map((ec) => (ec.label ? ec.label : ec))
                                      .join(", ")
                                  : this.props.answer.data.formData[ek].label}
                              </div>
                            )}
                          </>
                        )
                      )} */}
                      {question &&
                        question.map(e => (
                          <>
                            {e && e.section !== 'undefined' && (
                              <div
                                class='heading-with-icon-box d-flex align-items-center'
                                style={{ width: '100%' }}>
                                <div class='hwi-heading'>
                                  <h3>{e && e.section}</h3>
                                </div>
                              </div>
                            )}
                            {e.question &&
                              e.question.map((e1, i) => (
                                <>
                                  {!e1.parent_question_id && e1.childs ? (
                                    i % 2 === 0 ? (
                                      <div class='scb-left space-bottom'>
                                        <p>{e1.question}</p>
                                        {this.props.answer.data &&
                                          this.props.answer.data.formData &&
                                          typeof this.props.answer.data
                                            .formData[e1.question] ===
                                            'string' &&
                                          this.props.answer.data.formData[
                                            e1.question
                                          ]}
                                        {this.props.answer.data &&
                                        this.props.answer.data.formData &&
                                        typeof this.props.answer.data.formData[
                                          e1.question
                                        ] === 'object' &&
                                        this.props.answer.data.formData[
                                          e1.question
                                        ] &&
                                        this.props.answer.data.formData[
                                          e1.question
                                        ].length >= 1
                                          ? this.props.answer.data.formData[
                                              e1.question
                                            ]
                                              .map(ec =>
                                                ec.label ? ec.label : ec
                                              )
                                              .join(', ')
                                          : this.props.answer.data.formData &&
                                            this.props.answer.data.formData[
                                              e1.question
                                            ] &&
                                            this.props.answer.data.formData[
                                              e1.question
                                            ].label}
                                      </div>
                                    ) : (
                                      <div class='scb-right space-bottom'>
                                        <p>{e1.question}</p>
                                        {this.props.answer.data &&
                                          this.props.answer.data.formData &&
                                          typeof this.props.answer.data
                                            .formData[e1.question] ===
                                            'string' &&
                                          this.props.answer.data.formData[
                                            e1.question
                                          ]}
                                        {this.props.answer.data &&
                                        this.props.answer.data.formData &&
                                        typeof this.props.answer.data.formData[
                                          e1.question
                                        ] === 'object' &&
                                        this.props.answer.data.formData[
                                          e1.question
                                        ] &&
                                        this.props.answer.data.formData[
                                          e1.question
                                        ].length >= 1
                                          ? this.props.answer.data.formData[
                                              e1.question
                                            ]
                                              .map(ec =>
                                                ec.label ? ec.label : ec
                                              )
                                              .join(', ')
                                          : this.props.answer.data.formData &&
                                            this.props.answer.data.formData[
                                              e1.question
                                            ] &&
                                            this.props.answer.data.formData[
                                              e1.question
                                            ].label}
                                      </div>
                                    )
                                  ) : e1.question ===
                                      'Important life events/changes, if any' ||
                                    e1.question ===
                                      'Danger, if any (Suicidal/Homicidal ideations):' ? (
                                    ''
                                  ) : i % 2 === 0 ? (
                                    <div class='scb-left space-bottom'>
                                      <p>{e1.question}</p>
                                      {this.props.answer.data &&
                                        this.props.answer.data.formData &&
                                        typeof this.props.answer.data.formData[
                                          e1.question
                                        ] === 'string' &&
                                        this.props.answer.data.formData[
                                          e1.question
                                        ]}
                                      {this.props.answer.data &&
                                      this.props.answer.data.formData &&
                                      typeof this.props.answer.data.formData[
                                        e1.question
                                      ] === 'object' &&
                                      this.props.answer.data.formData[
                                        e1.question
                                      ] &&
                                      this.props.answer.data.formData[
                                        e1.question
                                      ].length >= 1
                                        ? this.props.answer.data.formData[
                                            e1.question
                                          ]
                                            .map(ec =>
                                              ec.label ? ec.label : ec
                                            )
                                            .join(', ')
                                        : this.props.answer.data.formData &&
                                          this.props.answer.data.formData[
                                            e1.question
                                          ] &&
                                          this.props.answer.data.formData[
                                            e1.question
                                          ].label}
                                    </div>
                                  ) : (
                                    <div class='scb-right space-bottom'>
                                      <p>{e1.question}</p>
                                      {this.props.answer.data &&
                                        this.props.answer.data.formData &&
                                        typeof this.props.answer.data.formData[
                                          e1.question
                                        ] === 'string' &&
                                        this.props.answer.data.formData[
                                          e1.question
                                        ]}
                                      {this.props.answer.data &&
                                      this.props.answer.data.formData &&
                                      typeof this.props.answer.data.formData[
                                        e1.question
                                      ] === 'object' &&
                                      this.props.answer.data.formData[
                                        e1.question
                                      ] &&
                                      this.props.answer.data.formData[
                                        e1.question
                                      ].length >= 1
                                        ? this.props.answer.data.formData[
                                            e1.question
                                          ]
                                            .map(ec =>
                                              ec.label ? ec.label : ec
                                            )
                                            .join(', ')
                                        : this.props.answer.data.formData &&
                                          this.props.answer.data.formData[
                                            e1.question
                                          ] &&
                                          this.props.answer.data.formData[
                                            e1.question
                                          ].label}
                                    </div>
                                  )}
                                </>
                              ))}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.props.isShow1 === true ? 'overlay1 show' : 'overlay1'
          }></div>
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadTherapy: () => dispatch(loadTherapy()),
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadAnswerFollowup: params => dispatch(loadAnswerFollowup(params)),
  addAnswerFollowup: (data, callBack) =>
    dispatch(addAnswerFollowup(data, callBack)),
})

const mapStateToProps = state => ({
  doctorProfile: getDoctor(state).profile,
  detail: getAssessment(state).detail,
  answer: getFollowup(state).answer,
  loading: getFollowup(state).answerLoading,
  therapy: getFollowup(state).therapy,
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FollowUpView)
)
