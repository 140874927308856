import React from 'react'
import { useFastboard, Fastboard } from '@netless/fastboard-react'

const CustomFastBoard = ({ data = {}, fullScreen = false }) => {
  const {
    name = '',
    whiteboard_room_uuid = '',
    whiteboard_room_token = '',
  } = data
  const fastboard = useFastboard(() => ({
    sdkConfig: {
      appIdentifier: 'IQcPUJj-Ee6c9cGw7Ebx5Q/5dDeUs7pxpAicQ',
      region: 'in-mum',
    },
    joinRoom: {
      uid: name,
      uuid: whiteboard_room_uuid,
      roomToken: whiteboard_room_token,
    },
  }))

  console.log('uid custom=========>', {
    uid: name,
    uuid: whiteboard_room_uuid,
    roomToken: whiteboard_room_token,
  })

  return (
    <div
      style={{
        //height: '400px',
        //border: '1px solid',
        minHeight: fullScreen ? '100%' : '400px',
        background: '#f1f2f3',
        height: fullScreen ? '100%' : '400px',
        width: '100%',
      }}>
      <Fastboard app={fastboard} config={{ page_control: { enable: false } }} />
    </div>
  )
}

export default CustomFastBoard
