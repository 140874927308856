import React, { Component } from 'react'
import Joi from 'joi-browser'
import Input from './input'
import SelectUpdate from './selectUpdate'
import Password from './password'
import SuccessInput from './successInput'
import EditInput from './editInput'
import EditSelect from './editSelect'
import DateInput from './dateInput'
import Select from './select'
import Nominee from './nominee'
import Textarea from './textarea'
import Query from './query'
import Prescription from './prescription'
class Form extends Component {
  state = {
    data: {},
    errors: {},
  }
  schema = {
    key_issue: Joi.string().allow('', null),
    notes_data: Joi.string().allow('', null),
    chief_complaints: Joi.string().allow('', null),
    duration_of_session: Joi.string().allow('', null),
    diagnosis: Joi.string().allow(''),
    frequency: Joi.array().items(Joi.object().allow('')).allow(''),
    notes: Joi.string().allow(''),
    plan_for_the_next_session: Joi.any(),
    objective_of_the_next_session: Joi.string().allow(''),
    followup_session: Joi.any(),
    referral: Joi.any(),
    to_chemist: Joi.any(),
    day: Joi.string().allow(''),
    special_advice: Joi.string().allow('', null),
    formulation: Joi.object().allow(''),
    composition: Joi.object().allow(''),
    brand: Joi.object().allow(''),
    dose: Joi.object().allow('', null),
    radiology: Joi.array().items(Joi.object().allow('')).allow('', null),
    theray_method: Joi.object().allow('', null),
    therapy_technique: Joi.array(),
    labinvestigation: Joi.array().items(Joi.object().allow('')).allow('', null),
    techniques: Joi.array().items(Joi.object().allow('')).allow(''),
    medicinalPlan: Joi.array().items(Joi.object().allow('')).allow(''),
    conduct: Joi.array().items(Joi.object().allow('')).allow(''),
    referral_doctor_id: Joi.any(),
    nextFollowUp: Joi.any(),
  }

  validate = () => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.data, this.schema, options)
    if (!error) return null
    const errors = {}
    for (let item of error.details) errors[item.path[0]] = item.message
    return errors
  }

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }
    const schema = { [name]: this.schema[name] }
    const { error } = Joi.validate(obj, schema, { abortEarly: false })
    return error ? error.details[0].message : null
  }

  setErrors = input => {
    const errors = { ...this.state.errors }
    const errorMessage = this.validateProperty(input)
    if (errorMessage) {
      errors[input.name] = errorMessage
    } else {
      delete errors[input.name]
    }

    if (input.name === 'confirmPassword') {
      if (this.state.data.password === input.value) {
        delete errors[input.name]
      }
    }
    this.setState({ errors })
  }

  setData = input => {
    if (input.name) {
      const data = { ...this.state.data }
      if (
        input.name.slice(0, -1) === 'experience_year' ||
        input.name === 'zipcode' ||
        input.name === 'graducation_year' ||
        input.name === 'postgraducation_year' ||
        input.name === 'super_speciality_year' ||
        input.name.substring(0, 4) === 'year'
      ) {
        data[input.name] = input.value.replace(/\D/, '')
        this.setState({ data })
      } else {
        data[input.name] = input.value
        this.setState({ data })
      }
    }
  }

  handleSubmit = e => {
    if (e) e.preventDefault()
    const errors = this.validate()
    this.setState({ errors: errors || {} })
    
    if (errors) return

    this.doSubmit()
  }

  handleChangeSelect = ({ currentTarget: input }) => {
    //console.log("input.name", input);
    this.setErrors(input)
    this.setData(input)
  }

  handleChange = ({ currentTarget: input }) => {
    this.setData(input)
  }

  handleBlur = ({ currentTarget: input }) => {
    this.setErrors(input)
  }

  renderButton(label) {
    return (
      <button disabled={this.validate()} className='btn btn-primary'>
        {label}
      </button>
    )
  }

  renderSelect(name, label, options, readOnly = false, defaultValue) {
    const { data, errors } = this.state

    return data[name] ? (
      <SelectUpdate
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChangeSelect}
        error={errors[name]}
        defaultValue={defaultValue}
        readOnly={readOnly}
      />
    ) : (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChangeSelect}
        error={errors[name]}
        defaultValue={defaultValue}
        readOnly={readOnly}
      />
    )
  }

  rendereditSelect(name, label, options) {
    const { data, errors } = this.state
    return (
      <EditSelect
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChangeSelect}
        error={errors[name]}
      />
    )
  }

  renderDateInput(name, label, options, defaultValue, readOnly) {
    const { data, errors } = this.state
    // console.log("form data", data);
    return (
      <DateInput
        name={name}
        showYearDropdown
        showMonthDropdown
        selected={data[name]}
        label={label}
        options={options}
        value={data[name]}
        onChange={date => {
          let newVal = date
          newVal.setHours(23)
          newVal.setMinutes(59)
          newVal.setSeconds(59)
             
          this.handleChangeSelect({
            currentTarget: { value: newVal, name },
          })
        }}
        error={errors[name]}
        defaultValue={null}
        readOnlyData={readOnly}
        type='text'
      />
    )
  }

  renderInput(name, label, type = 'text', readOnly = false) {
    const { data, errors } = this.state

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    )
  }
  renderPrescription(name, label, type = 'text', readOnly = false) {
    const { data, errors } = this.state

    return (
      <Prescription
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    )
  }
  renderQuery(name, label, type = 'text', readOnly = false) {
    const { data, errors } = this.state

    return (
      <Query
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    )
  }

  renderTextarea(name, label, type = 'text') {
    const { data, errors } = this.state

    return (
      <Textarea
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    )
  }
  renderNominee(
    name,
    label,
    tooltip,
    type = 'text',
    readOnly = false,
    showIcon = true
  ) {
    const { data, errors } = this.state

    return (
      <Nominee
        type={type}
        name={name}
        value={data[name]}
        label={label}
        tooltip={tooltip}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
        showIcon={showIcon}
      />
    )
  }
  renderEdit(name, label, type = 'text') {
    const { data, errors } = this.state
    return (
      <EditInput
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    )
  }

  renderSucess(name, label, type = 'text') {
    const { data, errors } = this.state
    return (
      <SuccessInput
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    )
  }

  renderPasswordInput(name, label, type = 'password') {
    const { data, errors } = this.state
    return (
      <Password
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    )
  }
}

export default Form
