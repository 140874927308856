import { configureStore } from '@reduxjs/toolkit'
import reducer from './reducer'
//import logger from "./middleware/logger";
import toast from './middleware/toast'
import api from './middleware/api'

export default function () {
  return configureStore({
    reducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: ['payload.callback'],
        },
      }),
      toast,
      api,
    ],
  })
}
