import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getSeo } from '../../store/seo'
import ReactHtmlParser from 'react-html-parser'
import closeIcon from './../../include/images/close.svg'
import { getPatient } from '../../store/patient'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars-2'

class TermTemplatePopup extends Component {
  state = {}

  replaceText = () => {
    if (this.props.termTemplate.key === 'userConsentForm') {
      // console.log('this.props.profile', this.props.profile)
      const {
        first_name,
        last_name,
        date_of_birth,
        yesmindy_identification_number,
      } = this.props.profile

      var data = this.props.termTemplate.html_data
      data = data.replace('$name', first_name + ' ' + last_name)
      data = data.replace('$age', moment().diff(moment(date_of_birth), 'years'))
      data = data.replace('$uid', yesmindy_identification_number)
      return data
    } else {
      return this.props.termTemplate.html_data
    }
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={e => (!this.props.consent ? this.props.toggle(e) : () => null)}>
        <div class='modal-header'>
          {!this.props.consent && (
            <button
              type='button'
              class='close'
              data-dismiss='modal'
              onClick={this.props.toggle}>
              <img src={closeIcon} alt='close popup' />
            </button>
          )}
        </div>
        <div class='modal-body modal-body-doc'>
          <Scrollbars style={{ height: window.innerWidth > 768 ? 600 : 450 }}>
            <div class='varification-popup'>
              <h4>{ReactHtmlParser(this.props.termTemplate.header_text)}</h4>
              <h4>
                {this.props.termTemplate.key &&
                  ReactHtmlParser(this.replaceText())}
              </h4>
              {/* <p>{ReactHtmlParser(this.props.termTemplate.html_data)}</p> */}
            </div>
          </Scrollbars>
        </div>
        {this.props.consent && (
          <a
            href=''
            className='btn btn-sm btn-primary'
            onClick={e => {
              e.preventDefault()
              this.props.toggle()
            }}>
            Ok
          </a>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  termTemplate: getSeo(state).termTemplate,
  profile: getPatient(state).profile,
})

export default withRouter(connect(mapStateToProps, null)(TermTemplatePopup))
