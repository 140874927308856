import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
//import moment from "moment";

const slice = createSlice({
  name: 'followUp',
  initialState: {
    answer: {},
    add: {},
    therapy: [],
    loading: false,
    answerLoading: true,
    lastFetch: null,
  },
  reducers: {
    followAnswerRequested: (followUp, action) => {
      followUp.answerLoading = true
    },

    followAnswerReceived: (followUp, action) => {
      followUp.answer = action.payload
      followUp.answerLoading = false
      followUp.lastFetch = Date.now()
    },

    followAnswerRequestFailed: (followUp, action) => {
      followUp.answerLoading = false
    },
    therapyRequested: (followUp, action) => {
      followUp.loading = true
    },

    therapyReceived: (followUp, action) => {
      followUp.therapy = action.payload
      followUp.loading = false
      followUp.lastFetch = Date.now()
    },

    therapyRequestFailed: (followUp, action) => {
      followUp.loading = false
    },
    followAnswerAdded: (followUp, action) => {
      followUp.add = action.payload
      followUp.answerLoading = false
      followUp.lastFetch = Date.now()
    },

    followAnswerAddRequestFailed: (followUp, action) => {
      followUp.answerLoading = false
    },
  },
})

export const {
  followAnswerAdded,
  followAnswerRequested,
  followAnswerReceived,
  followAnswerRequestFailed,
  followAnswerAddRequestFailed,
  therapyReceived,
  therapyRequestFailed,
  therapyRequested,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'assessmentAnswer/followUp'
const url1 = 'therapyMethods'
export const loadAnswerFollowup =
  (params, callback = null) =>
  (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        callback,
        url: url,
        params,
        onStart: followAnswerRequested.type,
        onSuccess: followAnswerReceived.type,
        onError: followAnswerRequestFailed.type,
      })
    )
  }
export const loadTherapy = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1,
      onStart: therapyRequested.type,
      onSuccess: therapyReceived.type,
      onError: therapyRequestFailed.type,
    })
  )
}
export const addAnswerFollowup = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url,
      method: 'post',
      data: param,
      onSuccess: followAnswerAdded.type,
      onError: followAnswerAddRequestFailed.type,
    })
  )
}

export const getFollowup = createSelector(
  state => state.entities.followUp,
  followUp => followUp
)
