import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { withRouter } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import avtarImg from '../../../include/images/avatar1.png'
import bluestar from '../../../include/images/blue-star-icon.svg'
import { Link } from 'react-router-dom'
import star from '../../../include/images/start-0.svg'
import AvtarImage from '../../common/avtarImage'
import NoRecord from '../../common/noRecord'
import { getDoctor } from '../../../store/doctor'
import { connect } from 'react-redux'

class PatientList extends Component {
  state = {}
  render() {
    return (
      <>
        <ReactTooltip className='testPurple' />
        <div
          style={{ height: '700px', overflow: 'auto' }}
          ref={ref => (this.scrollParentRef = ref)}>
          {this.props.doctorPatient && this.props.doctorPatient.length > 0 ? (
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={this.props.loadFunc}
              hasMore={
                !this.props.loading &&
                this.props.pagination &&
                this.props.pagination.filtered_record !=
                  this.props.pagination.total_record_on_current_page
              }
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}>
              {this.props.doctorPatient &&
                this.props.doctorPatient.map((e, i) => (
                  <li
                    class='cm-item'
                    key={i}
                    onClick={ee => {
                      this.props.history.push({
                        pathname: '/doctor/patient-detail',
                        state: e._id,
                      })
                    }}>
                    <div class='cm-box d-flex flex-wrap'>
                      <div class='person-details-box d-flex align-items-center flex-grow-1'>
                        <Link
                          to={{ pathname: '/doctor/patient-detail', state: e }}
                          style={{ display: 'unset' }}>
                          <div class='person-img'>
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=''
                              />
                            ) : (
                              <div class='person-img'>
                                <AvtarImage />
                              </div>
                            )}
                          </div>
                        </Link>
                        <div class='person-text-box'>
                          <h3>
                            {' '}
                            <Link
                              to={{
                                pathname: '/doctor/patient-detail',
                                state: e._id,
                              }}
                              style={{ display: 'unset' }}>
                              {e.first_name + ' ' + e.last_name}{' '}
                            </Link>
                            <i>
                              {this.props.UpdateStar ? (
                                <>
                                  {' '}
                                  {e.isStar === true ? (
                                    <img
                                      src={bluestar}
                                      alt='star-icon'
                                      onClick={ex => {
                                        this.props.UpdateStar(e._id)
                                        ex.stopPropagation()
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={star}
                                      alt='star-icon'
                                      onClick={ex => {
                                        this.props.UpdateStar(e._id)
                                        ex.stopPropagation()
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {e.isStar === true ? (
                                    <img src={bluestar} alt='star-icon' />
                                  ) : (
                                    <img src={star} alt='star-icon' />
                                  )}
                                </>
                              )}
                            </i>
                          </h3>
                          <div class='patient-btn d-flex align-items-center'>
                            <p>{e.yesmindy_identification_number}</p>
                            <a href='#' class='profile-box'>
                              {e.type === 1 && e.partner_id === null && (
                                <i class='fas fa-user'></i>
                              )}
                              {e.type === 2 && <i class='fas fa-child'></i>}
                              {e.type === 1 && e.partner_id != null && (
                                <i class='fas fa-users'></i>
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class='pd-right-content'>
                        {e.caserecord.finish &&
                          e.caserecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ] &&
                          e.caserecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ].diagnosis.length > 0 &&
                          e.caserecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ].diagnosis.map(ed => (
                            <div class='d-flex align-items-center justify-content-end'>
                              <strong>{ed.value}</strong>
                              <div className='custom-dropright'>
                                <div>
                                  <a
                                    className='custom-dropdown-toggle'
                                    href='javascript:void(0)'
                                    data-tip={ed.diagnosis}>
                                    <i
                                      className='fas fa-info-circle'
                                      aria-hidden='true'></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </li>
                ))}
            </InfiniteScroll>
          ) : (
            <NoRecord
              header='No Patient’s Data To Display Here'
              text='aims to empower the clinical practice of our Mental Health Experts with newer technology advancements.'
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  doctorProfile: getDoctor(state).profile,
})

export default withRouter(connect(mapStateToProps, null)(PatientList))
