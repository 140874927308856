import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { WatiScript } from '../../../include/js/wati'
import AgoraRTC from 'agora-rtc-sdk-ng'
import videoslash from '../../../include/images/video-slash.svg'
import messageIcon from '../../../include/images/tab-message.svg'
import videosOn from '../../../include/images/Videoon.png'
import documentIcon from '../../../include/images/tab-document.svg'
import notesIcon from '../../../include/images/notes-icon.svg'
import taskIcon from '../../../include/images/tab-dashboard.svg'
import micOn from '../../../include/images/micon.png'
import Document from './../../patient/dashboard/postAppointment/activity/documents'
import phoneslash from '../../../include/images/phone-slash.svg'
import shareIcon from '../../../include/images/share.svg'
import shareClose from '../../../include/images/share_close.svg'
import iosExit from '../../../include/images/ios-exit.svg'
import fullScreenImg from '../../../include/images/fullscreen-icon.svg'
import Timer from '../../common/timer'
import moment from 'moment'
import {
  getAppointment,
  loadAppointmentDetailPatient,
} from '../../../store/appointment'
import { loadSeo, loadTermTemplate } from '../../../store/seo'
import { getDoctor, loadDoctorProfile } from '../../../store/doctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DashboardHeader from '../profile/common/dashboardHeader'
import Messeges from './postAppointment/activity/messeges'
import Notes from './postAppointment/activity/notes'

import TermTemplatePopup from '../../common/termTemplatePopup'
//import Task from "./timeline/task";
import Task from './postAppointment/activity/task'

import AvtarImage from '../../common/avtarImage'
import { getPatient } from '../../../store/patient'
import LinesEllipsis from 'react-lines-ellipsis'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import CustomFastBoard from '../../../components/doctor/dashboard/Fastboard'

import '../../doctor/dashboard/style.css'

import { io } from 'socket.io-client'

let socket = ''

function VideoCallPatient(props) {
  const APP_ID = process.env.REACT_APP_KEY
  const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

  const [popShow, setPopShow] = useState(false)
  const [data, setData] = useState({
    patient_id: {},
    doctor_id: { field_type: {} },
  })
  const [tokenA, setTokenA] = useState('')
  const [remoteStreams, setRemoteStreams] = useState([])
  const [video, setVideo] = useState(true)
  const [audio, setAudio] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)
  const [tab, setTab] = useState('notes')
  const [localMedia, setLocalMedia] = useState([])
  const [screenShareTrack, setScreenShareTrack] = useState(null)
  const [whiteboard, setWhiteBoard] = useState(null)
  const [whiteBoardData, setWhiteBoardData] = useState({})

  const handleStopShareScreen = async () => {
    setScreenShareTrack(false)
    socket &&
      socket.emit('message', {
        type: 'shareScreen',
        msg: 'start',
        sender: data?.patient_id?._id,
        reciever: data?.doctor_id?._id,
        created_on: moment().format(),
      })
  }

  const shareScreen = async () => {
    setScreenShareTrack(true)
    socket &&
      socket.emit('message', {
        type: 'shareScreen',
        msg: 'start',
        sender: data?.patient_id?._id,
        reciever: data?.doctor_id?._id,
        created_on: moment().format(),
      })
  }

  const changeTab = (tab, e) => {
    e.preventDefault()
    setTab(tab)
  }
  const toggleFull = () => {
    setFullScreen(!fullScreen)
  }
  const togglePopup = () => {
    setPopShow(!popShow)
  }
  const openPopup = (e, key) => {
    if (e) e.preventDefault()
    props.loadTermTemplate(key)
    togglePopup()
  }

  const muteVideo = () => {
    if (localMedia.length > 1) {
      if (video) {
        localMedia[1].setEnabled(false)
      } else {
        localMedia[1].setEnabled(true)
      }
    }
    setVideo(!video)
  }

  const muteAudio = () => {
    if (localMedia.length > 1) {
      if (audio) {
        localMedia[0].setVolume(0)
        //localStream.muteAudio();
      } else {
        localMedia[0].setVolume(100)
        //localStream.unmuteAudio();
      }
    }
    setAudio(!audio)
  }

  const leave = async () => {
    if (localMedia.length > 1) {
      localMedia[0].close()
      localMedia[1].close()
    }
    setRemoteStreams([])
    await client.leave()
    window.close()
  }
  client.on('user-published', async (user, mediaType) => {
    // Subscribe to the remote user when the SDK triggers the "user-published" event.
    await client.subscribe(user, mediaType)
    let channelParameters = {}
    // Subscribe and play the remote video in the container If the remote user publishes a video track.
    if (mediaType == 'video') {
      // Retrieve the remote video track.
      channelParameters.remoteVideoTrack = user.videoTrack
      // Retrieve the remote audio track.
      channelParameters.remoteAudioTrack = user.audioTrack
      // Save the remote user id for reuse.
      channelParameters.remoteUid = user.uid.toString()
      setRemoteStreams([channelParameters])
    }
    // Subscribe and play the remote audio track If the remote user publishes the audio track only.
    if (mediaType == 'audio') {
      // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
      channelParameters.remoteAudioTrack = user.audioTrack
      // Play the remote audio track. No need to pass any DOM element.
      channelParameters.remoteAudioTrack.play()
    }
  })

  client.on('user-unpublished', user => {
    setRemoteStreams([])
    window.close()
  })

  useEffect(() => {
    if (remoteStreams.length > 0)
      remoteStreams[0].remoteVideoTrack.play(
        'agora_remote' + remoteStreams[0].remoteUid
      )
  }, [remoteStreams])

  useEffect(() => {
    openPopup(null, 'userConsentForm')

    props.loadAppointmentDetailPatient(
      props.match.params.appointment_id,
      res => {
        if (res && res.data) {
          setData(res.data.data)
          setTokenA(res.data.tokenA)
        }
      }
    )
  }, []);

  const loadVideoCall = async() =>{
    await client.join(APP_ID, data._id, tokenA, data.patient_id._id)
    let localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
    let localVideoTrack = await AgoraRTC.createCameraVideoTrack()
    setLocalMedia([localAudioTrack, localVideoTrack])
    // Publish the local audio and video tracks in the channel.
    await client.publish([localAudioTrack, localVideoTrack])
    let localPlayerContainer = document.getElementById('agora_local')
    localVideoTrack.play(localPlayerContainer)
  }

  const joinAgoraCall = async () => {
    await loadVideoCall();
    socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      upgrade: false,
      transports: ['websocket'],
      query: {
        _id: data.patient_id._id,
      },
    })
    socket.on('connect', () => {
      socket.on('message',async message => {
        if(message && message.type && message.type == "shareScreen" && message.msg == "start"){
          let _wbData = message.roomData;
          _wbData.name = data.patient_id &&
          data.patient_id.first_name
            ? data.patient_id.first_name +
              ' ' +
              data.patient_id.last_name
            : '';
          setWhiteBoard(_wbData);
        }
        if(message && message.type && message.type == "shareScreen" && message.msg == "end"){
          setWhiteBoard(null);
          await client.leave()
          loadVideoCall();
        }
        console.log('message socket patient', message)
      })
      socket.off('message', () => {})
    })
    socket.connect()
  }

  useEffect(() => {
    document.body.className = 'dashboard-video'
    if (data && Object.keys(data).length > 0 && tokenA != '') {
      joinAgoraCall()
    }
  }, [data, tokenA])

  useEffect(() => {
    const classData = document.getElementsByClassName('mcb-body-fullscreen')
    const topDar = document.getElementsByClassName('dashboard-top-bar')
    if (classData.length > 0 && topDar && topDar.length > 0) {
      topDar[0].style.zIndex = -999
    } else if (topDar && topDar.length > 0) {
      topDar[0].style.zIndex = 999
    }
    let localVideoTrack = localMedia && localMedia.length > 0 && localMedia[1]

    if (localVideoTrack) {
      if (fullScreen) {
        let localPlayerContainer = document.getElementById('agora_local_full')
        localPlayerContainer.innerHTML = ''
        localVideoTrack.play(localPlayerContainer)
      } else {
        let localPlayerContainer = document.getElementById('agora_local')
        localPlayerContainer.innerHTML = ''
        localVideoTrack.play(localPlayerContainer)
      }
    }
  }, [fullScreen])

  return (
    <>
      {/* <Helmet>
        <script id='wati'>{WatiScript}</script>
      </Helmet> */}

      <DashboardHeader />

      <ReactTooltip id='my-tooltip' className='testPurple' html={true} />

      {data && data.doctor_id && data.doctor_id._id && (
        <div class='dashboard-main-container'>
          {/* {JSON.stringify(
              props.getPatient.lastFollowup &&
                props.getPatient.lastFollowup.formData &&
                props.getPatient.lastFollowup.formData[
                  "Current Complaints:"
                ]
            )} */}
          <div class='video-box d-xl-flex flex-wrap'>
            <div
              className={
                fullScreen
                  ? 'main-content-box main-content-box3'
                  : 'main-content-box '
              }>
              <div class='mcb-head'>
                <ReactTooltip
                  id='toolTip'
                  multiline={true}
                  className='testPurple'
                />
                <ul class='doc-intro-list d-md-flex flex-wrap'>
                  <li class='di-item'>
                    <div class='di-intro d-flex flex-wrap align-items-center'>
                      <div class='di-img has-active'>
                        {data.doctor_id.image_url ? (
                          <img
                            src={
                              process.env.REACT_APP_S3URL +
                              data.doctor_id.image_url
                            }
                            alt=''
                          />
                        ) : (
                          <AvtarImage />
                        )}
                      </div>
                      <div class='di-text-box'>
                        <h6>{data && data.doctor_id && data.doctor_id.name}</h6>
                        <p>
                          {' '}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.field_type &&
                            data.doctor_id.field_type.name}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.graduation &&
                            ', ' + data.doctor_id.graduation.education}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.post_graduation &&
                            ', ' + data.doctor_id.post_graduation.education}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.super_speciality &&
                            ', ' + data.doctor_id.super_speciality.education}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    class='di-item'
                    data-for='toolTip'
                    data-tip={
                      props.getPatient &&
                      props.getPatient.lastFollowupNotes[
                        data.doctor_id.field_type.name
                      ] &&
                      Object.keys(
                        props.getPatient.lastFollowupNotes[
                          data.doctor_id.field_type.name
                        ]
                      ).length > 0
                        ? props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ].formData &&
                          props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ].formData['Current Complaints:'] &&
                          typeof props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ].formData['Current Complaints:'] === 'string'
                          ? props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:']
                          : props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:'] &&
                            props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:'].length > 0
                          ? props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:'] &&
                            props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:']
                              .map(ec => ec.value)
                              .join(', ')
                          : 'NA'
                        : 'NA'
                    }>
                    <h3>Current Complaints:</h3>
                    <p>
                      <LinesEllipsis
                        text={
                          props.getPatient &&
                          props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ] &&
                          Object.keys(
                            props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ]
                          ).length > 0
                            ? props.getPatient.lastFollowupNotes[
                                data.doctor_id.field_type.name
                              ].formData &&
                              props.getPatient.lastFollowupNotes[
                                data.doctor_id.field_type.name
                              ].formData['Current Complaints:'] &&
                              typeof props.getPatient.lastFollowupNotes[
                                data.doctor_id.field_type.name
                              ].formData['Current Complaints:'] === 'string'
                              ? props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:']
                              : props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:'] &&
                                props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:'].length > 0
                              ? props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:'] &&
                                props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:']
                                  .map(ec => ec.value)
                                  .join(', ')
                              : 'NA'
                            : 'NA'
                        }
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                      />
                    </p>
                  </li>
                  <li
                    class='di-item'
                    data-for='toolTip'
                    data-tip={
                      props.getPatient.prevPsychiatristPrescription &&
                      props.getPatient.prevPsychiatristPrescription
                        .medical_prescription &&
                      props.getPatient.prevPsychiatristPrescription
                        .medical_prescription.length > 0 &&
                      props.getPatient.prevPsychiatristPrescription
                        .medical_prescription[0].composition
                        ? props.getPatient.prevPsychiatristPrescription.medical_prescription
                            .map(
                              elp =>
                                elp.formulation +
                                ' ' +
                                elp.composition +
                                ' ' +
                                elp.dose
                            )
                            .join(', ')
                        : 'NA'
                    }>
                    <h3>Last advise:</h3>
                    <p>
                      {data &&
                      data.doctor_id &&
                      data.doctor_id.field_type &&
                      data.doctor_id.field_type.name &&
                      data.doctor_id.field_type.name === 'Psychologist' ? (
                        <LinesEllipsis
                          text={
                            props.getPatient.prevPsychologistPrescription &&
                            props.getPatient.prevPsychologistPrescription
                              .theray_method
                              ? props.getPatient.prevPsychologistPrescription
                                  .theray_method
                              : 'NA'
                          }
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='letters'
                        />
                      ) : (
                        <LinesEllipsis
                          text={
                            props.getPatient.prevPsychiatristPrescription &&
                            props.getPatient.prevPsychiatristPrescription
                              .medical_prescription &&
                            props.getPatient.prevPsychiatristPrescription
                              .medical_prescription.length > 0 &&
                            props.getPatient.prevPsychiatristPrescription
                              .medical_prescription[0].composition
                              ? props.getPatient.prevPsychiatristPrescription.medical_prescription
                                  .map(
                                    elp =>
                                      elp.formulation +
                                      ' ' +
                                      elp.composition +
                                      ' ' +
                                      elp.dose
                                  )
                                  .join(', ')
                              : 'NA'
                          }
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='letters'
                        />
                      )}
                    </p>
                  </li>
                </ul>
              </div>
              {whiteboard ? <CustomFastBoard data={whiteboard} /> : 
                <div
                  class={`call-end ${
                    fullScreen ? 'mcb-body-fullscreen' : 'mcb-body'
                  }`}>
                  {Object.keys(remoteStreams).length === 0 && (
                    <>
                      <div class='doc-image'></div>
                      <h4>Connecting...</h4>
                    </>
                  )}
                  {Object.keys(remoteStreams).map(key => {
                    let stream = remoteStreams[key]
                    let streamId = stream.remoteUid
                    return (
                      <div
                        class='doc-image'
                        key={streamId}
                        id={`agora_remote${streamId}`}></div>
                    )
                  })}

                  {!fullScreen && (
                    <>
                      <div
                        class='fullscreen-button'
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleFull()}>
                        <img src={fullScreenImg} alt='' />
                      </div>
                      <div id='agora_local' class='front-thumbnail-box'></div>
                    </>
                  )}
                </div>
              }
              {fullScreen && (
                <>
                  <div
                    class='fullscreen-button'
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleFull()}>
                    <img src={fullScreenImg} alt='' />
                  </div>
                  <div id='agora_local_full' class='front-thumbnail-box'></div>
                </>
              )}

              <div class={`${fullScreen ? 'mcb-footer-fullscreen' : ''} `}>
                <div className={`${fullScreen ? 'mb-foor-app' : ''} `}>
                  <div class='mcb-footer d-flex align-items-center justify-content-between'>
                    <div class='mcb-left'>
                      {moment().format('MMMM Do, YYYY')}
                    </div>
                    <div class='mcb-mid d-flex align-items-center flex-wrap'>
                      <div class='mcb-link' onClick={muteVideo}>
                        <div class='img'>
                          <img src={!video ? videoslash : videosOn} alt='' />
                        </div>
                        <p>Camera</p>
                      </div>
                      <div class='mcb-link' onClick={muteAudio}>
                        <div class='img'>
                          <img src={!audio ? phoneslash : micOn} alt='' />
                        </div>
                        <p>Mic</p>
                      </div>
                      <div
                        class='mcb-link'
                        onClick={() =>
                          screenShareTrack
                            ? handleStopShareScreen()
                            : shareScreen()
                        }>
                        <div class='img'>
                          <img
                            src={!screenShareTrack ? shareIcon : shareClose}
                            alt=''
                          />
                        </div>
                        <p>share</p>
                      </div>
                      <div class='mcb-link leave' onClick={leave}>
                        <div class='img'>
                          <img src={iosExit} alt='' />
                        </div>
                        <p>Leave</p>
                      </div>
                    </div>
                    <div class='mcb-right'>
                      <Timer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!fullScreen && (
              <div class='video-sidebar video-sidebar2'>
                <div class='vs-box'>
                  <div class='tab-container d-none d-md-block'>
                    <ul
                      class='nav nav-tabs videochat-tabs'
                      id='myTab'
                      role='tablist'>
                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='You can message your experts for up to 3 days after every appointment.'>
                        <a
                          class={
                            tab === 'message' ? 'nav-link active' : 'nav-link'
                          }
                          id='messages-tab'
                          data-toggle='tab'
                          href='#messages'
                          role='tab'
                          aria-controls='messages'
                          aria-selected='true'
                          onClick={e => changeTab('message', e)}>
                          <img src={messageIcon} alt='' />
                          <b>Messages</b>
                        </a>
                      </li>

                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='Reports/ Past prescriptions can be shared from here with your                                                                                          
                experts.'>
                        <a
                          class={
                            tab === 'documents' ? 'nav-link active' : 'nav-link'
                          }
                          id='profile-tab'
                          data-toggle='tab'
                          href='#mySummary'
                          role='tab'
                          aria-controls='mySummary'
                          aria-selected='false'
                          onClick={e => changeTab('documents', e)}>
                          {' '}
                          <img src={documentIcon} alt='' />
                          <b>My Reports</b>{' '}
                        </a>
                      </li>
                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='Notes & Thoughts'>
                        <a
                          class={
                            tab === 'notes' ? 'nav-link active' : 'nav-link'
                          }
                          id='contact-tab'
                          data-toggle='tab'
                          href='#myNotes'
                          role='tab'
                          aria-controls='myNotes'
                          aria-selected='false'
                          onClick={e => changeTab('notes', e)}>
                          {' '}
                          <img src={notesIcon} alt='' />
                          <b>Notes & Thoughts</b>{' '}
                        </a>
                      </li>
                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='When task is allotted by your expert it will be visible here till  
                Completed and submitted. Find completed tasks under timeline.'>
                        <a
                          class={
                            tab === 'tasks' ? 'nav-link active' : 'nav-link'
                          }
                          id='contact-tab'
                          data-toggle='tab'
                          href='#myTasks'
                          role='tab'
                          aria-controls='myTasks'
                          aria-selected='false'
                          onClick={e => changeTab('tasks', e)}>
                          {' '}
                          <img src={taskIcon} alt='' />
                          <b>Tasks</b>{' '}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class='chatbot-body p-md-0'>
                    <div class='tab-content' id='myTabContent'>
                      <div
                        class={
                          tab === 'message'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        <Messeges
                          doctor_id={data.doctor_id._id}
                          patient_id={
                            JSON.parse(localStorage.getItem('patient'))._id
                          }
                          tab={tab}
                          setTab={changeTab}
                        />
                      </div>
                      <div
                        class={
                          tab === 'documents'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        <Document
                          videoCall={true}
                          tab={tab}
                          setTab={changeTab}
                        />
                      </div>

                      <div
                        class={
                          tab === 'notes'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        <Notes
                          aapointment_id={props.match.params.appointment_id}
                          doctor_id={data.doctor_id._id}
                          tab={tab}
                          setTab={changeTab}
                        />
                      </div>

                      <div
                        class={
                          tab === 'tasks'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        {data && data.doctor_id && data.doctor_id._id && (
                          <Task
                            videoCall={true}
                            aapointment_id={props.match.params.appointment_id}
                            doctor_id={data.doctor_id._id}
                            tab={tab}
                            setTab={changeTab}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <TermTemplatePopup consent={true} show={popShow} toggle={togglePopup} />
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadTermTemplate: params => dispatch(loadTermTemplate(params)),
  loadAppointmentDetailPatient: (id, callback) =>
    dispatch(loadAppointmentDetailPatient(id, callback)),
})
const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  getPatient: getPatient(state),
  doctorProfile: getDoctor(state).profile,
  getAppointment: getAppointment(state).doctorAppointment,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoCallPatient)
)
