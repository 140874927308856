import React, { Component } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class ActivityTab extends Component {
  state = {}
  render() {
    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <ul className='nav nav-tabs nav-tabs2' id='myTab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <a
              className={
                this.props.tab === 'messages' ? 'nav-link active' : 'nav-link'
              }
              id='home-tab'
              data-toggle='tab'
              role='tab'
              href='#'
              aria-controls='messages'
              aria-selected='true'
              onClick={e => this.props.setTab('messages', e)}>
              <b>Messages</b>
              <span>Messages</span>
              <i
                data-tip='You can message your experts for up to 3 days after every appointment.'
                class='fas fa-info-circle ml-2'
                aria-hidden='true'
                style={{ color: 'rgb(24 0 88 / 50%)', fontSize: '12px' }}></i>
            </a>
          </li>
          <li className='nav-item' role='presentation'>
            <a
              // data-tip="This will be for the latest appointment booked. Must be filled before appointment starts."
              className={
                this.props.tab === 'notes' ? 'nav-link active' : 'nav-link'
              }
              id='notes-tab'
              data-toggle='tab'
              role='tab'
              href='#'
              aria-controls='notes'
              aria-selected='false'
              onClick={e => this.props.setTab('notes', e)}>
              <b>Notes & Thoughts</b>
              <span>Notes & Thoughts</span>
            </a>
          </li>
          <li className='nav-item' role='presentation'>
            <a
              className={
                this.props.tab === 'tasks' ? 'nav-link active' : 'nav-link'
              }
              id='tasks-tab'
              data-toggle='tab'
              onClick={e => this.props.setTab('tasks', e)}
              role='tab'
              href='#'
              aria-controls='tasks'
              aria-selected='false'>
              <b>Tasks</b>
              <span>Tasks</span>
              <i
                data-tip='When task is allotted by your expert it will be visible here till  
                Completed and submitted. Find completed tasks under timeline.'
                class='fas fa-info-circle ml-2'
                aria-hidden='true'
                style={{ color: 'rgb(24 0 88 / 50%)', fontSize: '12px' }}></i>
            </a>
          </li>
          <li className='nav-item' role='presentation'>
            <a
              className={
                this.props.tab === 'documents' ? 'nav-link active' : 'nav-link'
              }
              id='documents-tab'
              data-toggle='tab'
              onClick={e => this.props.setTab('documents', e)}
              role='tab'
              href='#'
              aria-controls='documents'
              aria-selected='false'>
              <b>Documents</b>
              <span>Documents</span>
              <i
                data-tip='Reports/ Past prescriptions can be shared from here with your                                                                                          
                experts.'
                class='fas fa-info-circle ml-2'
                aria-hidden='true'
                style={{ color: 'rgb(24 0 88 / 50%)', fontSize: '12px' }}></i>
            </a>
          </li>
        </ul>
      </>
    )
  }
}

export default ActivityTab
