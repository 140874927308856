import React, { Component } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import dotsImg from '../../../../include/images/vertical-dots.svg'
import vCall from '../../../../include/images/np-video-call.svg'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import calculatorimg from '../../../../include/images/calculatorimg.svg'
import circle from '../../../../include/images/circle-check-icon.svg'
import { isMobile } from 'react-device-detect'

import {
  getPatient,
  loadPatientProfile,
  removeRequest,
} from '../../../../store/patient'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import {
  cancellAppointment,
  getAppointment,
  loadAppointmentDetailPatient,
  loadDate,
} from '../../../../store/appointment'
import { toast } from 'react-toastify'
import AlertSuccess from '../../../common/alertSuccess'
import AlertError from '../../../common/alertError'
import DateTimeSelection from '../../doctor/appointment/dateTimeSelection'
import Prescription from '../../../patient/dashboard/timeline/prescription'
import { getMisc, loadConstant } from '../../../../store/misc'
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
class DoctorSelection extends Component {
  state = {
    cancelConfirm: false,
    doctorName: '',
    popUp: false,
    popUpExpert: false,
    popUpExpert2: false,
    expertId: '',
    appointId: '',
    loader: false,
    userName: '',
    appointment_type: '',
    selectedDr: '',
    appointment_id: '',
    isShow2: false,
    formSuccessModel: false,
    data: {
      reason_title: '',
      reason_text: '',
    },
  }

  toggle2 = appointment_id => {
    this.setState({
      isShow2: !this.state.isShow2,
      appointment_id: appointment_id,
    })
  }

  onChangeInput = e => {
    const { data } = this.state
    data[e.target.name] = e.target.value
    this.setState({ data })
  }

  reSchule = (e, appointId) => {
    e.preventDefault()
    this.props.history.push({
      pathname: '/dr/' + this.state.userName,
      appointId: this.state.appointId,
      appointment_type: this.state.appointment_type,
    })
  }
  componentDidMount = () => {
    this.props.loadConstant()
    if (this.props.psychiatrist && this.props.psychiatrist._id) {
      this.setState({ selectedDr: this.props.psychiatrist._id })
      this.props.setDoctorId(this.props.psychiatrist._id)
      this.props.setAapointment_id(
        this.props.psychiatristAppointment._id ||
          this.props.patientData.prevPsychiatristAppointment._id
      )
    } else if (this.props.psychologist && this.props.psychologist._id) {
      this.setState({ selectedDr: this.props.psychologist._id })
      this.props.setAapointment_id(
        this.props.psychologistAppointment._id ||
          this.props.patientData.prevPsychologistAppointment._id
      )
      this.props.setDoctorId(this.props.psychologist._id)
    }
    this.props.loadPatientProfile()
  }
  toggleExpert = e => {
    if (e) e.preventDefault()
    this.setState({ popUpExpert: !this.state.popUpExpert })
  }
  toggleExpert2 = e => {
    if (e) e.preventDefault()
    this.setState({
      popUpExpert2: !this.state.popUpExpert2,
      data: {
        reason_title: '',
        reason_text: '',
      },
    })
  }

  toggle = e => {
    if (e) e.preventDefault()
    this.setState({ popUp: !this.state.popUp })
  }

  cancellAppointment = (e, id) => {
    e.preventDefault()
    this.props.cancellAppointment(id, this.callBack)
    this.setState({ loader: true })
  }
  callBack = res => {
    this.toggle()
    this.setState({ loader: false, cancelConfirm: false })
    this.props.loadPatientProfile()
    if (res.status === 200) {
      toast(<AlertSuccess message={'Appointment cancelled successfully'} />)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  changeBodyClass = (e, nameClass) => {
    this.toggle()
    e.preventDefault()
    this.setState({ selectedDate: '', selectedTime: '', preview: false })
    document.body.classList.add(nameClass)
    this.props.loadDate()
  }

  doSubmitRemove = e => {
    e.preventDefault()
    const payLoad = {
      doctor_id: this.state.expertId,
      reason_title: this.state.data.reason_title,
      reason_text: this.state.data.reason_text,
    }
    this.props.removeRequest(payLoad, this.callBackReq)
    console.log(payLoad)
  }

  callBackReq = res => {
    //this.toggleExpert2();
    if (res.status === 200) {
      this.toggleFormSuccess()
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  onCall = (e, id) => {
    e.preventDefault()
    window.open('/patient/video-call/' + id, '_blank').focus()
  }

  toggleFormSuccess = () => {
    this.setState({
      formSuccessModel: !this.state.formSuccessModel,
    })
  }
  render() {
    const { newDoctor = false, type = '' } = this.props
    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <div className={!newDoctor ? 'chatbot-pannel-left' : ''}>
          {!newDoctor && <h4>My Treating Team</h4>}
          {newDoctor ? (
            <>
              <div className='cpl-wrapper-outer'>
                {this.props.psychiatrist &&
                this.props.psychiatrist._id &&
                type === 'Psychiatrist' ? (
                  <div
                    onClick={e => {
                      this.setState({ dropDown: false })
                      if (this.props.psychiatrist._id !== this.state.selectedDr)
                        this.setState({
                          selectedDr: this.props.psychiatrist._id,
                        })
                      this.props.setDoctorId(this.props.psychiatrist._id)
                      this.props.setAapointment_id(
                        this.props.psychiatristAppointment._id ||
                          this.props.patientData.prevPsychiatristAppointment._id
                      )
                    }}
                    className={
                      this.state.selectedDr === this.props.psychiatrist._id
                        ? 'cpl-wrapper-box d-flex flex-column add-shape'
                        : 'cpl-wrapper-box d-flex flex-column add-bg'
                    }>
                    <div className='cpl-head d-flex align-items-center justify-content-between'>
                      <h3>My Psychiatrist</h3>
                      <div className='dropright'>
                        {!newDoctor && (
                          <a
                            className='dropdown-toggle'
                            href='#'
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              this.props.setDoctorId(
                                this.props.psychiatrist._id
                              )
                              this.props.setDropDown(!this.props.dropDown)
                              this.setState({
                                selectedDr: this.props.psychiatrist._id,
                                dropDown: true,
                              })
                            }}
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'>
                            <img src={dotsImg} alt='' />
                          </a>
                        )}
                        <div
                          style={
                            isMobile
                              ? {
                                  zIndex: 99999999999,
                                  position: 'absolute',
                                  transform: 'translate3d(-173px, 0px, 0px)',
                                  top: '0px',
                                  left: '0px',
                                  willChange: 'transform',
                                }
                              : {}
                          }
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              dropDown: false,
                            })
                          }}
                          className={
                            this.props.dropDown &&
                            this.props.doctor_id === this.props.psychiatrist._id
                              ? 'dropdown-menu dropdown-custom show'
                              : 'dropdown-menu dropdown-custom'
                          }
                          x-placement={isMobile ? 'left-start' : 'right-start'}>
                          <Link
                            className='dropdown-item'
                            to='/patient/dashboard-timeline'>
                            Past Records
                          </Link>
                          {this.props.psychiatristAppointment &&
                            moment(
                              this.props.psychiatristAppointment
                                .appointment_time
                            )
                              .subtract({ hours: 5, minutes: 30 })
                              .diff(moment(), 'minutes') >
                              this.props.constants
                                .appointment_cancellation_allowed_before && (
                              <a
                                className='dropdown-item'
                                href='#!'
                                onClick={e => {
                                  this.setState({
                                    appointId:
                                      this.props.psychiatristAppointment._id,
                                    userName: this.props.psychiatrist.user_name,
                                    appointment_type:
                                      this.props.psychiatristAppointment
                                        .appointment_type,
                                  })
                                  this.toggle(e)
                                }}>
                                Modify Appointment{' '}
                              </a>
                            )}
                          <a
                            className='dropdown-item'
                            href='#!'
                            onClick={e => {
                              this.setState({
                                expertId: this.props.psychiatrist._id,
                                doctorName: this.props.psychiatrist.name,
                              })
                              this.toggleExpert(e)
                            }}>
                            Change My Experts
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='cpl-box d-flex flex-wrap'>
                      <div className='cpl-img has-active'>
                        <img
                          src={
                            process.env.REACT_APP_S3URL +
                            this.props.psychiatrist.image_url
                          }
                          alt=''
                        />
                      </div>
                      <div className='cpl-text-box'>
                        <h5>{this.props.psychiatrist.name}</h5>
                        <p>
                          {this.props.psychiatrist.graduation &&
                            this.props.psychiatrist.graduation.education}
                          {this.props.psychiatrist.post_graduation &&
                            ', ' +
                              this.props.psychiatrist.post_graduation.education}
                          {this.props.psychiatrist.super_speciality &&
                            ', ' +
                              this.props.psychiatrist.super_speciality
                                .education}
                        </p>
                      </div>
                    </div>
                    <div className='cpl-box'>
                      {this.props.psychiatristAppointment.appointment_time &&
                      moment(
                        this.props.psychiatristAppointment.appointment_time
                      ).utc() > moment() ? (
                        <div class='row'>
                          <div className='cpl-intro-desc col-8'>
                            <div class='d-flex'>
                              <p>Upcoming appointment</p>
                              <i
                                data-tip='Join call icon and Prescription will appear in this area.'
                                class='fas fa-info-circle ml-1 mt-1'
                                aria-hidden='true'
                                style={{
                                  color: 'rgb(24 0 88 / 50%)',
                                  fontSize: '12px',
                                }}></i>
                            </div>
                            <p>
                              On{' '}
                              <strong>
                                {this.props.psychiatristAppointment &&
                                moment(
                                  this.props.psychiatristAppointment &&
                                    this.props.psychiatristAppointment
                                      .appointment_time
                                )
                                  .utc()
                                  .format('DD-MM-YYYY') ===
                                  moment().format('DD-MM-YYYY')
                                  ? 'Today'
                                  : moment(
                                      this.props.psychiatristAppointment
                                        .appointment_time
                                    )
                                      .utc()
                                      .format('DD-MM-YYYY')}
                              </strong>{' '}
                              at{' '}
                              <strong>
                                {this.props.psychiatristAppointment &&
                                  moment(
                                    this.props.psychiatristAppointment
                                      .appointment_time
                                  )
                                    .utc()
                                    .format('hh:mm A')}
                                {''}
                              </strong>
                            </p>
                          </div>

                          {this.props.psychiatristAppointment
                            .appointment_type === 'online' && (
                            <a
                              onClick={e => {
                                if (
                                  this.props.psychiatristAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychiatristAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                ) {
                                  this.onCall(
                                    e,
                                    this.props.psychiatristAppointment._id
                                  )
                                }
                              }}
                              data-tip={
                                this.props.psychiatristAppointment
                                  .appointment_type === 'online' &&
                                moment().diff(
                                  moment(
                                    this.props.psychiatristAppointment
                                      .appointment_time
                                  ).subtract({ hours: 5, minutes: 30 }),
                                  'minutes'
                                ) > -10
                                  ? ''
                                  : 'Join will be active 10 minutes before appointment'
                              }
                              className='btn btn-blue'
                              style={{
                                cursor:
                                  this.props.psychiatristAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychiatristAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                    ? 'pointer'
                                    : 'initial',
                                minWidth: '0px',
                                height: '50px',
                              }}>
                              Join Call
                            </a>
                          )}
                        </div>
                      ) : (
                        <>
                          {this.props.patientData.prevPsychiatristAppointment &&
                          this.props.patientData.prevPsychiatristAppointment
                            ._id ? (
                            <>
                              <div className='cpl-intro-desc'>
                                Prescription available
                              </div>
                              <div
                                class='cpl-box'
                                onClick={e =>
                                  console.log(
                                    this.props.patientData
                                      .prevPsychiatristAppointment._id
                                  )
                                }>
                                <a
                                  href=''
                                  className='np-links-pres'
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.toggle2(
                                      this.props.patientData
                                        .prevPsychiatristAppointment._id
                                    )
                                  }}
                                  style={{ cursor: 'pointer !important' }}>
                                  <img src={calculatorimg} alt='' />
                                </a>
                              </div>
                            </>
                          ) : (
                            <em>
                              “A Psychiatrist interviews, diagnose, prescribes
                              and has all round medical expertise with
                              capability to make you feel better at the
                              earliest. ”
                            </em>
                          )}
                        </>
                      )}
                    </div>

                    {moment(
                      this.props.psychiatristAppointment.appointment_time
                    ).utc() > moment() ? (
                      <div className='cpl-box'>
                        <p>You have tasks remaining</p>
                        <Link
                          to='/patient/dashboard/pre-Appointment'
                          className='btn btn-blue-outline btn-block'
                          onClick={() => {
                            if (isMobile) {
                              window.scrollTo({
                                top: 1000,
                                behavior: 'smooth',
                              })
                            }
                          }}>
                          Complete Pre-Appointment
                        </Link>
                      </div>
                    ) : (
                      <div class='cpl-box'>
                        <p>
                          <small>You don’t have an appointment.</small>
                        </p>
                        <Link
                          to={{
                            pathname: '/patient/doctors',
                            state: { field_type: 'Psychiatrist' },
                          }}
                          class='btn btn-default no-shadow btn-block'>
                          Book an Appointment
                        </Link>
                      </div>
                    )}
                    {newDoctor && (
                      <>
                        <div className='pb-4'>
                          If you want to book an appointment please change your
                          expert
                        </div>
                        <div
                          class='btn btn-default no-shadow btn-block'
                          onClick={e => {
                            this.setState({
                              expertId: this.props.psychiatrist._id,
                              doctorName: this.props.psychiatrist.name,
                            })
                            this.toggleExpert(e)
                          }}>
                          Change My Experts
                        </div>
                      </>
                    )}
                  </div>
                ) : this.props.psychologist &&
                  this.props.psychologist._id &&
                  type === 'Psychologist' ? (
                  <div
                    onClick={e => {
                      this.setState({ dropDown: false })
                      if (this.props.psychologist._id !== this.state.selectedDr)
                        this.setState({
                          selectedDr: this.props.psychologist._id,
                        })
                      this.props.setDoctorId(this.props.psychologist._id)
                      this.props.setAapointment_id(
                        this.props.psychologistAppointment._id ||
                          this.props.patientData.prevPsychologistAppointment._id
                      )
                    }}
                    className={
                      this.state.selectedDr === this.props.psychologist._id
                        ? 'cpl-wrapper-box d-flex flex-column add-shape'
                        : 'cpl-wrapper-box d-flex flex-column add-bg'
                    }>
                    <div className='cpl-head d-flex align-items-center justify-content-between'>
                      <h3>My Psychologist</h3>
                      <div className='dropright'>
                        {!newDoctor && (
                          <a
                            className='dropdown-toggle'
                            href='#'
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              this.props.setDropDown(!this.props.dropDown)
                              this.setState({
                                selectedDr: this.props.psychologist._id,
                                dropDown: true,
                              })
                              this.props.setDoctorId(
                                this.props.psychologist._id
                              )
                            }}
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'>
                            <img src={dotsImg} alt='' />
                          </a>
                        )}
                        <div
                          style={
                            isMobile
                              ? {
                                  zIndex: 99999999999,
                                  position: 'absolute',
                                  transform: 'translate3d(-173px, 0px, 0px)',
                                  top: '0px',
                                  left: '0px',
                                  willChange: 'transform',
                                }
                              : {}
                          }
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              dropDown: false,
                            })
                          }}
                          className={
                            this.props.dropDown &&
                            this.props.doctor_id === this.props.psychologist._id
                              ? 'dropdown-menu dropdown-custom show'
                              : 'dropdown-menu dropdown-custom'
                          }
                          x-placement={isMobile ? 'left-start' : 'right-start'}>
                          <Link
                            className='dropdown-item'
                            to='/patient/dashboard-timeline'>
                            Past Records{' '}
                          </Link>
                          {this.props.psychologistAppointment &&
                            moment(
                              this.props.psychologistAppointment
                                .appointment_time
                            )
                              .subtract({ hours: 5, minutes: 30 })
                              .diff(moment(), 'minutes') >
                              parseInt(
                                this.props.constants
                                  .appointment_cancellation_allowed_before
                              ) && (
                              <a
                                className='dropdown-item'
                                href='#!'
                                onClick={e => {
                                  this.setState({
                                    appointId:
                                      this.props.psychologistAppointment._id,
                                    userName: this.props.psychologist.user_name,
                                    appointment_type:
                                      this.props.psychologistAppointment
                                        .appointment_type,
                                  })
                                  this.toggle(e)
                                }}>
                                Modify Appointment
                              </a>
                            )}
                          <a
                            className='dropdown-item'
                            href='#!'
                            onClick={e => {
                              this.setState({
                                expertId: this.props.psychologist._id,
                                doctorName: this.props.psychologist.name,
                              })
                              this.toggleExpert(e)
                            }}>
                            Change My Experts
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='cpl-box d-flex flex-wrap'>
                      <div className='cpl-img has-active'>
                        <img
                          src={
                            process.env.REACT_APP_S3URL +
                            this.props.psychologist.image_url
                          }
                          alt=''
                        />
                      </div>
                      <div className='cpl-text-box'>
                        <h5>{this.props.psychologist.name}</h5>

                        <p>
                          {this.props.psychologist.graduation &&
                            this.props.psychologist.graduation.education}
                          {this.props.psychologist.post_graduation &&
                            ', ' +
                              this.props.psychologist.post_graduation.education}
                          {this.props.psychologist.super_speciality &&
                            ', ' +
                              this.props.psychologist.super_speciality
                                .education}
                        </p>
                      </div>
                    </div>
                    <div className='cpl-box'>
                      {moment(
                        this.props.psychologistAppointment.appointment_time
                      ).utc() > moment() ? (
                        <div class='row'>
                          <div className='cpl-intro-desc col-8'>
                            <div class='d-flex'>
                              <p>Upcoming appointment</p>
                              <i
                                data-tip='Join call icon and Prescription will appear in this area.'
                                class='fas fa-info-circle ml-1 mt-1'
                                aria-hidden='true'
                                style={{
                                  color: 'rgb(24 0 88 / 50%)',
                                  fontSize: '12px',
                                }}></i>
                            </div>
                            <p>
                              On{' '}
                              <strong>
                                {moment(
                                  this.props.psychologistAppointment
                                    .appointment_time
                                )
                                  .utc()
                                  .format('DD-MM-YYYY') ===
                                moment().format('DD-MM-YYYY')
                                  ? 'Today'
                                  : moment(
                                      this.props.psychologistAppointment
                                        .appointment_time
                                    )
                                      .utc()
                                      .format('DD-MM-YYYY')}
                              </strong>{' '}
                              at{' '}
                              <strong>
                                {moment(
                                  this.props.psychologistAppointment
                                    .appointment_time
                                )
                                  .utc()
                                  .format('hh:mm A')}{' '}
                              </strong>
                            </p>
                          </div>
                          {this.props.psychologistAppointment
                            .appointment_type === 'online' && (
                            <a
                              onClick={e => {
                                if (
                                  this.props.psychologistAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychologistAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                ) {
                                  this.onCall(
                                    e,
                                    this.props.psychologistAppointment._id
                                  )
                                }
                              }}
                              data-tip={
                                this.props.psychologistAppointment
                                  .appointment_type === 'online' &&
                                moment().diff(
                                  moment(
                                    this.props.psychologistAppointment
                                      .appointment_time
                                  ).subtract({ hours: 5, minutes: 30 }),
                                  'minutes'
                                ) > -10
                                  ? ''
                                  : 'Join will be active 10 minutes before appointment'
                              }
                              className='btn btn-blue'
                              style={{
                                cursor:
                                  this.props.psychologistAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychologistAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                    ? 'pointer'
                                    : 'initial',
                                minWidth: '0px',
                                height: '50px',
                              }}>
                              {' '}
                              Join Call
                            </a>
                          )}
                        </div>
                      ) : (
                        <>
                          {this.props.patientData.prevPsychologistAppointment &&
                          this.props.patientData.prevPsychologistAppointment
                            ._id ? (
                            <>
                              <div className='cpl-intro-desc'>
                                Prescription available
                              </div>
                              <div
                                class='cpl-box'
                                onClick={e =>
                                  console.log(
                                    this.props.patientData
                                      .prevPsychologistAppointment._id
                                  )
                                }>
                                <a
                                  href=''
                                  className='np-links-pres'
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.toggle2(
                                      this.props.patientData
                                        .prevPsychologistAppointment._id
                                    )
                                  }}
                                  style={{ cursor: 'pointer !important' }}>
                                  <img src={calculatorimg} alt='' />
                                </a>
                              </div>
                            </>
                          ) : (
                            <em>
                              “A psychologist can help you unwind your mind in
                              ways you can’t imagine…”
                            </em>
                          )}
                        </>
                      )}
                    </div>
                    {moment(
                      this.props.psychologistAppointment.appointment_time
                    ).utc() > moment() ? (
                      <div className='cpl-box'>
                        <p>You have tasks remaining</p>
                        <Link
                          to='/patient/dashboard/pre-Appointment'
                          className='btn btn-blue-outline btn-block'
                          onClick={() => {
                            if (isMobile) {
                              window.scrollTo({
                                top: 1000,
                                behavior: 'smooth',
                              })
                            }
                          }}>
                          Complete Pre-Appointment
                        </Link>
                      </div>
                    ) : (
                      <div class='cpl-box'>
                        <p>
                          <small>You don’t have an appointment.</small>
                        </p>
                        <Link
                          to={{
                            pathname: '/patient/doctors',
                            state: { field_type: 'Psychologist' },
                          }}
                          class='btn btn-default no-shadow btn-block'>
                          Book an Appointment
                        </Link>
                      </div>
                    )}

                    {newDoctor && (
                      <>
                        <div className='pb-4'>
                          If you want to book an appointment please change your
                          expert
                        </div>
                        <div
                          class='btn btn-default no-shadow btn-block'
                          onClick={e => {
                            this.setState({
                              expertId: this.props.psychologist._id,
                              doctorName: this.props.psychologist.name,
                            })
                            this.toggleExpert(e)
                          }}>
                          Change My Experts
                        </div>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className='cpl-wrapper-outer'>
                {this.props.psychiatrist && this.props.psychiatrist._id ? (
                  <div
                    onClick={e => {
                      this.setState({ dropDown: false })
                      if (this.props.psychiatrist._id !== this.state.selectedDr)
                        this.setState({
                          selectedDr: this.props.psychiatrist._id,
                        })
                      this.props.setDoctorId(this.props.psychiatrist._id)
                      this.props.setAapointment_id(
                        this.props.psychiatristAppointment._id ||
                          this.props.patientData.prevPsychiatristAppointment._id
                      )
                    }}
                    className={
                      this.state.selectedDr === this.props.psychiatrist._id
                        ? 'cpl-wrapper-box d-flex flex-column add-shape'
                        : 'cpl-wrapper-box d-flex flex-column add-bg'
                    }>
                    <div className='cpl-head d-flex align-items-center justify-content-between'>
                      <h3>My Psychiatrist</h3>
                      <div className='dropright'>
                        {!newDoctor && (
                          <a
                            className='dropdown-toggle'
                            href='#'
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              this.props.setDoctorId(
                                this.props.psychiatrist._id
                              )
                              this.props.setDropDown(!this.props.dropDown)
                              this.setState({
                                selectedDr: this.props.psychiatrist._id,
                                dropDown: true,
                              })
                            }}
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'>
                            <img src={dotsImg} alt='' />
                          </a>
                        )}
                        <div
                          style={
                            isMobile
                              ? {
                                  zIndex: 99999999999,
                                  position: 'absolute',
                                  transform: 'translate3d(-173px, 0px, 0px)',
                                  top: '0px',
                                  left: '0px',
                                  willChange: 'transform',
                                }
                              : {}
                          }
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              dropDown: false,
                            })
                          }}
                          className={
                            this.props.dropDown &&
                            this.props.doctor_id === this.props.psychiatrist._id
                              ? 'dropdown-menu dropdown-custom show'
                              : 'dropdown-menu dropdown-custom'
                          }
                          x-placement={isMobile ? 'left-start' : 'right-start'}>
                          <Link
                            className='dropdown-item'
                            to='/patient/dashboard-timeline'>
                            Past Records
                          </Link>
                          {this.props.psychiatristAppointment &&
                            moment(
                              this.props.psychiatristAppointment
                                .appointment_time
                            )
                              .subtract({ hours: 5, minutes: 30 })
                              .diff(moment(), 'minutes') >
                              this.props.constants
                                .appointment_cancellation_allowed_before && (
                              <a
                                className='dropdown-item'
                                href='#!'
                                onClick={e => {
                                  this.setState({
                                    appointId:
                                      this.props.psychiatristAppointment._id,
                                    userName: this.props.psychiatrist.user_name,
                                    appointment_type:
                                      this.props.psychiatristAppointment
                                        .appointment_type,
                                  })
                                  this.toggle(e)
                                }}>
                                Modify Appointment{' '}
                              </a>
                            )}
                          <a
                            className='dropdown-item'
                            href='#!'
                            onClick={e => {
                              this.setState({
                                expertId: this.props.psychiatrist._id,
                                doctorName: this.props.psychiatrist.name,
                              })
                              this.toggleExpert(e)
                            }}>
                            Change My Experts
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='cpl-box d-flex flex-wrap'>
                      <div className='cpl-img has-active'>
                        <img
                          src={
                            process.env.REACT_APP_S3URL +
                            this.props.psychiatrist.image_url
                          }
                          alt=''
                        />
                      </div>
                      <div className='cpl-text-box'>
                        <h5>{this.props.psychiatrist.name}</h5>
                        <p>
                          {this.props.psychiatrist.graduation &&
                            this.props.psychiatrist.graduation.education}
                          {this.props.psychiatrist.post_graduation &&
                            ', ' +
                              this.props.psychiatrist.post_graduation.education}
                          {this.props.psychiatrist.super_speciality &&
                            ', ' +
                              this.props.psychiatrist.super_speciality
                                .education}
                        </p>
                      </div>
                    </div>
                    <div className='cpl-box'>
                      {this.props.psychiatristAppointment.appointment_time &&
                      moment(
                        this.props.psychiatristAppointment.appointment_time
                      ).utc() > moment() ? (
                        <div class='row'>
                          <div className='cpl-intro-desc col-8'>
                            <div class='d-flex'>
                              <p>Upcoming appointment</p>
                              <i
                                data-tip='Join call icon and Prescription will appear in this area.'
                                class='fas fa-info-circle ml-1 mt-1'
                                aria-hidden='true'
                                style={{
                                  color: 'rgb(24 0 88 / 50%)',
                                  fontSize: '12px',
                                }}></i>
                            </div>
                            <p>
                              On{' '}
                              <strong>
                                {this.props.psychiatristAppointment &&
                                moment(
                                  this.props.psychiatristAppointment &&
                                    this.props.psychiatristAppointment
                                      .appointment_time
                                )
                                  .utc()
                                  .format('DD-MM-YYYY') ===
                                  moment().format('DD-MM-YYYY')
                                  ? 'Today'
                                  : moment(
                                      this.props.psychiatristAppointment
                                        .appointment_time
                                    )
                                      .utc()
                                      .format('DD-MM-YYYY')}
                              </strong>{' '}
                              at{' '}
                              <strong>
                                {this.props.psychiatristAppointment &&
                                  moment(
                                    this.props.psychiatristAppointment
                                      .appointment_time
                                  )
                                    .utc()
                                    .format('hh:mm A')}
                                {''}
                              </strong>
                            </p>
                          </div>

                          {this.props.psychiatristAppointment
                            .appointment_type === 'online' && (
                            <a
                              onClick={e => {
                                if (
                                  this.props.psychiatristAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychiatristAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                ) {
                                  this.onCall(
                                    e,
                                    this.props.psychiatristAppointment._id
                                  )
                                }
                              }}
                              data-tip={
                                this.props.psychiatristAppointment
                                  .appointment_type === 'online' &&
                                moment().diff(
                                  moment(
                                    this.props.psychiatristAppointment
                                      .appointment_time
                                  ).subtract({ hours: 5, minutes: 30 }),
                                  'minutes'
                                ) > -10
                                  ? ''
                                  : 'Join will be active 10 minutes before appointment'
                              }
                              className='btn btn-blue'
                              style={{
                                cursor:
                                  this.props.psychiatristAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychiatristAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                    ? 'pointer'
                                    : 'initial',
                                minWidth: '0px',
                                height: '50px',
                              }}>
                              Join Call
                            </a>
                          )}
                        </div>
                      ) : (
                        <>
                          {this.props.patientData.prevPsychiatristAppointment &&
                          this.props.patientData.prevPsychiatristAppointment
                            ._id ? (
                            <>
                              <div className='cpl-intro-desc'>
                                Prescription available
                              </div>
                              <div
                                class='cpl-box'
                                onClick={e =>
                                  console.log(
                                    this.props.patientData
                                      .prevPsychiatristAppointment._id
                                  )
                                }>
                                <a
                                  href=''
                                  className='np-links-pres'
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.toggle2(
                                      this.props.patientData
                                        .prevPsychiatristAppointment._id
                                    )
                                  }}
                                  style={{ cursor: 'pointer !important' }}>
                                  <img src={calculatorimg} alt='' />
                                </a>
                              </div>
                            </>
                          ) : (
                            <em>
                              “A Psychiatrist interviews, diagnose, prescribes
                              and has all round medical expertise with
                              capability to make you feel better at the
                              earliest. ”
                            </em>
                          )}
                        </>
                      )}
                    </div>

                    {moment(
                      this.props.psychiatristAppointment.appointment_time
                    ).utc() > moment() ? (
                      <div className='cpl-box'>
                        <p>You have tasks remaining</p>
                        <Link
                          to='/patient/dashboard/pre-Appointment'
                          className='btn btn-blue-outline btn-block'
                          onClick={() => {
                            if (isMobile) {
                              window.scrollTo({
                                top: 1000,
                                behavior: 'smooth',
                              })
                            }
                          }}>
                          Complete Pre-Appointment
                        </Link>
                      </div>
                    ) : (
                      <div class='cpl-box'>
                        <p>
                          <small>You don’t have an appointment.</small>
                        </p>
                        <Link
                          to={{
                            pathname: '/patient/doctors',
                            state: { field_type: 'Psychiatrist' },
                          }}
                          class='btn btn-default no-shadow btn-block'>
                          Book an Appointment
                        </Link>
                      </div>
                    )}
                    {newDoctor && (
                      <>
                        <div className='pb-4'>
                          If you want to book an appointment please change your
                          expert
                        </div>
                        <div
                          class='btn btn-default no-shadow btn-block'
                          onClick={e => {
                            this.setState({
                              expertId: this.props.psychiatrist._id,
                              doctorName: this.props.psychiatrist.name,
                            })
                            this.toggleExpert(e)
                          }}>
                          Change My Experts
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className='cpl-wrapper-box d-flex flex-column add-bg'>
                    <div className='cpl-head d-flex align-items-center justify-content-between'>
                      <h2>My Psychiatrist</h2>
                      <a href='#!'>
                        <img
                          src='include/images/ios-arrow-round-forward.svg'
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='cpl-box'>
                      <p>You don’t have a Psychiatrist yet.</p>
                    </div>
                    <div className='cpl-box'>
                      <em>
                        “A Psychiatrist interviews, diagnose, prescribes and has
                        all round medical expertise with capability to make you
                        feel better at the earliest. ”
                      </em>
                    </div>
                    <div className='cpl-box'>
                      <p>
                        <small>Book appointment with our top specialists</small>
                      </p>
                      <Link
                        to={{
                          pathname: '/patient/doctors',
                          state: { field_type: 'Psychiatrist' },
                        }}
                        className='btn btn-default no-shadow btn-block'>
                        Find a Psychiatrist
                      </Link>
                    </div>
                  </div>
                )}

                {this.props.psychologist && this.props.psychologist._id ? (
                  <div
                    onClick={e => {
                      this.setState({ dropDown: false })
                      if (this.props.psychologist._id !== this.state.selectedDr)
                        this.setState({
                          selectedDr: this.props.psychologist._id,
                        })
                      this.props.setDoctorId(this.props.psychologist._id)
                      this.props.setAapointment_id(
                        this.props.psychologistAppointment._id ||
                          this.props.patientData.prevPsychologistAppointment._id
                      )
                    }}
                    className={
                      this.state.selectedDr === this.props.psychologist._id
                        ? 'cpl-wrapper-box d-flex flex-column add-shape'
                        : 'cpl-wrapper-box d-flex flex-column add-bg'
                    }>
                    <div className='cpl-head d-flex align-items-center justify-content-between'>
                      <h3>My Psychologist</h3>
                      <div className='dropright'>
                        <a
                          className='dropdown-toggle'
                          href='#'
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            this.props.setDropDown(!this.props.dropDown)
                            this.setState({
                              selectedDr: this.props.psychologist._id,
                              dropDown: true,
                            })
                            this.props.setDoctorId(this.props.psychologist._id)
                          }}
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'>
                          <img src={dotsImg} alt='' />
                        </a>
                        <div
                          style={
                            isMobile
                              ? {
                                  zIndex: 99999999999,
                                  position: 'absolute',
                                  transform: 'translate3d(-173px, 0px, 0px)',
                                  top: '0px',
                                  left: '0px',
                                  willChange: 'transform',
                                }
                              : {}
                          }
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              dropDown: false,
                            })
                          }}
                          className={
                            this.props.dropDown &&
                            this.props.doctor_id === this.props.psychologist._id
                              ? 'dropdown-menu dropdown-custom show'
                              : 'dropdown-menu dropdown-custom'
                          }
                          x-placement={isMobile ? 'left-start' : 'right-start'}>
                          <Link
                            className='dropdown-item'
                            to='/patient/dashboard-timeline'>
                            Past Records{' '}
                          </Link>
                          {this.props.psychologistAppointment &&
                            moment(
                              this.props.psychologistAppointment
                                .appointment_time
                            )
                              .subtract({ hours: 5, minutes: 30 })
                              .diff(moment(), 'minutes') >
                              parseInt(
                                this.props.constants
                                  .appointment_cancellation_allowed_before
                              ) && (
                              <a
                                className='dropdown-item'
                                href='#!'
                                onClick={e => {
                                  this.setState({
                                    appointId:
                                      this.props.psychologistAppointment._id,
                                    userName: this.props.psychologist.user_name,
                                    appointment_type:
                                      this.props.psychologistAppointment
                                        .appointment_type,
                                  })
                                  this.toggle(e)
                                }}>
                                Modify Appointment
                              </a>
                            )}
                          <a
                            className='dropdown-item'
                            href='#!'
                            onClick={e => {
                              this.setState({
                                expertId: this.props.psychologist._id,
                                doctorName: this.props.psychologist.name,
                              })
                              this.toggleExpert(e)
                            }}>
                            Change My Experts
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='cpl-box d-flex flex-wrap'>
                      <div className='cpl-img has-active'>
                        <img
                          src={
                            process.env.REACT_APP_S3URL +
                            this.props.psychologist.image_url
                          }
                          alt=''
                        />
                      </div>
                      <div className='cpl-text-box'>
                        <h5>{this.props.psychologist.name}</h5>

                        <p>
                          {this.props.psychologist.graduation &&
                            this.props.psychologist.graduation.education}
                          {this.props.psychologist.post_graduation &&
                            ', ' +
                              this.props.psychologist.post_graduation.education}
                          {this.props.psychologist.super_speciality &&
                            ', ' +
                              this.props.psychologist.super_speciality
                                .education}
                        </p>
                      </div>
                    </div>
                    <div className='cpl-box'>
                      {moment(
                        this.props.psychologistAppointment.appointment_time
                      ).utc() > moment() ? (
                        <div class='row'>
                          <div className='cpl-intro-desc col-8'>
                            <div class='d-flex'>
                              <p>Upcoming appointment</p>
                              <i
                                data-tip='Join call icon and Prescription will appear in this area.'
                                class='fas fa-info-circle ml-1 mt-1'
                                aria-hidden='true'
                                style={{
                                  color: 'rgb(24 0 88 / 50%)',
                                  fontSize: '12px',
                                }}></i>
                            </div>
                            <p>
                              On{' '}
                              <strong>
                                {moment(
                                  this.props.psychologistAppointment
                                    .appointment_time
                                )
                                  .utc()
                                  .format('DD-MM-YYYY') ===
                                moment().format('DD-MM-YYYY')
                                  ? 'Today'
                                  : moment(
                                      this.props.psychologistAppointment
                                        .appointment_time
                                    )
                                      .utc()
                                      .format('DD-MM-YYYY')}
                              </strong>{' '}
                              at{' '}
                              <strong>
                                {moment(
                                  this.props.psychologistAppointment
                                    .appointment_time
                                )
                                  .utc()
                                  .format('hh:mm A')}{' '}
                              </strong>
                            </p>
                          </div>

                          {this.props.psychologistAppointment
                            .appointment_type === 'online' && (
                            <a
                              onClick={e => {
                                if (
                                  this.props.psychologistAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychologistAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                ) {
                                  this.onCall(
                                    e,
                                    this.props.psychologistAppointment._id
                                  )
                                }
                              }}
                              data-tip={
                                this.props.psychologistAppointment
                                  .appointment_type === 'online' &&
                                moment().diff(
                                  moment(
                                    this.props.psychologistAppointment
                                      .appointment_time
                                  ).subtract({ hours: 5, minutes: 30 }),
                                  'minutes'
                                ) > -10
                                  ? ''
                                  : 'Join will be active 10 minutes before appointment'
                              }
                              className='btn btn-blue'
                              style={{
                                cursor:
                                  this.props.psychologistAppointment
                                    .appointment_type === 'online' &&
                                  moment().diff(
                                    moment(
                                      this.props.psychologistAppointment
                                        .appointment_time
                                    ).subtract({ hours: 5, minutes: 30 }),
                                    'minutes'
                                  ) > -10
                                    ? 'pointer'
                                    : 'initial',
                                minWidth: '0px',
                                height: '50px',
                              }}>
                              {' '}
                              Join Call
                            </a>
                          )}
                        </div>
                      ) : (
                        <>
                          {this.props.patientData.prevPsychologistAppointment &&
                          this.props.patientData.prevPsychologistAppointment
                            ._id ? (
                            <>
                              <div className='cpl-intro-desc'>
                                Prescription available
                              </div>
                              <div
                                class='cpl-box'
                                onClick={e =>
                                  console.log(
                                    this.props.patientData
                                      .prevPsychologistAppointment._id
                                  )
                                }>
                                <a
                                  href=''
                                  className='np-links-pres'
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.toggle2(
                                      this.props.patientData
                                        .prevPsychologistAppointment._id
                                    )
                                  }}
                                  style={{ cursor: 'pointer !important' }}>
                                  <img src={calculatorimg} alt='' />
                                </a>
                              </div>
                            </>
                          ) : (
                            <em>
                              “A psychologist can help you unwind your mind in
                              ways you can’t imagine…”
                            </em>
                          )}
                        </>
                      )}
                    </div>
                    {moment(
                      this.props.psychologistAppointment.appointment_time
                    ).utc() > moment() ? (
                      <div className='cpl-box'>
                        <p>You have tasks remaining</p>
                        <Link
                          to='/patient/dashboard/pre-Appointment'
                          className='btn btn-blue-outline btn-block'
                          onClick={() => {
                            if (isMobile) {
                              window.scrollTo({
                                top: 1000,
                                behavior: 'smooth',
                              })
                            }
                          }}>
                          Complete Pre-Appointment
                        </Link>
                      </div>
                    ) : (
                      <div class='cpl-box'>
                        <p>
                          <small>You don’t have an appointment.</small>
                        </p>
                        <Link
                          to={{
                            pathname: '/patient/doctors',
                            state: { field_type: 'Psychologist' },
                          }}
                          class='btn btn-default no-shadow btn-block'>
                          Book an Appointment
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='cpl-wrapper-box d-flex flex-column add-bg'>
                    <div className='cpl-head d-flex align-items-center justify-content-between'>
                      <h2>My Psychologist</h2>
                      <a href='#!'>
                        <img
                          src='include/images/ios-arrow-round-forward.svg'
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='cpl-box'>
                      <p>You don’t have a Psychologist yet.</p>
                    </div>
                    <div className='cpl-box'>
                      <em>
                        “A Psychologist can help you unwind your mind in ways
                        you can’t imagine…”
                      </em>
                    </div>
                    <div className='cpl-box'>
                      <p>
                        <small>Book appointment with our top specialists</small>
                      </p>
                      <Link
                        to={{
                          pathname: '/patient/doctors',
                          state: { field_type: 'Psychologist' },
                        }}
                        className='btn btn-default no-shadow btn-block'>
                        Find a Psychologist
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {this.state.popUpExpert && (
            <Modal
              dialogClassName='modal-dialog modal-dialog-centered'
              className='modal fade custom-modal custom-modal2 show'
              show={this.state.popUpExpert}
              onHide={this.toggleExpert}>
              <div class='modal-body'>
                <h6>Change My Expert</h6>
                <h5>
                  Changing your mental health professional will drop out{' '}
                  {this.state.doctorName} from your expert panel.
                </h5>
                <p>
                  We would just like to point out that it takes time, patience
                  and trust on your team for treatment to be effective. After
                  all it’s a journey. There are many advantages to long term
                  association with your initial experts. You can express about
                  what is not working out for you. But, we understand sometimes
                  it doesn’t fit right somehow.
                </p>
                <p>
                  Please cancel any future appointment that you have with the
                  expert
                </p>
                <h4>Do you wish to continue?</h4>
                <div class='modal-coustom-btn d-flex align-items-center justify-content-center'>
                  <a
                    href='#!'
                    class='btn btn-primary'
                    onClick={e => {
                      this.toggleExpert2(e)
                      this.toggleExpert(e)
                    }}>
                    Go ahead &amp; change my expert
                  </a>
                  <a
                    href='#!'
                    class='btn btn-outline no-shadow'
                    onClick={this.toggleExpert}>
                    Cancel
                  </a>
                </div>
              </div>
            </Modal>
          )}

          <Modal
            dialogClassName='modal-dialog modal-dialog-centered'
            className='modal fade custom-modal custom-modal2 show'
            show={this.state.popUpExpert2}
            onHide={this.toggleExpert2}>
            <div class='modal-body'>
              <h6>We value your feedback</h6>
              <div class='form-group'>
                <select
                  name='cars'
                  id='cars'
                  className='form-control'
                  name='reason_title'
                  onChange={this.onChangeInput}
                  value={this.state.data.reason_title}>
                  <option value=''>Select Reason</option>
                  <option value='Consultation duration'>
                    Consultation duration
                  </option>
                  <option value='Appointment comfort'>
                    Appointment comfort
                  </option>
                  <option value='Treatment effectiveness'>
                    Treatment effectiveness
                  </option>
                  <option value='Query clarifications'>
                    Query clarifications
                  </option>
                  <option value='Rapport/ Comfort'>Rapport/ Comfort</option>
                </select>
              </div>{' '}
              <div class='form-group'>
                <input
                  value={this.state.data.reason_text}
                  onChange={this.onChangeInput}
                  type='text'
                  name='reason_text'
                  className='form-control'
                  placeHolder='Reason detail'
                />
              </div>
              <div class='modal-coustom-btn d-flex align-items-center justify-content-center'>
                <a
                  href='#!'
                  class='btn btn-primary'
                  onClick={e => {
                    this.doSubmitRemove(e)
                  }}>
                  Submit
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            className='modal fade custom-modal'
            show={this.state.cancelConfirm}
            onHide={e => this.setState({ cancelConfirm: false })}>
            <div class='modal-body'>
              <h6>Cancel Appointment</h6>
              <p>Are you sure you want to cancel the appointment?</p>{' '}
              <div class='custom-modal-btn'>
                <a
                  disabled={this.state.loader}
                  href='#!'
                  class={
                    this.state.loader
                      ? 'btn btn-primary btn-block disabled'
                      : 'btn btn-primary btn-block'
                  }
                  onClick={e =>
                    this.cancellAppointment(e, this.state.appointId)
                  }>
                  Yes
                </a>
                <a
                  href='#!'
                  class='btn-text'
                  onClick={e => this.setState({ cancelConfirm: false })}>
                  Don’t Cancel
                </a>
              </div>
            </div>
          </Modal>

          <Modal
            className='modal fade custom-modal'
            show={this.state.popUp}
            onHide={this.toggle}>
            <div class='modal-body'>
              <h6>Modify Appointment?</h6>
              <p>Do you wish to cancel or reschedule your appointment</p>
              <div class='custom-modal-btn'>
                <a
                  disabled={this.state.loader}
                  href='#!'
                  class={
                    this.state.loader
                      ? 'btn btn-primary btn-block disabled'
                      : 'btn btn-primary btn-block'
                  }
                  onClick={e => {
                    this.setState({ cancelConfirm: true })
                    //this.cancellAppointment(e, this.state.appointId)
                  }}>
                  Cancel Appointment
                </a>
                <a
                  onClick={e => this.reSchule(e, this.state.appointId)}
                  href='#!'
                  class='btn btn-outline no-shadow btn-block'>
                  Reschedule Appointment
                </a>
                <a href='#!' class='btn-text' onClick={this.toggle}>
                  Don’t Modify
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            aria-labelledby='contained-modal-title-vcenter'
            centered
            dialogClassName='modal-dialog modal-dialog-centered mt-0'
            className='modal fade personal-details-modal show'
            show={this.state.formSuccessModel}
            onHide={this.toggleFormSuccess}>
            <div class='text-center'>
              <div class='modal-body'>
                <div class='pdm-icon'>
                  <img src={circle} alt='' />
                </div>
                <h5></h5>
                <p>
                  Your request for changing the expert has been registered. It
                  might take at-least 24 hours to process your request.
                </p>
                <a
                  href='javascript:void(0)'
                  onClick={e =>
                    this.setState({
                      popUp: false,
                      popUpExpert: false,
                      popUpExpert2: false,
                      formSuccessModel: false,
                    })
                  }
                  class='btn btn-default no-shadow'>
                  CLOSE
                </a>
              </div>
            </div>
          </Modal>
          <Prescription
            isShow={this.state.isShow2}
            toggle={this.toggle2}
            appointment_id={this.state.appointment_id}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  psychiatrist: getPatient(state).psychiatrist,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  patientData: getPatient(state),
  constants: getMisc(state).constants,
  loading: getPatient(state).loading,
  getAppointment: getAppointment(state),
})
const mapDispatchToProps = dispatch => ({
  loadConstant: () => dispatch(loadConstant()),
  loadDate: () => dispatch(loadDate()),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  cancellAppointment: (id, callBack) =>
    dispatch(cancellAppointment(id, callBack)),
  loadAppointmentDetailPatient: (id, callBack) =>
    dispatch(loadAppointmentDetailPatient(id, callBack)),

  removeRequest: (data, callBack) => dispatch(removeRequest(data, callBack)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DoctorSelection)
)
