import React, { Component } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { connect } from 'react-redux'
import DashboardHeader from '../../profile/common/dashboardHeader'
import DashboardLeftBar from '../../profile/common/dashboardLeftBar'
import MyJourney from './myJourney'
import MyReport from './myReport'
import Task from './task'
import { withRouter } from 'react-router-dom'

class Timeline extends Component {
  state = {
    step: 1,
  }

  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
  }
  componentDidMount = () => {
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
  }
  componentWillUnmount = () => {
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }
  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return <MyJourney updateStep={this.updateStep} step={this.state.step} />
      case 2:
        return <MyReport timeline={true} />
      case 3:
        return <Task updateStep={this.updateStep} step={this.state.step} />

      default:
        break
    }
  }

  render() {
    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <DashboardLeftBar updateStep={this.updateStep} />
        <DashboardHeader />
        <div className='dashboard-main-container'>
          <div className='timeline-box'>
            <h4>My Timeline</h4>
            <div className='chatbot-box'>
              <div className='chatbot-head d-none d-md-block'>
                <ul
                  className='nav nav-tabs nav-tabs2'
                  id='myTab'
                  role='tablist'>
                  <li
                    className='nav-item'
                    role='presentation'
                    style={{ cursor: 'pointer' }}>
                    <a
                      className={
                        this.state.step === 1 ? 'nav-link active' : 'nav-link'
                      }
                      data-toggle='tab'
                      role='tab'
                      aria-controls='myjourney'
                      aria-selected='true'
                      onClick={e => this.updateStep(null, 1)}>
                      <b>My Journey</b>
                      <span>My Journey</span>
                      <i
                        data-tip='Timeline and details of individual appointment.'
                        class='fas fa-info-circle ml-3'
                        aria-hidden='true'
                        style={{
                          color: 'rgb(24 0 88 / 50%)',
                          fontSize: '12px',
                        }}></i>
                    </a>
                  </li>
                  <li
                    className='nav-item'
                    role='presentation'
                    style={{ cursor: 'pointer' }}>
                    <a
                      className={
                        this.state.step === 2 ? 'nav-link active' : 'nav-link'
                      }
                      data-toggle='tab'
                      role='tab'
                      aria-controls='myreport'
                      aria-selected='true'
                      onClick={e => this.updateStep(null, 2)}>
                      <b>My Reports</b>
                      <span>My Reports</span>
                      <i
                        data-tip=' All the reports uploaded by you and experts at one place.'
                        class='fas fa-info-circle ml-3'
                        aria-hidden='true'
                        style={{
                          color: 'rgb(24 0 88 / 50%)',
                          fontSize: '12px',
                        }}></i>
                    </a>
                  </li>
                  <li
                    className='nav-item'
                    role='presentation'
                    style={{ cursor: 'pointer' }}>
                    <a
                      className={
                        this.state.step === 3 ? 'nav-link active' : 'nav-link'
                      }
                      id='tasks-tab'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='tasks'
                      aria-selected='false'
                      onClick={e => this.updateStep(null, 3)}>
                      <b>Tasks</b>
                      <span>Tasks</span>
                      <i
                        data-tip='Tasks (homework, assessment, worksheets) allotted and completed will be displayed here.'
                        class='fas fa-info-circle ml-3'
                        aria-hidden='true'
                        style={{
                          color: 'rgb(24 0 88 / 50%)',
                          fontSize: '12px',
                        }}></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='chatbot-body'>
                <div className='tab-content' id='myTabContent'>
                  <div
                    class={
                      this.state.step === 1
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id='allpatients'
                    role='tabpanel'
                    aria-labelledby='allpatients-tab'>
                    <div
                      class='card-header'
                      onClick={e => this.updateStep(null, 1)}>
                      My Journey
                    </div>
                    <div
                      className={
                        this.state.step === 1
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}>
                      {' '}
                      {this.state.step === 1 && this.leftSideStep()}
                    </div>
                  </div>
                  <div
                    class={
                      this.state.step === 2
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id='allpatients'
                    role='tabpanel'
                    aria-labelledby='allpatients-tab'>
                    <div
                      class='card-header'
                      onClick={e => this.updateStep(null, 2)}>
                      My Reports
                    </div>
                    <div
                      className={
                        this.state.step === 2
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}>
                      {' '}
                      {this.state.step === 2 && this.leftSideStep()}
                    </div>
                  </div>
                  <div
                    class={
                      this.state.step === 3
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id='allpatients'
                    role='tabpanel'
                    aria-labelledby='allpatients-tab'>
                    <div
                      class='card-header'
                      onClick={e => this.updateStep(null, 3)}>
                      Tasks
                    </div>
                    <div
                      className={
                        this.state.step === 3
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}>
                      {' '}
                      {this.state.step === 3 && this.leftSideStep()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(connect(null)(Timeline))
