import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
import { createAction } from '@reduxjs/toolkit'

//import moment from "moment";

const slice = createSlice({
  name: 'patient',
  initialState: {
    signup: {
      name: '',
      value: '',
    },
    patientList: [],
    caseRecord: {},
    patient: {},
    history: {},
    psychiatrist: {},
    psychiatristAppointment: {},
    prevPsychiatristAppointment: {},
    lastFollowupNotes: {},
    psychologist: {},
    patientDetail: {},
    psychologistAppointment: {},
    prevPsychologistAppointment: {},
    profile: {},
    lastFollowup: {},
    lastPrescription: {},
    loading: false,
    lastFetch: null,
    initial: true,
    showSuccess: false,
  },
  reducers: {
    setInitial: (patient, action) => {
      patient.initial = action.payload
    },
    setSignup: (patient, action) => {
      patient.signup = action.payload
    },
    setShowSuccess: (patient, action) => {
      patient.showSuccess = action.payload
    },
    patientListRequested: patient => {
      patient.listLoading = true
    },
    patientListRecieved: (patient, action) => {
      patient.listLoading = false
      patient.patientList = action.payload.data
    },
    patientListFailed: patient => {
      patient.listLoading = false
    },
    patientDetailRequested: patient => {
      patient.detailLoading = true
    },
    patientDetailRecieved: (patient, action) => {
      patient.detailLoading = false
      patient.patientDetail = action.payload
    },
    patientDetailFailed: patient => {
      patient.detailLoading = false
    },

    profileRequested: patient => {
      patient.loading = true
    },
    profileReceived: (patient, action) => {
      patient.profile = action.payload.data
      patient.history = action.payload.patientHistory
      patient.lastFollowupNotes = action.payload.lastFollowupNotes
      patient.prevPsychiatristAppointment =
        action.payload.prevPsychiatristAppointment
      patient.prevPsychologistAppointment =
        action.payload.prevPsychologistAppointment
      patient.psychiatrist = action.payload.psychiatrist
      patient.psychiatristAppointment = action.payload.psychiatristAppointment
        ? action.payload.psychiatristAppointment
        : {}
      patient.caseRecord = action.payload.caseRecord
        ? action.payload.caseRecord
        : {}

      patient.lastFollowup = action.payload.lastFollowup
        ? action.payload.lastFollowup
        : {}
      patient.lastPrescription = action.payload.lastPrescription
        ? action.payload.lastPrescription
        : {}
      patient.prevPsychologistPrescription = action.payload
        .prevPsychologistPrescription
        ? action.payload.prevPsychologistPrescription
        : {}
      patient.prevPsychiatristPrescription = action.payload
        .prevPsychiatristPrescription
        ? action.payload.prevPsychiatristPrescription
        : {}
      patient.psychologistAppointment = action.payload.psychologistAppointment
      patient.psychologist = action.payload.psychologist
      patient.loading = false
      patient.lastFetch = Date.now()
    },
    profileRequestFailed: patient => {
      patient.loading = false
      patient.profile = {}
    },
  },
})

export const {
  profileRequested,
  profileReceived,
  profileRequestFailed,
  patientListRequested,
  patientListRecieved,
  patientListFailed,
  patientDetailRequested,
  patientDetailRecieved,
  patientDetailFailed,
  setInitial,
  setShowSuccess,
  setSignup,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'patient/'
const otpUrl = 'otp/'

export const addExistingPatientByDoctor =
  (data, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url + 'addExistingPatientByDoctor',
        method: 'POST',
        data,
        callback,
      })
    )
  }

export const mobileAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'mobileAvailability',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const patientAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'patientAvailability',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const emailAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'emailAvailability',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const checkOtp = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + 'verifyOTP',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const sendOtp = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + 'requestOTP',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const patientSignUp = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'signUp',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const mobileSignUp =
  (data, callback = null, urlName = '') =>
  dispatch => {
    return dispatch(
      apiCallBegan({
        url: url + (urlName ? urlName : 'mobileSignUp'),
        method: 'POST',
        data,
        callback,
      })
    )
  }

export const addPatient = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'addPatient',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const loadPatientProfile = callback => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'me',
      method: 'GET',
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
      callback,
    })
  )
}

export const updatePatientProfile = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'me',
      method: 'PUT',
      data,
      callback,
    })
  )
}
export const updatePatientProfileByDoctor =
  (data, id, callback) => dispatch => {
    return dispatch(
      apiCallBegan({
        url: url + 'doctor/update-patient/' + id,
        method: 'PUT',
        data,
        callback,
      })
    )
  }
export const updatePatientHistory = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'history',
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const resetPassword = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'resetPassword',
      method: 'PUT',
      data,
      callback,
    })
  )
}
export const patientSignInExternal = (id, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'signIn/' + id,
      method: 'POST',
      callback,
    })
  )
}
export const patientSignIn = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'signIn',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const patientSignInOtp = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'signInOtp',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const patientSearch = params => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'list/forDoctor',
      method: 'GET',
      params,
      onStart: patientListRequested.type,
      onSuccess: patientListRecieved.type,
      onError: patientListFailed.type,
    })
  )
}

export const setInitialData = val => dispatch => {
  return dispatch({ type: setInitial.type, payload: val })
}

export const setSignupData = val => dispatch => {
  return dispatch({ type: setSignup.type, payload: val })
}

export const setShowSuccessData = val => dispatch => {
  return dispatch({ type: setShowSuccess.type, payload: val })
}

export const patientDetail =
  (id, addPatient = false) =>
  dispatch => {
    return dispatch(
      apiCallBegan({
        url: url + 'details/forDoctor/' + id,
        method: 'GET',
        onStart: addPatient
          ? profileRequested.type
          : patientDetailRequested.type,
        onSuccess: addPatient
          ? profileReceived.type
          : patientDetailRecieved.type,
        onError: addPatient
          ? profileRequestFailed.type
          : patientDetailFailed.type,
      })
    )
  }
export const removeRequest = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'doctor-remove-request',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const updatePartner = (id, data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'updatePartner/' + id,
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const getPatient = createSelector(
  state => state.entities.patient,
  patient => patient
)
