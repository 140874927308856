import React, { Component } from 'react'
import sendIcon from '../../../../../include/images/send-icon.svg'
import { io } from 'socket.io-client'
import { getPatient, loadPatientProfile } from '../../../../../store/patient'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  chatReceived,
  getChats,
  loadChatForPatient,
} from '../../../../../store/chats'
import msgIcon from '../../../../../include/images/tab-message.svg'
import moment from 'moment'

class Messeges extends Component {
  myRef = React.createRef()
  scrollToMyRef = () => {
    if (this.myRef.current) {
      const scroll =
        this.myRef.current.scrollHeight - this.myRef.current.clientHeight
      this.myRef.current.scrollTo(0, scroll)
    }
  }
  state = {
    ready: false,
    msgData: '',
  }
  sendMessege = msg => {
    if (msg) {
      this.socket.emit('message', {
        msg: msg,
        sender: this.props.patient_id,
        reciever: this.props.doctor_id,
        created_on: moment().format(),
      })
      this.setState({ msgData: '' })
      this.recievedMsg({
        msg: msg,
        sender: this.props.patient_id,
        reciever: this.props.doctor_id,
        created_on: moment().format(),
      })
    }
    this.scrollToMyRef()
  }

  recievedMsg = msg => {
    this.props.chatReceived(msg)
    this.scrollToMyRef()
  }

  componentWillUnmount = () => {
    this.socket.disconnect()
  }

  socket
  componentDidUpdate = (prevProps, prevState) => {
    this.scrollToMyRef()
    if (this.props.doctor_id !== prevProps.doctor_id) {
      this.props.loadChatForPatient(this.props.doctor_id)
    }
    if (this.props.tab === 'messages' && this.props.tab !== prevProps.tab) {
      this.props.loadChatForPatient(this.props.doctor_id)
    }
  }
  // componentDidUpdate = (prevProps, prevState) => {
  //   this.scrollToMyRef();
  //   if (prevProps.doctor_id != this.props.doctor_id) {
  //     const _id = this.props.doctor_id;
  //     this.socket = io(process.env.REACT_APP_SOCKET_URL, {
  //       autoConnect: false,
  //       upgrade: false,
  //       transports: ["websocket"],
  //       query: {
  //         _id: _id,
  //       },
  //     });
  //     this.socket.on("connect", () => {
  //       this.socket.on("message", (message) => {
  //         this.recievedMsg(message);
  //       });
  //       this.socket.off("message", this.recievedMsg);
  //     });
  //     this.socket.connect();
  //     this.socket.on("disconnect", () => {
  //       this.socket.removeAllListeners();
  //     });
  //   }
  // };

  componentDidMount = () => {
    this.scrollToMyRef()
    this.props.loadChatForPatient(this.props.doctor_id)
    if (this.props.patient_id) {
      const _id = this.props.patient_id
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        autoConnect: false,
        upgrade: false,
        transports: ['websocket'],
        query: {
          _id: _id,
        },
      })
      this.socket.on('connect', () => {
        this.socket.on('message', message => {
          this.recievedMsg(message)
        })
        this.socket.off('message', this.recievedMsg)
      })
      this.socket.connect()
      this.socket.on('disconnect', () => {
        this.socket.removeAllListeners()
      })
    }
  }

  render() {
    const days = moment().diff(
      moment(this.props.lastAppointment.appointment_time),
      'days'
    )
    this.scrollToMyRef()

    return (
      <>
        <div
          onClick={e => this.props.setTab('messages', e)}
          className={
            this.props.tab === 'messages' ? 'card-header active' : 'card-header'
          }>
          <img src={msgIcon} alt='' />
          <b>Messages</b>
        </div>
        <div
          className={
            this.props.tab === 'messages' ? 'content-body show' : 'content-body'
          }>
          <div className='chat-area'>
            <div className='chat' ref={this.myRef}>
              {this.props.chats.map((es, i) => (
                <>
                  {(i === 0 ||
                    moment(this.props.chats[i ? i - 1 : 0].created_on).format(
                      'DD/MM/YYYY'
                    ) !== moment(es.created_on).format('DD/MM/YYYY')) && (
                    <div class='chat-seprator'>
                      <span> {moment(es.created_on).format('DD/MM/YYYY')}</span>
                    </div>
                  )}
                  {this.props.patient_id === es.sender ? (
                    <div class='chat-message-box send-message'>
                      <div>
                        <div class='chat-message'>{es.msg || es.message}</div>
                      </div>
                      <div class='time'>
                        {moment(es.created_on).format('hh:mm:ss A')}{' '}
                      </div>
                    </div>
                  ) : (
                    <div class='chat-message-box received-message d-flex flex-column align-items-end'>
                      <div class='d-flex flex-column'>
                        <div class='chat-message'>{es.msg || es.message}</div>
                      </div>
                      <div class='time'>
                        {' '}
                        {moment(es.created_on).format('hh:mm:ss A')}{' '}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            {this.props.lastAppointment.appointment_time && days < 3 && (
              <div className='send'>
                <input
                  onChange={e => this.setState({ msgData: e.target.value })}
                  className='input-message'
                  name='msgData'
                  value={this.state.msgData}
                  placeholder='Write your message…'
                  type='text'
                  id='message'
                  onKeyDown={e => {
                    if (e.key === 'Enter') this.sendMessege(this.state.msgData)
                  }}
                />
                <button
                  className='input-send'
                  onClick={e => {
                    if (this.state.msgData) this.sendMessege(this.state.msgData)
                  }}>
                  <i>
                    <img src={sendIcon} alt='' />
                  </i>
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  patientProfile: getPatient(state).profile,
  patientHistory: getPatient(state).history,
  psychiatrist: getPatient(state).psychiatrist,
  psychologist: getPatient(state).psychologist,
  loading: getPatient(state).loading,
  chats: getChats(state).chatData,
  lastAppointment: getChats(state).lastAppointment,
})
const mapDispatchToProps = dispatch => ({
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  chatReceived: data => dispatch(chatReceived(data)),
  loadChatForPatient: id => dispatch(loadChatForPatient(id)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messeges)
)
