import { extend } from 'jquery'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getPlan, loadSummaryPatbyId } from '../../../store/treatmentPlan'
import moment from 'moment'
import material from '../../../include/images/material-note.svg'
import { getPatient } from '../../../store/patient'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import FollowupView from './followUp/followupView'
import LinesEllipsis from 'react-lines-ellipsis'

class SummaryDoc extends Component {
  state = {
    doctor_id: '',
    patient_id: '',
    appointment_id: '',
    isShow1: false,
    id: '',
    first_name: '',
    last_name: '',
    image: '',
    time: '',
    star: '',
    yesmindy_identification_number: '',
    age: '',
    sex: '',
    psychiatristFilter: false,
    psychologistFilter: false,
  }

  toggle1 = (
    id,
    first_name,
    last_name,
    thumb,
    appointment_time,
    star,
    yesmindy_identification_number,
    appointment_id,
    patient_id,
    age,
    sex
  ) => {
    this.setState({
      patient_id,
      appointment_id,
      isShow1: !this.state.isShow1,
      id: id,
      first_name: first_name,
      last_name: last_name,
      image: thumb,
      time: appointment_time,
      star: star,
      yesmindy_identification_number: yesmindy_identification_number,
      age,
      sex,
    })
  }

  componentDidMount = () => {
    this.props.loadSummaryPatbyId(this.props.id)
  }
  loadDate = () => {
    this.props.loadSummaryPatbyId(this.props.id, {
      doctor_id: this.state.doctor_id,
    })
  }
  render() {
    return (
      <>
        <div>
          <div class='doc-filter-head d-md-flex align-items-md-center'>
            <div class='doc-filter-box d-md-flex flex-wrap align-items-center'>
              <div class='filter-text-box'>
                <h6>Filters:</h6>
              </div>
              <div class='filter-check-box'>
                <ul class='filter-check-list'>
                  <li class='filter-check-item'>
                    <div class='form-group preferred-field show'>
                      <div class='custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0'>
                        {this.props.detail &&
                          this.props.detail.data &&
                          this.props.detail.data.Psychiatrist && (
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id='smb'
                                name='Psychiatrist'
                                onClick={() => {
                                  this.setState(
                                    {
                                      doctor_id:
                                        this.props.detail &&
                                        this.props.detail.data &&
                                        this.props.detail.data.Psychiatrist &&
                                        this.props.detail.data.Psychiatrist._id,
                                      psychiatristFilter: true,
                                      psychologistFilter: false,
                                    },
                                    () => this.loadDate()
                                  )
                                }}
                                checked={this.state.psychiatristFilter}
                              />
                              <label class='custom-control-label' htmlFor='smb'>
                                By Psychiatrist
                              </label>
                            </div>
                          )}
                        {this.props.detail &&
                          this.props.detail.data &&
                          this.props.detail.data.Psychologist && (
                            <div class='custom-control custom-radio'>
                              <input
                                type='radio'
                                class='custom-control-input'
                                id='mid'
                                name='Psychologist'
                                onClick={() => {
                                  this.setState(
                                    {
                                      doctor_id:
                                        this.props.detail &&
                                        this.props.detail.data &&
                                        this.props.detail.data.Psychologist &&
                                        this.props.detail.data.Psychologist._id,
                                      psychologistFilter: true,
                                      psychiatristFilter: false,
                                    },
                                    () => this.loadDate()
                                  )
                                }}
                                checked={this.state.psychologistFilter}
                              />
                              <label class='custom-control-label' htmlFor='mid'>
                                By Psychologist
                              </label>
                            </div>
                          )}
                        <div class='custom-control custom-radio'>
                          <input
                            type='radio'
                            class='custom-control-input'
                            id='0'
                            name='other'
                            onClick={() => {
                              this.setState(
                                {
                                  doctor_id: '',
                                  psychologistFilter: false,
                                  psychiatristFilter: false,
                                },
                                () => this.loadDate()
                              )
                            }}
                            checked={this.state.doctor_id === ''}
                          />
                          <label class='custom-control-label' htmlFor='0'>
                            All
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul class='doc-info-list'>
            {this.props.summaryPatbyId &&
              this.props.summaryPatbyId.map(e => {
                const atPresentValues =
                  e.currentFollowup &&
                  e.currentFollowup.formData &&
                  e.currentFollowup.formData['Current Complaints:']
                    ? e.currentFollowup
                    : e.currentPrescription
                    ? {
                        formData: {
                          ...e.currentPrescription,
                          'Current Complaints:': e.currentPrescription
                            .chief_complaints
                            ? e.currentPrescription.chief_complaints
                            : 'N/A',
                        },
                      }
                    : null
                const sideEffectsData =
                  e.currentFollowup && e.currentFollowup.formData

                const sideEffectMedical =
                  sideEffectsData && sideEffectsData['Medicine tolerance:']
                    ? sideEffectsData['Medicine tolerance:']
                    : sideEffectsData && sideEffectsData['Medicine Tolerance:']
                    ? sideEffectsData['Medicine Tolerance:']
                    : ''
                const sideEffectValue =
                  sideEffectsData && sideEffectsData['Side Effects']
                    ? sideEffectsData['Side Effects']
                    : sideEffectsData &&
                      sideEffectsData['Side effects details (if present):']
                    ? sideEffectsData['Side effects details (if present):']
                    : ''
                console.log('e.currentFollowup', sideEffectsData)
                return (
                  <li class='doc-info-item'>
                    <div class='doc-info-box'>
                      <div class='doc-info-upper d-flex flex-wrap'>
                        <div class='left'>
                          {`${moment
                            .utc(e.appointment_time)
                            .format('DD MMM')},`}{' '}
                          <span>{`${moment
                            .utc(e.appointment_time)
                            .format('YYYY')}`}</span>
                          <span>{`${moment
                            .utc(e.appointment_time)
                            .format('hh:mm a')}`}</span>
                        </div>
                        <div class='mid d-flex'>
                          <div class='block d-flex'>
                            <div class='img-block'>
                              <img
                                src={
                                  e.doctor_id &&
                                  e.doctor_id.image_url &&
                                  process.env.REACT_APP_S3URL +
                                    e.doctor_id.image_url
                                }
                                alt=''
                              />
                            </div>
                            <div class='text-block'>
                              <h3>{e.doctor_id && e.doctor_id.name}</h3>
                              <p>
                                {e.doctor_id &&
                                  e.doctor_id.field_type &&
                                  e.doctor_id.field_type.name}
                              </p>
                            </div>
                          </div>
                          <div class='block'>
                            <ReactTooltip className='testPurple' html={true} />
                            <h6>At Present:</h6>
                            <h5
                              data-tip={`${
                                atPresentValues &&
                                atPresentValues.formData &&
                                atPresentValues.formData[
                                  'Current Complaints:'
                                ] &&
                                typeof atPresentValues.formData[
                                  'Current Complaints:'
                                ] == 'object'
                                  ? atPresentValues.formData[
                                      'Current Complaints:'
                                    ].length > 0
                                    ? atPresentValues.formData[
                                        'Current Complaints:'
                                      ] &&
                                      atPresentValues.formData[
                                        'Current Complaints:'
                                      ].map(e => ` ${e.label}`)
                                    : 'NA'
                                  : atPresentValues &&
                                    atPresentValues.formData &&
                                    atPresentValues.formData[
                                      'Current Complaints:'
                                    ]
                                  ? atPresentValues.formData[
                                      'Current Complaints:'
                                    ]
                                  : 'NA'
                              }`}>
                              {atPresentValues &&
                              atPresentValues.formData &&
                              atPresentValues.formData['Current Complaints:'] &&
                              typeof atPresentValues.formData[
                                'Current Complaints:'
                              ] == 'object'
                                ? atPresentValues.formData[
                                    'Current Complaints:'
                                  ].length > 0
                                  ? atPresentValues.formData[
                                      'Current Complaints:'
                                    ].length > 1
                                    ? `${
                                        atPresentValues.formData[
                                          'Current Complaints:'
                                        ][0] &&
                                        atPresentValues.formData[
                                          'Current Complaints:'
                                        ][0].label
                                      }...`
                                    : atPresentValues.formData[
                                        'Current Complaints:'
                                      ][0] &&
                                      atPresentValues.formData[
                                        'Current Complaints:'
                                      ][0].label
                                  : 'NA'
                                : atPresentValues &&
                                  atPresentValues.formData &&
                                  atPresentValues.formData[
                                    'Current Complaints:'
                                  ]
                                ? atPresentValues.formData[
                                    'Current Complaints:'
                                  ]
                                : 'NA'}
                            </h5>
                          </div>
                        </div>
                        <div class='right d-flex'>
                          <div class='block'>
                            <h6>Last advise:</h6>
                            <h5
                              data-tip={`${
                                e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name &&
                                e.doctor_id.field_type.name === 'Psychologist'
                                  ? e.prevPrescription &&
                                    e.prevPrescription.theray_method &&
                                    e.prevPrescription.therapy_technique
                                    ? e.prevPrescription.theray_method +
                                      e.prevPrescription.therapy_technique
                                        .map(elp => elp.label)
                                        .join(', ')
                                    : e.prevPrescription &&
                                      e.prevPrescription.theray_method
                                    ? e.prevPrescription.theray_method
                                    : e.prevPrescription &&
                                      e.prevPrescription.therapy_technique
                                    ? e.prevPrescription.therapy_technique
                                        .map(elp => elp.label)
                                        .join(', ')
                                    : 'NA'
                                  : e.prevPrescription &&
                                    e.prevPrescription.medical_prescription &&
                                    e.prevPrescription.medical_prescription
                                      .length > 0 &&
                                    e.prevPrescription.medical_prescription[0]
                                      .composition
                                  ? e.prevPrescription.medical_prescription
                                      .map(
                                        elp =>
                                          elp.formulation +
                                          ' ' +
                                          elp.composition +
                                          ' ' +
                                          elp.dose
                                      )
                                      .join(', ')
                                  : 'NA'
                              }`}>
                              {e.doctor_id &&
                              e.doctor_id.field_type &&
                              e.doctor_id.field_type.name &&
                              e.doctor_id.field_type.name === 'Psychologist' ? (
                                <LinesEllipsis
                                  text={
                                    e.prevPrescription &&
                                    e.prevPrescription.theray_method &&
                                    e.prevPrescription.therapy_technique
                                      ? e.prevPrescription.theray_method +
                                        e.prevPrescription.therapy_technique
                                          .map(elp => elp.label)
                                          .join(', ')
                                      : e.prevPrescription &&
                                        e.prevPrescription.theray_method
                                      ? e.prevPrescription.theray_method
                                      : e.prevPrescription &&
                                        e.prevPrescription.therapy_technique
                                      ? e.prevPrescription.therapy_technique
                                          .map(elp => elp.label)
                                          .join(', ')
                                      : 'NA'
                                  }
                                  maxLine='2'
                                  ellipsis='...'
                                  trimRight
                                  basedOn='letters'
                                />
                              ) : (
                                <LinesEllipsis
                                  text={
                                    e.prevPrescription &&
                                    e.prevPrescription.medical_prescription &&
                                    e.prevPrescription.medical_prescription
                                      .length > 0 &&
                                    e.prevPrescription.medical_prescription[0]
                                      .composition
                                      ? e.prevPrescription.medical_prescription
                                          .map(
                                            elp =>
                                              elp.formulation +
                                              ' ' +
                                              elp.composition +
                                              ' ' +
                                              elp.dose
                                          )
                                          .join(', ')
                                      : 'NA'
                                  }
                                  maxLine='2'
                                  ellipsis='...'
                                  trimRight
                                  basedOn='letters'
                                />
                              )}
                            </h5>
                          </div>
                          <div class='block side-effects'>
                            <ReactTooltip className='testPurple' html={true} />
                            <h6>Side effects:</h6>
                            <p
                              data-tip={`${
                                sideEffectValue &&
                                typeof sideEffectValue == 'object'
                                  ? sideEffectValue.length > 0
                                    ? sideEffectValue.length > 1
                                      ? `${
                                          sideEffectValue[0] &&
                                          sideEffectValue[0].label
                                        }...`
                                      : sideEffectValue[0] &&
                                        sideEffectValue[0].label
                                    : 'NA'
                                  : sideEffectValue && sideEffectMedical
                                  ? sideEffectMedical + ' , ' + sideEffectValue
                                  : sideEffectMedical
                                  ? sideEffectMedical
                                  : sideEffectValue
                                  ? sideEffectValue
                                  : 'NA'
                              }`}>
                              {sideEffectValue &&
                              typeof sideEffectValue == 'object'
                                ? sideEffectValue.length > 0
                                  ? sideEffectValue.length > 1
                                    ? `${
                                        sideEffectValue[0] &&
                                        sideEffectValue[0].label
                                      }...`
                                    : sideEffectValue[0] &&
                                      sideEffectValue[0].label
                                  : 'NA'
                                : sideEffectValue && sideEffectMedical
                                ? sideEffectMedical + ' , ' + sideEffectValue
                                : sideEffectMedical
                                ? sideEffectMedical
                                : sideEffectValue
                                ? sideEffectValue
                                : 'NA'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class='doc-info-lower d-flex flex-wrap'>
                        <div class='left'>
                          <div class='block'>
                            <h6>Improvement:</h6>
                            <p>
                              {e.currentFollowup &&
                              e.currentFollowup.formData &&
                              e.currentFollowup.formData['Overall Improvement:']
                                ? e.currentFollowup.formData[
                                    'Overall Improvement:'
                                  ]
                                : 'NA'}
                            </p>
                          </div>
                        </div>
                        <div class='mid d-flex'>
                          <div class='block blue'>
                            <h6>Current advise:</h6>
                            <p
                              data-tip={`${
                                e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name &&
                                e.doctor_id.field_type.name === 'Psychologist'
                                  ? e.currentPrescription &&
                                    e.currentPrescription.theray_method &&
                                    e.currentPrescription.therapy_technique
                                    ? e.currentPrescription.theray_method +
                                      e.currentPrescription.therapy_technique
                                        .map(elp => elp.label)
                                        .join(', ')
                                    : e.currentPrescription &&
                                      e.currentPrescription.theray_method
                                    ? e.currentPrescription.theray_method
                                    : e.currentPrescription &&
                                      e.currentPrescription.therapy_technique
                                    ? e.currentPrescription.therapy_technique
                                        .map(elp => elp.label)
                                        .join(', ')
                                    : 'NA'
                                  : e.currentPrescription &&
                                    e.currentPrescription
                                      .medical_prescription &&
                                    e.currentPrescription.medical_prescription
                                      .length > 0 &&
                                    e.currentPrescription
                                      .medical_prescription[0].composition
                                  ? e.currentPrescription.medical_prescription
                                      .map(
                                        elp =>
                                          elp.formulation +
                                          ' ' +
                                          elp.composition +
                                          ' ' +
                                          elp.dose
                                      )
                                      .join(', ')
                                  : 'NA'
                              }`}>
                              <strong>
                                {e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name &&
                                e.doctor_id.field_type.name ===
                                  'Psychologist' ? (
                                  <LinesEllipsis
                                    text={
                                      e.currentPrescription &&
                                      e.currentPrescription.theray_method &&
                                      e.currentPrescription.therapy_technique
                                        ? e.currentPrescription.theray_method +
                                          e.currentPrescription.therapy_technique
                                            .map(elp => elp.label)
                                            .join(', ')
                                        : e.currentPrescription &&
                                          e.currentPrescription.theray_method
                                        ? e.currentPrescription.theray_method
                                        : e.currentPrescription &&
                                          e.currentPrescription
                                            .therapy_technique
                                        ? e.currentPrescription.therapy_technique
                                            .map(elp => elp.label)
                                            .join(', ')
                                        : 'NA'
                                    }
                                    maxLine='2'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                  />
                                ) : (
                                  <LinesEllipsis
                                    text={
                                      e.currentPrescription &&
                                      e.currentPrescription
                                        .medical_prescription &&
                                      e.currentPrescription.medical_prescription
                                        .length > 0 &&
                                      e.currentPrescription
                                        .medical_prescription[0].composition
                                        ? e.currentPrescription.medical_prescription
                                            .map(
                                              elp =>
                                                elp.formulation +
                                                ' ' +
                                                elp.composition +
                                                ' ' +
                                                elp.dose
                                            )
                                            .join(', ')
                                        : 'NA'
                                    }
                                    maxLine='2'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                  />
                                )}
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div class='right d-flex'>
                          <div class='block green'>
                            <h6>Special mention:</h6>
                            <p
                              data-tip={`${
                                e.currentFollowup &&
                                e.currentFollowup.formData &&
                                e.currentFollowup.formData[
                                  'Special Mention (Anything important about patient that you would want to mention)'
                                ]
                                  ? e.currentFollowup.formData[
                                      'Special Mention (Anything important about patient that you would want to mention)'
                                    ]
                                  : 'NA'
                              }`}>
                              <strong>
                                {' '}
                                <LinesEllipsis
                                  text={
                                    e.currentFollowup &&
                                    e.currentFollowup.formData &&
                                    e.currentFollowup.formData[
                                      'Special Mention (Anything important about patient that you would want to mention)'
                                    ]
                                      ? e.currentFollowup.formData[
                                          'Special Mention (Anything important about patient that you would want to mention)'
                                        ]
                                      : 'NA'
                                  }
                                  maxLine='1'
                                  ellipsis='...'
                                  trimRight
                                  basedOn='letters'
                                />
                              </strong>
                            </p>
                          </div>
                        </div>
                        {/* <div class="right d-flex ">
                        <div class="block green ml-auto mr-4">
                          <a
                            href="javascript:void(0)"
                            class="np-links"
                            data-tip="Follow Up Notes"
                            aria-hidden="true"
                            data-toggle="matchat"
                            style={{
                              fontSize: "10px",
                              padding: "8px 13px",
                              border: "1px solid #582BB8",
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              this.toggle1(
                                e.patient_id._id,
                                e.patient_id.first_name,
                                e.patient_id.last_name,
                                e.patient_id.image_url,
                                e.appointment_time,
                                e.patient_id.star,
                                e.patient_id.yesmindy_identification_number,
                                e._id,
                                e.patient_id._id,
                                e.patient_id.date_of_birth,
                                e.patient_id.sex
                              )
                            }
                          >
                            <img
                              src={material}
                              alt=""
                              class="rotate"
                              style={{ width: "20px" }}
                            />
                          </a>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
        {this.state.id && (
          <FollowupView
            appointment_id={this.state.appointment_id}
            patient_id={this.state.patient_id}
            yesmindy_identification_number={
              this.state.yesmindy_identification_number
            }
            sex={this.state.sex}
            age={this.state.age}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            image={this.state.image}
            star={this.state.star}
            time={this.state.time || this.props.time}
            isShow2={this.state.isShow1}
            toggle2={this.toggle1}
            id={this.state.id}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  summaryPatbyId: getPlan(state).summaryPatbyId,
  detail: getPatient(state).patientDetail,
})

const mapDispatchToProps = dispatch => ({
  loadSummaryPatbyId: (id, data) => dispatch(loadSummaryPatbyId(id, data)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryDoc)
)
