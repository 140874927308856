import React, { Component } from 'react'
//import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import TextField from '@mui/material/TextField'
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

class DateInput extends Component {
  state = {
    formclass: 'form-group custom-input show on',
    value: null,
  }

  handleFocus = () => {
    this.setState({ formclass: 'form-group custom-input show on' })
  }

  handleBlur = e => {
    const classname = e.target.value
      ? 'form-group custom-input show on'
      : 'form-group custom-input show on'
    this.setState({ formclass: classname })
  }

  componentDidMount() {
    const classname = 'form-group custom-input show on'
    this.setState({ formclass: classname })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? 'form-group custom-input show on'
        : 'form-group custom-input show on'
      this.setState({ formclass: classname })
    }
  }

  handleError = error => {
    if (error) {
      this.setState({})
    }
  }

  render() {
    const { name, label, error, readOnlyData, onChange, value, ...rest } =
      this.props
    return (
      <>
        <div className={this.state.formclass}>
          {/* <label style={{ zIndex: 1 }} htmlFor={label}>
            {label}
          </label> */}
          {/* <DatePicker
            showYearDropdown={false}
            disabled={readOnlyData ? true : false}
            yearItemNumber="15"
            autoComplete="off"
            name={name}
            id={label}
            className={
              error
                ? "form-control datepicker error"
                : "form-control datepicker"
            }
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            dateFormat="dd/MM/yyyy"
            {...rest}
          /> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={label}
              disabled={readOnlyData ? true : false}
              className={error ? 'error' : 'mui-date-picker'}
              value={value && value != ""? dayjs(value - 1) : null}
              onChange={val => onChange(new Date(val))}
              renderInput={params => <TextField {...params} />}
              format='DD/MM/YYYY'
              {...rest}
            />
          </LocalizationProvider>
          {error && <label className='error'>{error}</label>}
        </div>
      </>
    )
  }
}

export default DateInput
