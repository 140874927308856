import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getCaserecord,
  addDemographics,
  loadDiagnosis,
  loadCaseRecord,
} from '../../../../store/caseRecord'
import download from '../../../../include/images/download-icon.svg'
import Joi from 'joi-browser'
import Form from '../../../common/form/form'
import _ from 'lodash'
import AlertSuccess from '../../../common/alertSuccess'
import { toast } from 'react-toastify'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { getDoctor } from '../../../../store/doctor'
import { getPatient, loadPatientProfile } from '../../../../store/patient'
import patientDetail from '../../../patient/profile/patientDetail'
const personality = [
  {
    label: 'Antisocial personality disorder',
    value: 'Antisocial personality disorder',
  },
  {
    label: 'Antisocial personality disorder',
    value: 'Antisocial personality disorder',
  },
  {
    label: 'Avoidant personality disorder',
    value: 'Avoidant personality disorder',
  },
  {
    label: 'Borderline personality disorder',
    value: 'Borderline personality disorder',
  },
  { label: 'Cluster A Traits', value: 'Cluster A Traits' },
  { label: 'Cluster B Traits', value: 'Cluster B Traits' },
  { label: 'Cluster C Traits', value: 'Cluster C Traits' },
  { label: 'Difficult Temperament', value: 'Difficult Temperament' },
  {
    label: 'Histrionic personality disorder',
    value: 'Histrionic personality disorder',
  },
  {
    label: 'Histrionic personality disorder',
    value: 'Histrionic personality disorder',
  },
  { label: 'Mixed Personality Traits', value: 'Mixed Personality Traits' },
  {
    label: 'Narcissistic personality disorder',
    value: 'Narcissistic personality disorder',
  },
  {
    label: 'Obsessive-compulsive personality disorder',
    value: 'Obsessive-compulsive personality disorder',
  },
  {
    label: 'Other specified personality disorder',
    value: 'Other specified personality disorder',
  },
  {
    label: 'Paranoid personality disorder',
    value: 'Paranoid personality disorder',
  },
  {
    label: 'Schizoid personality disorder',
    value: 'Schizoid personality disorder',
  },
  {
    label: 'Schizotypal personality disorder',
    value: 'Schizotypal personality disorder',
  },
  {
    label: 'Unspecified personality disorder',
    value: 'Unspecified personality disorder',
  },
]

class Finish extends Form {
  state = {
    data: {
      type_of_diagnosis: '',
      diagnosis: [],
      type_of_diagnosisPsychiatrist: '',
      diagnosisPsychiatrist: [],
      personality: '',
      medical: '',
      special_notes: '',
      subtype: '',
    },
    errors: {},
    type: [
      {
        id: 'ICD',
        name: 'ICD',
      },
      {
        id: 'DSM',
        name: 'DSM',
      },
      {
        id: 'Conceptual Diagnosis',
        name: 'Conceptual Diagnosis',
      },
    ],
  }
  schema = {
    personality: Joi.object().allow(''),
    medical: Joi.string().allow(''),
    type_of_diagnosis: Joi.object().allow(''),
    diagnosis: Joi.array().allow(null),
    type_of_diagnosisPsychiatrist: Joi.object().allow(''),
    diagnosisPsychiatrist: Joi.array().allow(null),
    special_notes: Joi.string().allow(''),
    subtype: Joi.string().allow(''),
  }
  doSubmit = () => {
    const {
      type_of_diagnosis,
      diagnosis,
      special_notes,
      medical,
      personality,
      subtype,
      type_of_diagnosisPsychiatrist,
      diagnosisPsychiatrist,
    } = this.state.data
    const id = this.props.data._id
    const formData = {
      finish: {
        special_notes: special_notes,
        diagnosis: {
          type_of_diagnosis: type_of_diagnosis.value,
          diagnosis: diagnosis ? diagnosis.map(ed => ed.value) : [],
        },
        diagnosisPsychiatrist: {
          type_of_diagnosis: type_of_diagnosisPsychiatrist.value,
          diagnosis: diagnosisPsychiatrist
            ? diagnosisPsychiatrist.map(ed => ed.value)
            : [],
        },
      },
    }
    if (subtype) formData.finish.subtype = subtype
    if (medical) formData.finish.medical = medical
    if (personality) formData.finish.personality = personality
    const newData = _.pickBy(formData, _.identity)
    this.props.addDemographics(newData, id, this.callBack)
  }
  callBack = res => {
    const _id = this.props.data && this.props.data._id
    if (res && res.status === 200) {
      this.props.loadCaseRecord(_id)
      this.props.patientDetail(_id)
      toast(<AlertSuccess message={'Information Saved.'} />)
      this.props.history.push('/doctor/dashboard')
    }
  }
  animatedComponents = makeAnimated()
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
      'height': '57px',
    }),
  }
  customStyles2 = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
      'minHeight': '57px',
      'width': '100%',
    }),
  }
  componentDidMount() {
    document.body.className = 'dashboard fixed'
    if (this.props.data) {
      const { _id } = this.props.data
      console.log(
        '==>this.props.data',
        this.props.getPatientProfile.patientDetail.data
      )
      this.props.loadCaseRecord(_id)
      this.props.patientDetail(_id)
    }
    //this.props;
  }

  loadD = type_of_diagnosis => {
    const data = { ...this.state.data }
    data.diagnosis = []
    this.setState({ data })
    this.props.loadDiagnosis(type_of_diagnosis)
  }
  loadDPsychiatrist = type_of_diagnosis => {
    const data = { ...this.state.data }
    data.diagnosisPsychiatrist = []
    this.setState({ data })
    this.props.loadDiagnosis(type_of_diagnosis)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.case && this.props.loading !== prevProps.loading) {
      this.props.case.finish &&
        this.props.loadDiagnosis(
          this.props.doctorProfile.field_type.name !== 'Psychologist'
            ? this.props.case.finish.diagnosisPsychiatrist.type_of_diagnosis
            : this.props.case.finish.diagnosis.type_of_diagnosis
        )
      const { finish } = this.props.case
      const data = { ...this.state.data }
      data.special_notes = finish && finish.special_notes
      if (finish && finish.diagnosis && finish.diagnosis.type_of_diagnosis) {
        data.type_of_diagnosis = {
          value: finish.diagnosis.type_of_diagnosis,
          label: finish.diagnosis.type_of_diagnosis,
        }
      }
      data.diagnosis =
        finish &&
        finish.diagnosis &&
        finish.diagnosis.diagnosis.map(efd => ({
          label: efd.diagnosis + ' - ' + efd.value,
          value: efd._id,
        }))

      if (
        finish &&
        finish.diagnosisPsychiatrist &&
        finish.diagnosisPsychiatrist.type_of_diagnosis
      ) {
        data.type_of_diagnosisPsychiatrist = {
          value: finish.diagnosisPsychiatrist.type_of_diagnosis,
          label: finish.diagnosisPsychiatrist.type_of_diagnosis,
        }
      }
      data.diagnosisPsychiatrist =
        finish &&
        finish.diagnosisPsychiatrist &&
        finish.diagnosisPsychiatrist.diagnosis.map(efd => ({
          label: efd.diagnosis + ' - ' + efd.value,
          value: efd._id,
        }))
      data.subtype = finish && finish.subtype
      data.medical = finish && finish.medical
      data.personality = finish && finish.personality
      this.setState({ data })
    }
  }

  render() {
    const { diagnosis } = this.props

    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="finish"
          role="tabpanel"
          aria-labelledby="finish-tab"
        >
          <div class="card-header">Finish</div>
          <div class="content-body add-scrollbar scrollbar-dynamic"> */}
        <form onSubmit={this.handleSubmit} id='my-form'>
          <div class='crf-content-box'>
            <div class='textarea-row'>
              <h4>Brief Description / Formulation</h4>
              {this.renderTextarea('special_notes', 'Start Typing...')}
            </div>
            <h4>
              Diagnosis <small>(Psychologist)</small>
            </h4>
            <div class='diagnosis-box'>
              <div class='row'>
                <div class='col-lg-3 col-sm-12'>
                  <div class='dtb-content'>
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Type Of diagnosis</h5>
                        <Select
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e) {
                              data.type_of_diagnosis = e
                              this.setState({ data }, () => this.loadD(e.value))
                            }
                          }}
                          placeholder='Type Of diagnosis'
                          styles={this.customStyles}
                          classNameName='form-groupxx'
                          closeMenuOnSelect={true}
                          components={this.animatedComponents}
                          options={this.state.type.map(e => ({
                            label: e.name,
                            value: e.id,
                          }))}
                          value={this.state.data.type_of_diagnosis}
                          isDisabled={
                            this.props.doctorProfile.field_type.name !==
                            'Psychologist'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-lg-9 col-sm-12'>
                  <div class='dtb-content'>
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Diagnosis</h5>
                        <Select
                          isMulti
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e && e.length < 3) {
                              data.diagnosis = e
                            } else if (!e) {
                              data.diagnosis = ''
                            }
                            this.setState({ data })
                          }}
                          placeholder='Diagnosis'
                          styles={this.customStyles2}
                          classNameName='form-groupxx'
                          closeMenuOnSelect={true}
                          components={this.animatedComponents}
                          options={diagnosis.map(e => ({
                            label: e.diagnosis + ' - ' + e.value,
                            value: e._id,
                          }))}
                          value={this.state.data.diagnosis}
                          isDisabled={
                            this.props.doctorProfile.field_type.name !==
                            'Psychologist'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4 className='mt-4'>
              Diagnosis <small>(Psychiatrist)</small>
            </h4>
            <div class='diagnosis-box'>
              <div class='row'>
                <div class='col-lg-3 col-sm-12'>
                  <div class='dtb-content'>
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Type Of diagnosis</h5>
                        <Select
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e) {
                              data.type_of_diagnosisPsychiatrist = e
                              this.setState({ data }, () =>
                                this.loadDPsychiatrist(e.value)
                              )
                            }
                          }}
                          placeholder='Type Of diagnosis'
                          styles={this.customStyles}
                          classNameName='form-groupxx'
                          closeMenuOnSelect={true}
                          components={this.animatedComponents}
                          options={this.state.type.map(e => ({
                            label: e.name,
                            value: e.id,
                          }))}
                          value={this.state.data.type_of_diagnosisPsychiatrist}
                          isDisabled={
                            this.props.doctorProfile.field_type.name !==
                            'Psychiatrist'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-lg-9 col-sm-12'>
                  <div class='dtb-content'>
                    <div class='radio-group-box'>
                      <div class='input-group-row'>
                        <h5>Diagnosis</h5>
                        <Select
                          isMulti
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e && e.length < 3) {
                              data.diagnosisPsychiatrist = e
                            } else if (!e) {
                              data.diagnosisPsychiatrist = ''
                            }
                            this.setState({ data })
                          }}
                          placeholder='Diagnosis'
                          styles={this.customStyles2}
                          classNameName='form-groupxx'
                          closeMenuOnSelect={true}
                          components={this.animatedComponents}
                          options={diagnosis.map(e => ({
                            label: e.diagnosis + ' - ' + e.value,
                            value: e._id,
                          }))}
                          value={this.state.data.diagnosisPsychiatrist}
                          isDisabled={
                            this.props.doctorProfile.field_type.name !==
                            'Psychiatrist'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='textarea-row mt-4'>
              <label>Diagnosis Notes</label>
              {this.renderInput('subtype', 'Notes')}
            </div>
            <div class='textarea-row mt-4'>
              <label>Personality Type</label>
              <Select
                onChange={e => {
                  const data = { ...this.state.data }
                  data.personality = e
                  this.setState({ data })
                }}
                placeholder='Personality'
                styles={this.customStyles2}
                classNameName='form-groupxx'
                closeMenuOnSelect={true}
                components={this.animatedComponents}
                options={personality}
                value={this.state.data.personality}
              />
            </div>
            <div class='textarea-row mt-4'>
              <label>Medical Diagnosis</label>
              {this.renderInput('medical', 'Medical Diagnosis')}
            </div>
            <div class='class-plan-btn-group d-md-flex flex-wrap'>
              <div class='pane-seprator'></div>
              {this.props.getPatientProfile.patientDetail.data && (
                <div class='mhem-team-box'>
                  <h4>Mental Health Expert Team:</h4>
                  {this.props.getPatientProfile.patientDetail.data
                    .Psychologist && (
                    <>
                      <h6>
                        {
                          this.props.getPatientProfile.patientDetail.data
                            .Psychologist.name
                        }
                      </h6>
                      <address>
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychologist.graduation &&
                          this.props.getPatientProfile.patientDetail.data
                            .Psychologist.graduation.education}
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychologist.post_graduation &&
                          ',  ' +
                            this.props.getPatientProfile.patientDetail.data
                              .Psychologist.post_graduation.education}
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychologist.super_speciality &&
                          ',  ' +
                            this.props.getPatientProfile.patientDetail.data
                              .Psychologist.super_speciality.education}
                        ,{' '}
                        {
                          this.props.getPatientProfile.patientDetail.data
                            .Psychologist.field_type.name
                        }
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychologist.documents_url &&
                          this.props.getPatientProfile.patientDetail.data
                            .Psychologist.documents_url.registration_license &&
                          ', Reg No:' +
                            this.props.getPatientProfile.patientDetail.data
                              .Psychologist.documents_url.registration_license
                              .licence_no}
                      </address>
                    </>
                  )}
                  {this.props.getPatientProfile.patientDetail.data
                    .Psychiatrist && (
                    <>
                      <h6>
                        {
                          this.props.getPatientProfile.patientDetail.data
                            .Psychiatrist.name
                        }
                      </h6>
                      <address>
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychiatrist.graduation &&
                          this.props.getPatientProfile.patientDetail.data
                            .Psychiatrist.graduation.education}
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychiatrist.post_graduation &&
                          ',  ' +
                            this.props.getPatientProfile.patientDetail.data
                              .Psychiatrist.post_graduation.education}
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychiatrist.super_speciality &&
                          ',  ' +
                            this.props.getPatientProfile.patientDetail.data
                              .Psychiatrist.super_speciality.education}
                        ,{' '}
                        {
                          this.props.getPatientProfile.patientDetail.data
                            .Psychiatrist.field_type.name
                        }
                        {this.props.getPatientProfile.patientDetail.data
                          .Psychiatrist.documents_url &&
                          this.props.getPatientProfile.patientDetail.data
                            .Psychiatrist.documents_url.registration_license &&
                          ', Reg No:' +
                            this.props.getPatientProfile.patientDetail.data
                              .Psychiatrist.documents_url.registration_license
                              .licence_no}
                      </address>
                    </>
                  )}
                </div>
              )}

              <div class='pane-seprator'></div>
              <div class='disclaimer-box'>
                <span>Disclaimer: </span>The information and case history
                mentioned here about the patient is a confidential document for
                the clinician's purpose only. Information mentioned here and
                Diagnosis made is responsibility of the clinicians.
              </div>
            </div>
          </div>
        </form>
        {/* </div>
        </div> */}
        <div class='patients-details-footer d-md-flex align-items-md-center justify-content-md-between'>
          <Link to='/doctor/dashboard'>
            <button class='btn btn-grey'>EXIT</button>
          </Link>
          {/* <div class="pdf-btn d-flex align-items-center">
            <a href="javascript:void(0)">
              <img src={download} />
            </a>
          </div> */}
          <button type='submit' class='btn btn-primary btn-lg' form='my-form'>
            SAVE and EXIT
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  demographicsAdd: getCaserecord(state).demographicsAdd,
  diagnosis: getCaserecord(state).diagnosis,
  getCaserecord: getCaserecord(state),
  case: getCaserecord(state).caserecord,
  loading: getCaserecord(state).caseRecordLoading,
  doctorProfile: getDoctor(state).profile,
  getPatientProfile: getPatient(state),
})
const mapDispatchToProps = dispatch => ({
  addDemographics: (params, id, callBack) =>
    dispatch(addDemographics(params, id, callBack)),
  loadCaseRecord: id => dispatch(loadCaseRecord(id)),
  loadDiagnosis: params => dispatch(loadDiagnosis(params)),
  patientDetail: id => dispatch(patientDetail(id)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Finish))
