import Task from './task'
import Diary from './diary'
import Header from './header'
import LeftBar from './leftBar'
import History from './history'
import Journey from './journey'
import MyReport from './myReport'
import CaseRecord from './caseRecord'
import SummaryDoc from './summary'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import DetailHeader from './detailHeader'
import { getPatient, patientDetail } from '../../../store/patient'
import { getDoctor } from '../../../store/doctor'
import BioPyschosocial from './bioPyschosocial'
import TreatmentPlanPat from './treatmentPlanPat'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class PatientDetailTab extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
  }
  componentDidMount() {
    if (
      this.props.match.params.tab &&
      this.props.match.params.tab === 'history'
    ) {
      this.setState({ step: 4 })
    }
    if (this.props.match.params.id) {
      this.props.patientDetail(this.props.match.params.id)
    }

    if (this.props.match.params.tab && this.props.match.params.tab === 'task')
      this.setState({ step: 5 })
    if (this.props.location.state) {
      this.props.patientDetail(this.props.location.state)
    } else {
      this.props.history.goBack()
    }
  }
  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.step == 4 && this.state.step != prevState.step) {
      if (this.props.match.params.id) {
        this.props.patientDetail(this.props.match.params.id)
      }
      if (this.props.location.state) {
        this.props.patientDetail(this.props.location.state)
      }
    }
  }

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <Journey
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 2:
        return (
          <Diary
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 3:
        return (
          <CaseRecord
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 4:
        return (
          <History
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
            patientHistory={this.props.detail.patientHistory}
          />
        )
      case 5:
        return (
          <Task
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 6:
        return (
          <MyReport
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 7:
        return (
          <BioPyschosocial
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 8:
        return (
          <TreatmentPlanPat
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      case 9:
        return (
          <SummaryDoc
            updateStep={this.updateStep}
            step={this.state.step}
            id={this.props.location.state || this.props.match.params.id}
          />
        )
      default:
        break
    }
  }

  toggleSidebar = e => {
    if (e) e.preventDefault()
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }

  render() {
    const { data, patientHistory, caserecord } = this.props.detail
    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <div
          className={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
          <LeftBar />
          <Header
            sidebarToogle={this.state.sidebarToogle}
            toggleSidebar={this.toggleSidebar}
          />
          <div class='dashboard-main-container'>
            <div class='dashboard-main-heading'>
              <h2>
                Welcome, <strong>Dr. {this.props.doctorProfile.name}</strong>
              </h2>
              <p>Have a nice day at work</p>
            </div>

            <div class='patients-detail-box'>
              <DetailHeader detail={data} caserecord={caserecord} />

              <div class='patients-details-body'>
                <div class='tab-container d-none d-md-block'>
                  <ul class='nav nav-tabs nav-tabs2' id='myTab' role='tablist'>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip=' Appointments booked and completed by the patient on YesMindy'
                        className={
                          this.state.step === 1 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 1)}
                        id='Journey-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='Journey'
                        aria-selected='true'
                        style={{ cursor: 'pointer' }}>
                        <b>Journey</b>
                        <span>Journey</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip='Patients share their thoughts with the treating team can be seen here'
                        className={
                          this.state.step === 2 ? 'nav-link active' : 'nav-link'
                        }
                        id='diary-tab'
                        data-toggle='tab'
                        onClick={e => this.updateStep(null, 2)}
                        role='tab'
                        aria-controls='diary'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>Diary</b>
                        <span>Diary</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip=' Clinical history taken by the patient’s first expert. Edits made by any treating team member of the patient are logged here with the latest version readable only'
                        className={
                          this.state.step === 3 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 3)}
                        id='caserecords-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='caserecords'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>Case Record</b>
                        <span>Case Record</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip='Respective field’s expert can make their formulation of the patient which can be read and considered by the entire team of the patient.'
                        className={
                          this.state.step === 7 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 7)}
                        id='caserecords-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='caserecords'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>BioPsychoSocial</b>
                        <span>BioPsychoSocial</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip='Respective field’s expert can make their treatment plan for the patient which can be read by the entire team of the patient so as to be aware and plan accordingly as a team'
                        className={
                          this.state.step === 8 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 8)}
                        id='caserecords-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='caserecords'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>Treatment Plan</b>
                        <span>Treatment Plan</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip='Smart auto generated treatment summary of the patient for the experts to analyse'
                        className={
                          this.state.step === 9 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 9)}
                        id='caserecords-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='caserecords'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>Summary</b>
                        <span>Summary</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip='History reported by the patient about themselves'
                        className={
                          this.state.step === 4 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 4)}
                        id='history-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='history'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>History</b>
                        <span>History</span>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip='Task allotted by the team can be accessed/ checked from here'
                        className={
                          this.state.step === 5 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 5)}
                        id='task-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='task'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>Task</b>
                        <span>Task</span>
                      </a>
                    </li>

                    <li class='nav-item' role='presentation'>
                      <a
                        data-tip=' All the reports submitted by the patient or experts can be viewed here'
                        className={
                          this.state.step === 6 ? 'nav-link active' : 'nav-link'
                        }
                        onClick={e => this.updateStep(null, 6)}
                        id='reports-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='reports'
                        aria-selected='false'
                        style={{ cursor: 'pointer' }}>
                        <b>Reports</b>
                        <span>Reports</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class='tab-content-box'>
                  <div class='tab-content' id='myTabContent'>
                    <div
                      class={
                        this.state.step === 1
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 1)}>
                        Journey
                      </div>
                      <div
                        className={
                          this.state.step === 1
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 1 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 2
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 2)}>
                        Diary
                      </div>
                      <div
                        className={
                          this.state.step === 2
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 2 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 3
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 3)}>
                        Case Record
                      </div>
                      <div
                        className={
                          this.state.step === 3
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 3 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 7
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 7)}>
                        BioPsychoSocial
                      </div>
                      <div
                        className={
                          this.state.step === 7
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 7 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 8
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 8)}>
                        Treatment Plan
                      </div>
                      <div
                        className={
                          this.state.step === 8
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 8 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 9
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 9)}>
                        Summary
                      </div>
                      <div
                        className={
                          this.state.step === 9
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 9 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 4
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 4)}>
                        History
                      </div>
                      <div
                        className={
                          this.state.step === 4
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 4 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 5
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 5)}>
                        Task
                      </div>
                      <div
                        className={
                          this.state.step === 5
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 5 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 6
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 6)}>
                        Reports
                      </div>
                      <div
                        className={
                          this.state.step === 6
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative', overflow: 'unset' }}>
                        {' '}
                        {this.state.step === 6 && this.leftSideStep()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  detail: getPatient(state).patientDetail,
  doctorProfile: getDoctor(state).profile,
})
const mapDispatchToProps = dispatch => ({
  patientDetail: id => dispatch(patientDetail(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PatientDetailTab)
